import { useState, useEffect } from "react";
import { RealEstateReceptionBookFeedPresenter } from "./presenter";
import { useCityOptions } from "./hooks/useCityOptions";
import { useCitySelect } from "./hooks/useCitySelect";
import { usePrefectureOptions } from "./hooks/usePrefectureOptions";
import { useReceptionReasonOptions } from "./hooks/useReceptionReasonOptions";
import { type IFilterCondition } from "./types";
import { buildPrefectureAndCitySetter } from "./hooks/setPrefecturesWithCities";
import { useReceptionBookImportStatuses } from "./hooks/useReceptionBookImportStatuses";
import { useGridApiRef } from "@mui/x-data-grid";
import { type GridApiCommunity } from "@mui/x-data-grid/models/api/gridApiCommunity";
import {
  getStartAndEndDate,
  getSearchMinDate,
  getSearchMaxDate,
  isDateDiffOverOneYear,
} from "./utils/dateTime";
import { useStationLineOptions } from "./hooks/useStationLineOptions";
import { useStationOptions } from "./hooks/useStationOptions";
import { type ISelectObject } from "@/types/select";

const RealEstateReceptionBookFeedContainer: React.FC = () => {
  const [startDate, endDate] = getStartAndEndDate();
  const minDate = getSearchMinDate();
  const maxDate = getSearchMaxDate();

  // ---
  // hooks群
  // ---
  const gridApiRef: React.MutableRefObject<GridApiCommunity> = useGridApiRef();

  const [
    legalAffairsBureauRequestDateStart,
    setLegalAffairsBureauRequestDateStart,
  ] = useState<Date | null>(startDate);
  const [
    legalAffairsBureauRequestDateEnd,
    setLegalAffairsBureauRequestDateEnd,
  ] = useState<Date | null>(endDate);
  const [
    realEstateBookTypeRensakiRenzoku,
    setRealEstateBookTypeRensakiRenzoku,
  ] = useState<boolean>(false);
  const [realEstateBookTypeTandoku, setRealEstateBookTypeTandoku] =
    useState<boolean>(false);
  const [realEstateTypeTochi, setRealEstateTypeTochi] =
    useState<boolean>(false);
  const [realEstateTypeKutate, setRealEstateTypeKutate] =
    useState<boolean>(false);
  const [realEstateTypeTatemono, setRealEstateTypeTatemono] =
    useState<boolean>(false);
  const [realEstateTypeKyotan, setRealEstateTypeKyotan] =
    useState<boolean>(false);
  const [hasTrade, setHasTrade] = useState<boolean>(false);
  const [isTowerApartment, setIsTowerApartment] = useState<boolean>(false);
  const [isOneRoom, setIsOneRoom] = useState<boolean>(false);
  const [isPlant, setIsPlant] = useState<boolean>(false);
  const [cities, setCities] = useState<string[]>([]);
  const [prefectures, setPrefectures] = useState<string[]>([]);
  const [receptionReasons, setReceptionReasons] = useState<string[]>([]);
  const [sotoRange, setSotoRange] = useState<Record<string, number | null>>({
    min: null,
    max: null,
  });
  const [estimateAcreageRange, setEstimateAcreageRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [estimateLandPriceRange, setEstimateLandPriceRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [approximateEstimatePriceRange, setApproximateEstimatePriceRange] =
    useState<Record<string, number | null>>({
      min: null,
      max: null,
    });
  const [residenceOptions, setResidenceOptions] = useState<
    Record<string, boolean>
  >({
    ittei: false,
    icchuko: false,
    ichiju: false,
    nitei: false,
    nichuko: false,
    niju: false,
    denju: false,
    junju: false,
  });
  const [commercialOptions, setCommercialOptions] = useState<
    Record<string, boolean>
  >({
    kinsyo: false,
    syogyo: false,
  });
  const [industryOptions, setIndustryOptions] = useState<
    Record<string, boolean>
  >({
    junko: false,
    kogyo: false,
    kosen: false,
  });
  const [yosekiRatioRange, setYosekiRatioRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [kenpeiRatioRange, setKenpeiRatioRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [buildDateStart, setBuildDateStart] = useState<Date | null>(null);
  const [buildDateEnd, setBuildDateEnd] = useState<Date | null>(null);
  const [propertyName, setPropertyName] = useState<string>("");
  const [buildingAgeRange, setBuildingAgeRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [farmlandAreaRange, setFarmlandAreaRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [farmlandCategories, setFarmlandCategories] = useState<string[]>([]);
  const [isRenewableEnergy, setIsRenewableEnergy] = useState<boolean>(false);

  // 選択した駅項目を保持しておくstate
  const [stationSelectedValues, setStationSelectedValues] = useState<
    ISelectObject[]
  >([]);

  const [stationLine, setStationLine] = useState<number>(-1);
  const [walk, setWalk] = useState<number>(-1);

  /**
   * FilterConditionを受け取り、対応する全てのStateにSetしていく関数
   * @param filterCondition
   */
  const setAllFilterConditionStates = (
    filterCondition: IFilterCondition
  ): void => {
    setLegalAffairsBureauRequestDateStart(
      filterCondition.legalAffairsBureauRequestDateStart
    );
    setLegalAffairsBureauRequestDateEnd(
      filterCondition.legalAffairsBureauRequestDateEnd
    );
    setRealEstateBookTypeRensakiRenzoku(
      filterCondition.realEstateBookTypeRensakiRenzoku
    );
    setRealEstateBookTypeTandoku(filterCondition.realEstateBookTypeTandoku);
    setRealEstateTypeTochi(filterCondition.realEstateTypeTochi);
    setRealEstateTypeKutate(filterCondition.realEstateTypeKutate);
    setRealEstateTypeTatemono(filterCondition.realEstateTypeTatemono);
    setRealEstateTypeKyotan(filterCondition.realEstateTypeKyotan);
    setCities(filterCondition.cities);
    setPrefectures(filterCondition.prefectures);
    setReceptionReasons(filterCondition.receptionReasons);
    setSotoRange(filterCondition.sotoRange);
    setEstimateAcreageRange(filterCondition.estimateAcreageRange);
    setEstimateLandPriceRange(filterCondition.estimateLandPriceRange);
    setApproximateEstimatePriceRange(
      filterCondition.approximateEstimatePriceRange
    );
    setResidenceOptions(filterCondition.residenceOptions);
    setCommercialOptions(filterCondition.commercialOptions);
    setIndustryOptions(filterCondition.industryOptions);
    setYosekiRatioRange(filterCondition.yosekiRatioRange);
    setKenpeiRatioRange(filterCondition.kenpeiRatioRange);
    setHasTrade(filterCondition.hasTrade);
    setIsTowerApartment(filterCondition.isTowerApartment);
    setIsOneRoom(filterCondition.isOneRoom);
    setIsPlant(filterCondition.isPlant);
    setIsRenewableEnergy(filterCondition.isRenewableEnergy);
    setBuildDateStart(filterCondition.buildDateStart);
    setBuildDateEnd(filterCondition.buildDateEnd);
    setPropertyName(filterCondition.propertyName);
    setStationSelectedValues(filterCondition.stationSelectedValues);
    setWalk(filterCondition.walk);
    setBuildingAgeRange(filterCondition.buildingAgeRange);
    setFarmlandAreaRange(filterCondition.farmlandAreaRange);
    setFarmlandCategories(filterCondition.farmlandCategories);
    setHasTrade(filterCondition.hasTrade);
  };

  const [filterCondition, setFilterCondition] = useState<IFilterCondition>(
    () => {
      const historyState = window.history.state as {
        filterCondition: IFilterCondition;
        stationLine?: number;
      };
      if (historyState.filterCondition) {
        // 路線情報を復元する
        if (historyState.stationLine) {
          setStationLine(historyState.stationLine);
        }

        // filterConditionをUIに適用し画面の状態を復元する
        setAllFilterConditionStates(historyState.filterCondition);

        return historyState.filterCondition;
      }

      return {
        legalAffairsBureauRequestDateStart,
        legalAffairsBureauRequestDateEnd,
        realEstateBookTypeRensakiRenzoku,
        realEstateBookTypeTandoku,
        realEstateTypeTochi,
        realEstateTypeKutate,
        realEstateTypeTatemono,
        realEstateTypeKyotan,
        cities,
        prefectures,
        receptionReasons,
        sotoRange,
        estimateAcreageRange,
        estimateLandPriceRange,
        approximateEstimatePriceRange,
        residenceOptions,
        commercialOptions,
        industryOptions,
        yosekiRatioRange,
        kenpeiRatioRange,
        hasTrade,
        isTowerApartment,
        isOneRoom,
        isPlant,
        buildDateStart,
        buildDateEnd,
        propertyName,
        stationSelectedValues,
        walk,
        buildingAgeRange,
        farmlandAreaRange,
        farmlandCategories,
        isRenewableEnergy,
      };
    }
  );

  // ---
  // custom hooks群
  // ---
  const { prefectureOptions } = usePrefectureOptions();
  const { cityParams } = useCityOptions();
  const { cityOptions } = useCitySelect(prefectures, cityParams);
  const { receptionReasonOptions } = useReceptionReasonOptions();
  const importStatuses = useReceptionBookImportStatuses();

  // 路線一覧のセレクト項目
  const { stationLineOptions } = useStationLineOptions();
  // 駅一覧のセレクト項目
  const { stationOptions } = useStationOptions(stationLine);

  // ---
  // function群
  // ---
  const validateDate = (date: Date | null, dateName: string): string => {
    const dateInvalid = !date || isNaN(date.getDate());
    const dateOutOfRange = date && (date < minDate || date > maxDate);

    return (
      `${
        dateInvalid
          ? `法務局受付日 ${dateName}を${
              !date ? "選択して下さい" : "正しい日付の形式にして下さい"
            }\n`
          : ""
      }` + `${dateOutOfRange ? `法務局受付日 ${dateName}が範囲外です\n` : ""}`
    );
  };

  const handleSearch = (): void => {
    let alertMessage = "";
    if (!cities.length) {
      alertMessage += "市区町村を選択して下さい\n";
    }
    if (!receptionReasons.length) {
      alertMessage += "登記原因を選択して下さい\n";
    }
    alertMessage += validateDate(legalAffairsBureauRequestDateStart, "開始");
    alertMessage += validateDate(legalAffairsBureauRequestDateEnd, "終了");

    if (alertMessage !== "") {
      alert(alertMessage);
      return;
    }

    const currentFilterCondition: IFilterCondition = {
      legalAffairsBureauRequestDateStart,
      legalAffairsBureauRequestDateEnd,
      realEstateBookTypeRensakiRenzoku,
      realEstateBookTypeTandoku,
      realEstateTypeTochi,
      realEstateTypeKutate,
      realEstateTypeTatemono,
      realEstateTypeKyotan,
      cities,
      prefectures,
      receptionReasons,
      sotoRange,
      estimateAcreageRange,
      estimateLandPriceRange,
      approximateEstimatePriceRange,
      residenceOptions,
      commercialOptions,
      industryOptions,
      yosekiRatioRange,
      kenpeiRatioRange,
      hasTrade,
      stationSelectedValues,
      walk,
      isTowerApartment,
      isOneRoom,
      isPlant,
      buildDateStart,
      buildDateEnd,
      propertyName,
      buildingAgeRange,
      farmlandAreaRange,
      farmlandCategories,
      isRenewableEnergy,
    };

    // 現在のfilterConditionをhistoryに保存する
    // 路線情報(stationLine)は検索条件とは関係ないため, filterConditionには含まれないが画面の復元に必要なため別途保存する
    window.history.replaceState(
      {
        filterCondition: currentFilterCondition,
        stationLine,
      },
      ""
    );

    // 検索の実行
    setFilterCondition(currentFilterCondition);
  };

  const setPrefectureAndCity = buildPrefectureAndCitySetter(
    cityParams,
    cities,
    setCities,
    setPrefectures
  );

  useEffect(() => {
    if (
      legalAffairsBureauRequestDateEnd &&
      legalAffairsBureauRequestDateStart
    ) {
      const isOneYear = isDateDiffOverOneYear(
        legalAffairsBureauRequestDateStart,
        legalAffairsBureauRequestDateEnd
      );
      if (isOneYear) {
        const date = new Date(legalAffairsBureauRequestDateStart);
        date.setFullYear(legalAffairsBureauRequestDateStart.getFullYear() + 1);
        setLegalAffairsBureauRequestDateEnd(date);
      }
    }
  }, [legalAffairsBureauRequestDateStart]);

  useEffect(() => {
    if (
      legalAffairsBureauRequestDateEnd &&
      legalAffairsBureauRequestDateStart
    ) {
      const isOneYear = isDateDiffOverOneYear(
        legalAffairsBureauRequestDateStart,
        legalAffairsBureauRequestDateEnd
      );
      if (isOneYear) {
        const date = new Date(legalAffairsBureauRequestDateEnd);
        date.setFullYear(date.getFullYear() - 1);
        setLegalAffairsBureauRequestDateStart(date);
      }
    }
  }, [legalAffairsBureauRequestDateEnd]);
  return (
    <RealEstateReceptionBookFeedPresenter
      setLegalAffairsBureauRequestDateStart={
        setLegalAffairsBureauRequestDateStart
      }
      setLegalAffairsBureauRequestDateEnd={setLegalAffairsBureauRequestDateEnd}
      setRealEstateBookTypeTandoku={setRealEstateBookTypeTandoku}
      setRealEstateBookTypeRensakiRenzoku={setRealEstateBookTypeRensakiRenzoku}
      setRealEstateTypeTochi={setRealEstateTypeTochi}
      setRealEstateTypeKutate={setRealEstateTypeKutate}
      setRealEstateTypeTatemono={setRealEstateTypeTatemono}
      setRealEstateTypeKyotan={setRealEstateTypeKyotan}
      setReceptionReasons={setReceptionReasons}
      setCities={setCities}
      setPrefectures={setPrefectureAndCity}
      setStationSelectedValues={setStationSelectedValues}
      setStationLine={setStationLine}
      setWalk={setWalk}
      setSotoRange={setSotoRange}
      setEstimateAcreageRange={setEstimateAcreageRange}
      setEstimateLandPriceRange={setEstimateLandPriceRange}
      setApproximateEstimatePriceRange={setApproximateEstimatePriceRange}
      setResidenceOptions={setResidenceOptions}
      setCommercialOptions={setCommercialOptions}
      setIndustryOptions={setIndustryOptions}
      setYosekiRatioRange={setYosekiRatioRange}
      setKenpeiRatioRange={setKenpeiRatioRange}
      handleSearch={handleSearch}
      setHasTrade={setHasTrade}
      setIsTowerApartment={setIsTowerApartment}
      setIsOneRoom={setIsOneRoom}
      setIsPlant={setIsPlant}
      setBuildDateStart={setBuildDateStart}
      setBuildDateEnd={setBuildDateEnd}
      setPropertyName={setPropertyName}
      setBuildingAgeRange={setBuildingAgeRange}
      setFarmlandAreaRange={setFarmlandAreaRange}
      setFarmlandCategories={setFarmlandCategories}
      setIsRenewableEnergy={setIsRenewableEnergy}
      cities={cities}
      prefectures={prefectures}
      receptionReasons={receptionReasons}
      stationSelectedValues={stationSelectedValues}
      stationLine={stationLine}
      walk={walk}
      realEstateBookTypeTandoku={realEstateBookTypeTandoku}
      realEstateBookTypeRensakiRenzoku={realEstateBookTypeRensakiRenzoku}
      legalAffairsBureauRequestDateStart={legalAffairsBureauRequestDateStart}
      legalAffairsBureauRequestDateEnd={legalAffairsBureauRequestDateEnd}
      realEstateTypeTochi={realEstateTypeTochi}
      realEstateTypeKutate={realEstateTypeKutate}
      realEstateTypeTatemono={realEstateTypeTatemono}
      realEstateTypeKyotan={realEstateTypeKyotan}
      cityOptions={cityOptions}
      prefectureOptions={prefectureOptions}
      stationLineOptions={stationLineOptions}
      stationOptions={stationOptions}
      importStatuses={importStatuses}
      receptionReasonOptions={receptionReasonOptions}
      filterCondition={filterCondition}
      startDate={startDate}
      minDate={minDate}
      sotoRange={sotoRange}
      estimateAcreageRange={estimateAcreageRange}
      estimateLandPriceRange={estimateLandPriceRange}
      approximateEstimatePriceRange={approximateEstimatePriceRange}
      residenceOptions={residenceOptions}
      commercialOptions={commercialOptions}
      industryOptions={industryOptions}
      yosekiRatioRange={yosekiRatioRange}
      kenpeiRatioRange={kenpeiRatioRange}
      maxDate={maxDate}
      hasTrade={hasTrade}
      isTowerApartment={isTowerApartment}
      isOneRoom={isOneRoom}
      isPlant={isPlant}
      buildDateStart={buildDateStart}
      buildDateEnd={buildDateEnd}
      propertyName={propertyName}
      buildingAgeRange={buildingAgeRange}
      farmlandAreaRange={farmlandAreaRange}
      farmlandCategories={farmlandCategories}
      isRenewableEnergy={isRenewableEnergy}
      gridApiRef={gridApiRef}
    />
  );
};

export { RealEstateReceptionBookFeedContainer };
