import { useApiClient } from "./useApiClient";
import { type ISelectObject } from "@/types/select";
import useSWRImmutable from "swr/immutable";

interface IAreaUsePurposeResponse {
  list: Array<{
    id: number;
    areaUsePurposeType: string;
    name: string;
  }>;
}

interface IUseAreaUsePurposeOptionsReturnType {
  areaUsePurposeOptions: ISelectObject[];
}

export const useAreaUsePurposeOptions =
  (): IUseAreaUsePurposeOptionsReturnType => {
    const { apiClient } = useApiClient();

    // swrに引き渡すfetcher
    const fetcher = async (url: string): Promise<IAreaUsePurposeResponse> => {
      const { data } = await apiClient.get<IAreaUsePurposeResponse>(url);
      return data;
    };

    // IAreaUsePurposeResponseをISelectObject[]に変換する関数
    const toSelectObjectList = (
      data: IAreaUsePurposeResponse | undefined
    ): ISelectObject[] => {
      if (data === undefined) return [];

      return data.list.map((areaUsePurpose) => ({
        value: areaUsePurpose.areaUsePurposeType.toString(),
        label: areaUsePurpose.name,
      }));
    };

    const { data: areaUsePurposeOptions } = useSWRImmutable(
      "/option/area-use-purpose-type",
      fetcher
    );

    return {
      areaUsePurposeOptions: toSelectObjectList(areaUsePurposeOptions),
    };
  };
