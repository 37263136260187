import { type AxiosInstance, HttpStatusCode, isAxiosError } from "axios";
import type { PostMonitoringFileAPIResponse } from "@/features/monitoring/types";
import { format } from "date-fns";
import * as Sentry from "@sentry/react";

interface IProps {
  file: File;
  name: string;
  receptionReasons: string[];
  monthlyUpdatable: boolean;
  legalAffairsBureauRequestDateStart?: Date;
  legalAffairsBureauRequestDateEnd?: Date;
}

export const PostMonitoringFromFileAPI = async (
  apiClient: AxiosInstance,
  props: IProps
): Promise<PostMonitoringFileAPIResponse> => {
  const formData = new FormData();
  formData.append("file", props.file);
  formData.append("name", props.name);

  props.receptionReasons.forEach((receptionReason, index) => {
    formData.append(`reception_reason_ids[${index}]`, receptionReason);
  });

  formData.append(
    "monthly_updatable",
    props.monthlyUpdatable ? "true" : "false"
  );
  if (!props.monthlyUpdatable) {
    formData.append(
      "legal_affairs_bureau_request_date_start",
      format(
        props.legalAffairsBureauRequestDateStart ?? new Date(),
        "yyyy-MM-dd"
      )
    );
    formData.append(
      "legal_affairs_bureau_request_date_end",
      format(props.legalAffairsBureauRequestDateEnd ?? new Date(), "yyyy-MM-dd")
    );
  }

  try {
    const response = await apiClient.post<PostMonitoringFileAPIResponse>(
      "/monitoring/file/",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      if (
        (error.response?.status ?? HttpStatusCode.InternalServerError) >=
        HttpStatusCode.InternalServerError
      ) {
        Sentry.captureMessage(
          "不動産モニタリング登録APIでサーバーエラーが発生しました"
        );
      }
    }
    throw error;
  }
};
