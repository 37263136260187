import { useEffect, useState } from "react";
import { useMap, FeatureGroup } from "react-leaflet";
import { LandPriceTileLayer } from "./LandPriceTileLayer";

interface Props {
  defaultVisible: boolean;
  initialZoomLevel: number;
}

export const LAND_PRICE_LAYER_NAME = "公示価格を表示する";

/*
AreaUsePurposeTileLayerをLeafletのLayersControlで制御するためのWrapperコンポーネント
*/
export const LandPriceLayerControlWrapper: React.FC<Props> = ({
  defaultVisible,
  initialZoomLevel,
}) => {
  const [visible, setVisible] = useState(defaultVisible);
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // 一時的にBaseLayerを用いて用途地域レイヤーと同時に表示できないようにする
    // BaseLayer本来の使い方ではないため、用途地域レイヤーと同時表示が可能になり次第この処理は削除する
    map.on("baselayerchange", (e) => {
      if (e.name === LAND_PRICE_LAYER_NAME) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  }, []);

  return (
    <>
      {/* 空のFeatureGroupは、LayersControlに認識させるために必要 */}
      <FeatureGroup />
      <LandPriceTileLayer
        visible={visible}
        initialZoomLevel={initialZoomLevel}
      />
    </>
  );
};
