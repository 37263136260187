import { styled } from "@mui/material/styles";
import { AcquireBookDataGrid } from "@/components/DataGrid";
import {
  type GridSelectionModel,
  type GridColDef,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import { ButtonVariantOption, Button } from "../../../components/Button";
import { type RealEstateTitleSearchDataGridRow } from "../types";
import { useCallback, type Dispatch, type SetStateAction } from "react";
import { feedDetailAPI } from "../api";
import { useApiClient } from "@/hooks/useApiClient";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import { REAL_ESTATE_TYPE_MAP, ID_DELIMITER } from "../routes/TitleSearch";

interface FeedGridProps {
  loading: boolean;
  rows: RealEstateTitleSearchDataGridRow[];
  setRows: Dispatch<SetStateAction<RealEstateTitleSearchDataGridRow[]>>;
  setOpen: (value: boolean) => void;
  setDetailData: (
    value: Array<{ title: string; value: string | string[] }> | null
  ) => void;
  displayHasTrade?: boolean;
}

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

export const FeedGrid: React.FC<FeedGridProps> = ({
  loading,
  rows,
  setRows,
  setOpen,
  setDetailData,
  displayHasTrade = false,
}) => {
  const apiClient = useApiClient().apiClient;

  const clickActionButton = async (id: string): Promise<void> => {
    setDetailData(null);
    setOpen(true);

    const splitId = id.split(ID_DELIMITER);
    const realEstateType =
      Object.values(REAL_ESTATE_TYPE_MAP).find(
        (item) => item.label === splitId[0]
      )?.value ?? "";
    const bookId = Number(splitId[1]);

    const result = await feedDetailAPI(apiClient, {
      id: bookId,
      realEstateType,
    });

    if (result?.status === HttpStatusCode.Unauthorized) {
      toast.info("再度検索ボタンを押下してください");
      return;
    } else if (result?.status === HttpStatusCode.BadRequest) {
      toast.error(result?.error);
      return;
    } else if (result?.status === HttpStatusCode.InternalServerError) {
      toast.error("予期せぬエラーが発生しました");
      return;
    }

    if (result?.data?.land != null) {
      setDetailData(result?.data?.land);
    } else if (result?.data?.building != null) {
      setDetailData(result?.data?.building);
    }
  };

  const colDef: GridColDef[] = [
    {
      field: "realEstateType",
      headerName: "不動産種別",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "prefecture",
      headerName: "都道府県",
      width: 90,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "syozai",
      headerName: "所在",
      width: 300,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chibanKaokuNo",
      headerName: "地番または家屋番号",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ id }: GridRenderCellParams) => {
        return (
          <Button
            variant={ButtonVariantOption.Contained}
            label="詳細"
            onClick={() => {
              void (async () => {
                await clickActionButton(String(id));
              })();
            }}
          />
        );
      },
    },
  ];

  if (displayHasTrade) {
    colDef.splice(1, 0, {
      field: "hasTrade",
      headerName: "売買登記あり",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    });
  }

  const handleRowSelectionChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          selected: newSelectionModel.includes(row.id),
        }))
      );
    },
    [setRows]
  );

  return (
    <StyledDataGrid
      autoHeight
      checkboxSelection
      disableColumnMenu
      disableSelectionOnClick={true}
      loading={loading}
      rows={rows}
      columns={colDef}
      onSelectionModelChange={handleRowSelectionChange}
      sortModel={[
        {
          field: "requestedAt",
          sort: "desc",
        },
      ]}
    />
  );
};
