import { type Control, Controller, type FieldValues } from "react-hook-form";
import {
  DateRangePicker,
  type DateRangePickerIProps,
} from "@/components/DatePicker/DateRangePicker";
import { type ReactElement } from "react";
import { type FieldPath } from "react-hook-form/dist/types";

interface IProps<T extends FieldValues>
  extends Omit<
    DateRangePickerIProps,
    "startDate" | "endDate" | "onChangeStartDate" | "onChangeEndDate"
  > {
  control: Control<T> | undefined;
  name: FieldPath<T>;
}

/**
 * DateRangePickerコンポーネントのコントローラー対応版
 * コントローラーの詳細はReact-Hook-Formのドキュメントを参照
 * https://www.react-hook-form.com/api/usecontroller/controller/
 * @param props
 */
const CDateRangePicker = <T extends FieldValues>(
  props: IProps<T>
): ReactElement => {
  const {
    name,
    control,
    startDateLabel,
    endDateLabel,
    minDate,
    maxDate,
    defaultCalendarMonth,
    startDateDisableFuture = false,
    endDateDisableFuture = false,
    startDateDisablePast = false,
    endDateDisablePast = false,
    views,
    inputFormat,
    mask,
    disabled,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        const dateRange = [...value];
        return (
          <DateRangePicker
            startDateLabel={startDateLabel}
            startDate={dateRange[0]}
            onChangeStartDate={(date) => {
              onChange([date, dateRange[1]]);
            }}
            endDateLabel={endDateLabel}
            endDate={dateRange[1]}
            onChangeEndDate={(date) => {
              onChange([dateRange[0], date]);
            }}
            minDate={minDate}
            maxDate={maxDate}
            defaultCalendarMonth={defaultCalendarMonth}
            startDateDisableFuture={startDateDisableFuture}
            endDateDisableFuture={endDateDisableFuture}
            startDateDisablePast={startDateDisablePast}
            endDateDisablePast={endDateDisablePast}
            views={views}
            inputFormat={inputFormat}
            mask={mask}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export { CDateRangePicker };
