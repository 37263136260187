import {
  type AcquireMultipleBookHandoffData,
  type AcquireBookPictures,
  type AcquireBookZenbujikou,
  type PictureResponse,
  type PictureRequest,
  type BookTypeEng,
} from "@/types/acquirebook";
import { type BookRequestCheckboxStatuses } from "../types";
import { useApiClient } from "@/hooks/useApiClient";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";

interface AcquireBookForm {
  handleSubmit: (
    rows: AcquireMultipleBookHandoffData[],
    checkBoxOptions: BookRequestCheckboxStatuses[],
    pastDays: number | "",
    withOwnerAnalyze: boolean,
    identifierName: string,
    cancelLoading: () => void
  ) => void;
}

const validBookTypes: BookTypeEng[] = [
  "LAND",
  "BUILDING",
  "CONDOMINIUM",
  "ONE_BUILDING",
];

const makeSinglePictureRequest = (
  data: AcquireMultipleBookHandoffData,
  checkboxStates: BookRequestCheckboxStatuses,
  pastDays: number | ""
): PictureRequest => {
  const pictures: AcquireBookPictures[] = [];
  let zenbujikoOptions: AcquireBookZenbujikou[] = [];

  // チェックボックスのチェック状態に応じてpictures, zenbujikoOptionsの値を埋める
  for (const key in checkboxStates) {
    switch (key) {
      case "ownerInfo":
        if (checkboxStates[key].checked) {
          pictures.push("SHOYUSHAJIKO");
        }
        break;
      case "sintaku":
        if (checkboxStates[key].checked) {
          zenbujikoOptions.push("SHINTAKUMOKUROKU");
        }
        break;
      case "kyoutan":
        if (checkboxStates[key].checked) {
          zenbujikoOptions.push("KYODOTANPOMOKUROKU");
        }
        break;
      case "tisekiDrawings":
        if (checkboxStates[key].checked) {
          pictures.push("CHISEKISOKURYOZU");
        }
        break;
      case "electricDrawings":
        if (checkboxStates[key].checked) {
          pictures.push("DENSHIKOZU");
        }
        break;
      case "tiekiDrawings":
        if (checkboxStates[key].checked) {
          pictures.push("CHIEKIKENZUMEN");
        }
        break;
      case "buildingDrawings":
        if (checkboxStates[key].checked) {
          pictures.push("TATEMONOZUMEN");
        }
        break;
    }
  }

  // 共担もしくは信託にチェックが入っていた場合にpicturesに'ZENBUJIKO'を追加
  if (checkboxStates.sintaku.checked || checkboxStates.kyoutan.checked) {
    pictures.push("ZENBUJIKO");
  }

  // 現在事項がチェックされている場合は信託目録と共同担保目録を現在に切り替える
  if (checkboxStates.genzai.checked) {
    zenbujikoOptions = zenbujikoOptions.map((value): AcquireBookZenbujikou => {
      if (value === "KYODOTANPOMOKUROKU") {
        return "KYODOTANPOMOKUROKU_GENZAI";
      } else {
        return "SHINTAKUMOKUROKU_GENZAI";
      }
    });
  }

  // 送信するデータを生成する
  return {
    real_estate_type: data.bookType,
    pref_code: data.prefCode,
    location_full: data.locationFull,
    chiban_kaoku_number: data.chibanKaokuNumber,
    past_days: pastDays,
    pictures,
    zenbujiko_options: zenbujikoOptions,
  };
};

const validateRow = (row: AcquireMultipleBookHandoffData): boolean => {
  return (
    validBookTypes.includes(row.bookType) &&
    row.prefCode !== "" &&
    row.locationFull !== "" &&
    row.chibanKaokuNumber !== ""
  );
};

// リクエストのリストを生成する
const makeRequests = (
  row: AcquireMultipleBookHandoffData[],
  checkBoxOptions: BookRequestCheckboxStatuses[],
  pastDays: number | ""
): PictureRequest[] => {
  const pictureRequests = row.filter(validateRow).map((value, index) => {
    return makeSinglePictureRequest(value, checkBoxOptions[index], pastDays);
  });

  // チェックボックスにチェックが入っていない行がある場合は、その行を配列から取り除きバックエンドに送信しない
  return pictureRequests.filter((value) => {
    return value.pictures.length + value.zenbujiko_options.length !== 0;
  });
};

export const useAcquireBookForm = (): AcquireBookForm => {
  const { apiClient } = useApiClient();
  const navigate = useNavigate();

  const handleSubmit = (
    rows: AcquireMultipleBookHandoffData[],
    checkBoxOptions: BookRequestCheckboxStatuses[],
    pastDays: number | "",
    withOwnerAnalyze: boolean,
    identificationName: string,
    cancelLoading: () => void
  ): void => {
    const items = makeRequests(rows, checkBoxOptions, pastDays);
    const req = {
      withOwnerAnalyze,
      identificationName,
      items,
    };

    // 送信する
    apiClient
      .post<PictureResponse>("/picture-request/", req)
      .then((_res) => {
        navigate("/acquirebooklist");
      })
      .catch((e) => {
        if (isAxiosError(e) && e.response && e.response.data) {
          /* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */
          if (e.response.data.nonFieldErrors?.length) {
            // エラー文字列が配列なので複数のエラー文字列が返されても表示できるようにする
            const errorList: React.ReactNode[] =
              e.response.data.nonFieldErrors.map(
                (value: string): React.ReactNode => {
                  return (
                    <>
                      {value}
                      <br />
                    </>
                  );
                }
              );
            toast.error(<>{errorList}</>);
          }
          /* eslint-enable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */
        }
        if (
          isAxiosError(e) &&
          (e.response?.status ?? HttpStatusCode.InternalServerError) >=
            HttpStatusCode.InternalServerError
        ) {
          // Exclude Validation-Error(400)
          Sentry.captureException(e);
        }
        cancelLoading();
        console.error(e);
      });
  };

  return {
    handleSubmit,
  };
};
