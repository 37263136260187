import L, {LatLng} from "leaflet";

(function (window, document, undefined) {

    /**
     * Llatlng配列に含まれる各点と、スタート地点からの距離をそれぞれ求める
     * points[0]をスタート地点とみなす
     * @param points latlng配列
     * @return [number]
     */
    const getStartToPointsDistances = function (points) {
        if(points.length <= 1) {
            return [];
        }

        const distances = this._getPointsToPointsDistances(points);

        return distances.map((distance, index, array) => {
            return array.slice(0, index).reduce((acc, current) => acc + current, 0);
        });
    };

    /**
     * 総延長を求める
     * @return {number|null}
     */
    const getFullLength = function () {
        const latlngs = this.getLatLngs()[0];
        if(latlngs.length <= 1) {
            return null;
        }

        const distances = this._getPointsToPointsDistances(latlngs);
        return distances.reduce((acc, current) => acc + current, 0);
    }

    /**
     * latlng配列に含まれている点と点のそれぞれの距離を求める
     * @param points latlng配列
     * @return [number]
     */
    const getPointsToPointsDistances = function (points) {
        if(points.length <= 1) {
            return [];
        }
        const distances = points.map((point, index, array) => {
            // 2点間の距離を求める性質上、最初の1点のみでは距離が計算できない
            // そのためindex = 0の場合は結果なしとする
            if(index === 0) return Number.NaN;
            const pointA = array[index - 1];
            const pointB = point;

            // Calculate the distance based on the version
            let distance;
            if (L.GeometryUtil.isVersion07x()) {
                distance = pointA.distanceTo(pointB) * (this.options.factor || 1);
            } else {
                distance = this._map.distance(pointA, pointB) * (this.options.factor || 1);
            }

            return distance;
        });

        // 配列に含まれるNaNを取り除く
        const filteredDistances = distances.filter(value => !Number.isNaN(value));
        // ポリゴンの場合は線が閉じているので、スタート点から最終点の長さも求めることができる
        // それを実装する
        if (L.GeometryUtil.isVersion07x()) {
            filteredDistances.push(points[points.length - 1].distanceTo(points[0]) * (this.options.factor || 1))
        } else {
            filteredDistances.push(this._map.distance(points[points.length - 1], points[0]) * (this.options.factor || 1));
        }

        return filteredDistances;
    };

    /**
     * 2点間の中点を求める
     * @param a A点の座標
     * @param b B点の座標
     * @return {LatLng}
     * @private
     */
    const getMiddlePoint = function (a, b) {
        return new LatLng((a.lat + b.lat) / 2, (a.lng + b.lng) / 2);
    };

    /**
     * latlng配列から、それぞれの中点を求める
     * @param points latlngの配列
     * @return {LatLng}
     * @private
     */
    const getMiddlePoints = function (points) {
        return points.map((point, index, array) => {
            if(index === points.length-1) {
                return this._getMiddlePoint(point, array[0]);
            }
            return this._getMiddlePoint(point, array[index+1]);
        });
    };

    /**
     * スタート点からの距離を表示するTooltipオブジェクトを返す
     * @param latlng ツールチップを表示する座標
     * @param value ツールチップの内容
     * @return {Tooltip}
     * @private
     */
    const getDistanceTooltip = function (latlng, value) {
        return new L.Tooltip(latlng, {
            opacity: 0.75,
            content: `開始点からの距離: ${L.GeometryUtil.readableDistance(value, true)}`,
            permanent: true,
            direction: 'top',
            className: 'leaflet-distance-tooltip',
        });
    };

    /**
     * 辺の長さを表すTooltipオブジェクトを生成して返す
     * @param latlng ツールチップを表示する座標
     * @param value ツールチップの内容
     * @return {Tooltip}
     * @private
     */
    const getLengthTooltip = function (latlng, value) {
        return new L.Tooltip(latlng, {
            opacity: 0.75,
            content: `長さ: ${L.GeometryUtil.readableDistance(value, true)}`,
            permanent: true,
            direction: 'top',
            className: 'leaflet-length-tooltip',
        });
    };


    // ここからleafletの機能拡張を行う
    // 距離ラベルを表示する機能を持ったポリゴンを定義する
    L.LabeledPolygon = L.Polygon.extend({
        // 情報ラベルを表示しておくレイヤー
        _labelsGroup: L.LayerGroup,

        /**
         * ラベルの表示内容を求めて、表示する
         */
        drawLabels: function () {
            const latlngs = this.getLatLngs()[0];

            const distances = this._getStartToPointsDistances(latlngs); // スタート点からの距離
            const lengths = this._getPointsToPointsDistances(latlngs);          // 辺の長さ
            const middlePoints = this._getMiddlePoints(latlngs);        // 辺の中点の座標

            // スタート点からの距離を表すTooltipを生成
            const distanceTips = latlngs.map((value, index) => {
                return this._getDistanceTooltip(value, distances[index]);
            });
            // 始点は終点でもある。始点には終点に表示すべき総延長を表示する
            distanceTips[0] = this._getDistanceTooltip(latlngs[0], this._getFullLength());

            // 辺の長さのTooltipを生成
            const lengthsTips = middlePoints.map((value, index) => {
                return this._getLengthTooltip(value, lengths[index]);
            })


            if(this._labelsGroup) {
                distanceTips.forEach((tip) => {
                    tip.addTo(this._labelsGroup);
                });
                lengthsTips.forEach((tip) => {
                    tip.addTo(this._labelsGroup);
                });
            }
        },

        // 各種内部関数の割り当て
        // 関数名が_から始まっている関数は、leaflet上ではプライベート関数とみなされている
        _getFullLength: getFullLength,
        _getStartToPointsDistances: getStartToPointsDistances,
        _getPointsToPointsDistances: getPointsToPointsDistances,
        _getMiddlePoint: getMiddlePoint,
        _getMiddlePoints: getMiddlePoints,
        _getDistanceTooltip: getDistanceTooltip,
        _getLengthTooltip: getLengthTooltip,
    });

    // LabeledPolygon初期化時の処理を定義している
    // このLabeledPolygonがmapに追加されると同時に_labelsGroupも表示する
    // mapから削除された場合は_labelsGroupは不要なので削除する
    L.LabeledPolygon.addInitHook(function () {
        this._labelsGroup = new L.LayerGroup();

        // LabeledPolygonのインスタンスがmapに追加された時に実行する
        // this._labelsGroupレイヤーをmapに追加し内容を表示する
        this.on("add", function (e) {
            this._map.addLayer(this._labelsGroup);
        });

        // LabeledPolygonのインスタンスがmapから削除された時に実行する
        // this._labelsGroupをmapレイヤーから削除し非表示にする、さらに内容も削除する
        this.on("remove", function (e) {
            this._map.removeLayer(this._labelsGroup);
            delete this._labelsGroup;
        })
    });

    // L.Draw.Polylineについて機能拡張を行う
    // これによって図形描画中に、距離ラベルが表示されるようになる
    L.Draw.Polyline.include({
        _labels: [],
        _labelGroup: L.LayerGroup,
        /**
         * addHooks
         * オブジェクト生成時の初期化処理
         * 元となった処理はこのファイル: node_modules/leaflet-draw/dist/leaflet.draw-src.js
         * 元の処理にlabel表示のための処理を追加している
         */
        addHooks: function () {
            L.Draw.Feature.prototype.addHooks.call(this);
            if (this._map) {
                this._markers = [];
                this._labels = [];

                this._markerGroup = new L.LayerGroup();
                this._map.addLayer(this._markerGroup);

                this._labelGroup = new L.LayerGroup();
                this._map.addLayer(this._labelGroup);

                this._poly = new L.Polyline([], this.options.shapeOptions);

                this._tooltip.updateContent(this._getTooltipText());

                // Make a transparent marker that will used to catch click events. These click
                // events will create the vertices. We need to do this so we can ensure that
                // we can create vertices over other map layers (markers, vector layers). We
                // also do not want to trigger any click handlers of objects we are clicking on
                // while drawing.
                if (!this._mouseMarker) {
                    this._mouseMarker = L.marker(this._map.getCenter(), {
                        icon: L.divIcon({
                            className: 'leaflet-mouse-marker',
                            iconAnchor: [20, 20],
                            iconSize: [40, 40]
                        }),
                        opacity: 0,
                        zIndexOffset: this.options.zIndexOffset
                    });
                }

                this._mouseMarker
                    .on('mouseout', this._onMouseOut, this)
                    .on('mousemove', this._onMouseMove, this) // Necessary to prevent 0.8 stutter
                    .on('mousedown', this._onMouseDown, this)
                    .on('mouseup', this._onMouseUp, this) // Necessary for 0.8 compatibility
                    .addTo(this._map);

                this._map
                    .on('mouseup', this._onMouseUp, this) // Necessary for 0.7 compatibility
                    .on('mousemove', this._onMouseMove, this)
                    .on('zoomlevelschange', this._onZoomEnd, this)
                    .on('touchstart', this._onTouch, this)
                    .on('zoomend', this._onZoomEnd, this);

            }
        },

        // @method removeHooks(): void
        // Remove listener hooks from this handler.
        removeHooks: function () {
            L.Draw.Feature.prototype.removeHooks.call(this);

            this._clearHideErrorTimeout();

            this._cleanUpShape();

            // remove markers from map
            this._map.removeLayer(this._markerGroup);
            delete this._markerGroup;
            delete this._markers;

            this._map.removeLayer(this._labelGroup);
            delete this._labelGroup;
            delete this._labels;

            this._map.removeLayer(this._poly);
            delete this._poly;

            this._mouseMarker
                .off('mousedown', this._onMouseDown, this)
                .off('mouseout', this._onMouseOut, this)
                .off('mouseup', this._onMouseUp, this)
                .off('mousemove', this._onMouseMove, this);
            this._map.removeLayer(this._mouseMarker);
            delete this._mouseMarker;

            // clean up DOM
            this._clearGuides();

            this._map
                .off('mouseup', this._onMouseUp, this)
                .off('mousemove', this._onMouseMove, this)
                .off('zoomlevelschange', this._onZoomEnd, this)
                .off('zoomend', this._onZoomEnd, this)
                .off('touchstart', this._onTouch, this)
                .off('click', this._onTouch, this);
        },
        /**
         * addVertex 点が追加されたタイミングで実行される処理
         * 元々の処理(node_modules/leaflet-draw/dist/leaflet.draw-src.js)をこの関数でオーバライドしている
         * @param latlng
         */
        addVertex: function (latlng) {
            var markersLength = this._markers.length;
            // markersLength must be greater than or equal to 2 before intersections can occur
            if (markersLength >= 2 && !this.options.allowIntersection && this._poly.newLatLngIntersects(latlng)) {
                this._showErrorTooltip();
                return;
            } else if (this._errorShown) {
                this._hideErrorTooltip();
            }

            this._markers.push(this._createMarker(latlng));

            const latlngs = this._markers.map((value) => value.getLatLng());
            if(latlngs && latlngs.length >= 2) {
                const distances = this._getStartToPointsDistances(latlngs);
                const lengths = this._getPointsToPointsDistances(latlngs);
                const middlePoints = this._getMiddlePoints(latlngs);


                const lengthTooltipContent = this._getLengthTooltipContent(lengths, middlePoints);
                if(lengthTooltipContent) {
                    const tip = this._getLengthTooltip(lengthTooltipContent.latlng, lengthTooltipContent.length);

                    this._labelGroup.addLayer(tip);
                    this._labels.push(tip);
                }

                const distanceTooltipContent = this._getDistanceTooltipContent(latlng, distances);
                if(distanceTooltipContent) {
                    const tip = this._getDistanceTooltip(distanceTooltipContent.latlng, distanceTooltipContent.distance);

                    this._labelGroup.addLayer(tip);
                    this._labels.push(tip);
                }
            }

            this._poly.addLatLng(latlng);

            if (this._poly.getLatLngs().length === 2) {
                this._map.addLayer(this._poly);
            }

            this._vertexChanged(latlng, true);
        },

        // @method deleteLastVertex(): void
        // Remove the last vertex from the polyline, removes polyline from map if only one point exists.
        // 最後のポイントを削除ボタンを押した時に、ラベルが削除されるように変更している
        deleteLastVertex: function () {
            if (this._markers.length <= 1) {
                return;
            }

            var lastMarker = this._markers.pop(),
                poly = this._poly,
                // Replaces .spliceLatLngs()
                latlngs = poly.getLatLngs(),
                latlng = latlngs.splice(-1, 1)[0];
            this._poly.setLatLngs(latlngs);

            this._markerGroup.removeLayer(lastMarker);

            // 点削除時にラベルを削除する処理
            const lastLenLabel = this._labels.pop(); // 長さ
            const lastDistLabel = this._labels.pop(); // 開始点からの距離
            this._labelGroup.removeLayer(lastLenLabel);
            this._labelGroup.removeLayer(lastDistLabel);


            if (poly.getLatLngs().length < 2) {
                this._map.removeLayer(poly);
            }

            this._vertexChanged(latlng, false);
        },

        /**
         * 描画完了時に呼び出される処理
         * @private
         */
        _finishShape: function () {
            var latlngs = this._poly._defaultShape ? this._poly._defaultShape() : this._poly.getLatLngs();
            var intersects = this._poly.newLatLngIntersects(latlngs[latlngs.length - 1]);

            if ((!this.options.allowIntersection && intersects) || !this._shapeIsValid()) {
                this._showErrorTooltip();
                return;
            }

            // 最後の点についての距離を計算して表示する
            const distances = this._getStartToPointsDistances(latlngs);
            const lengths = this._getPointsToPointsDistances(latlngs);
            const middlePoints = this._getMiddlePoints(latlngs);

            // 最後の辺の長さを描画
            const lastLengthTip = this._getLengthTooltip(middlePoints[middlePoints.length - 1], lengths[lengths.length - 1]);
            this._labelGroup.addLayer(lastLengthTip);
            this._labels.push(lastLengthTip);

            // 最後の点の開始点からの距離を描画
            const lastDistanceTip = this._getDistanceTooltip(latlngs[0], distances[distances.length - 1]);
            this._labelGroup.addLayer(lastDistanceTip);
            this._labels.push(lastDistanceTip);

            this._fireCreatedEvent();
            this.disable();
            if (this.options.repeatMode) {
                this.enable();
            }
        },

        // L.LabeledPolygonで使っている関数と同じ関数をここで割り当てる
        _getStartToPointsDistances: getStartToPointsDistances,
        _getPointsToPointsDistances: getPointsToPointsDistances,
        _getMiddlePoint: getMiddlePoint,
        _getMiddlePoints: getMiddlePoints,
        _getDistanceTooltip: getDistanceTooltip,
        _getLengthTooltip: getLengthTooltip,

        /**
         * 辺の長さが格納された配列(lengthes)の長さから、その時表示すべき値を取り出す
         * lengthsの配列長が0ならnull
         * 配列長1なら0番目の値を返す
         * 配列長2でも0番目の値
         * 配列長3以降は配列長-2の値を返す
         * @param lengths 辺の長さが格納された配列
         * @param middlePoints 中点が格納された配列
         * @return {{latlng: *, length: *}|null}
         * @private
         */
        _getLengthTooltipContent: function (lengths, middlePoints) {
            const len = lengths.length;
            if(len === undefined) {
                return null;
            }
            const lastValidContentIndex = (len >= 2) ? len-2 : 0;

            switch(len) {
                case 0:
                    return null;
                case 1:
                    return {latlng: middlePoints[0], length: lengths[0]};
                case 2:
                    return {latlng: middlePoints[0], length: lengths[0]};
                default:
                    return {latlng: middlePoints[lastValidContentIndex], length: lengths[lastValidContentIndex]};
            }
        },

        /**
         * スタート点からの長さが格納された配列(distances)の長さから、その時表示すべき値を取り出す
         * distancesの配列長が0ならnull
         * 配列長1なら0番目の値を返す
         * 配列長2でも0番目の値
         * 配列長3以降は配列長-2の値を返す
         * @param latlng ツールチップを表示したい座標
         * @param distances スタート点からの距離が格納された配列
         * @return {{latlng, distance: *}|null}
         * @private
         */
        _getDistanceTooltipContent: function (latlng, distances) {
            const len = distances.length;
            if(len === undefined) {
                return null;
            }
            const lastContentIndex = (len >= 1) ? len-1 : 0;

            switch(len) {
                case 0:
                    return null;
                case 1:
                    return {latlng: latlng, distance: distances[0]};
                case 2:
                    return {latlng: latlng, distance: distances[1]};
                default:
                    return {latlng: latlng, distance: distances[lastContentIndex]};
            }
        },
    });
}(window, document));