import { Box, Link, Stack, Typography } from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { PageTitle } from "@/components/Title";
import Grid from "@mui/material/Grid";
import { PagePaper, SectionPaper } from "@/components/Paper";

import { KeyboardArrowLeft } from "@mui/icons-material";
import React, { useState } from "react";
import { useFeatureFlags } from "@/configs/featureFlag";
import { FileUploadButton } from "@/features/realEstateReceptionBookFeed/components/FileUploadButton";
import { Button, ButtonVariantOption } from "@/components/Button";
import type {
  AcquireMultipleBookHandoffData,
  BookTypeEng,
} from "@/types/acquirebook";
import { HttpStatusCode, isAxiosError } from "axios";
import { toast } from "react-toastify";
import { useApiClient } from "@/hooks/useApiClient";
import { useNavigate } from "react-router-dom";

export const AcquireMultipleBooksUpload: React.FC = () => {
  const { mypageTop } = useFeatureFlags();
  const { apiClient } = useApiClient();
  const navigate = useNavigate();

  // CSVアップロードダイアログの確認ボタンの有効/無効を決定するフラグ
  const [csvUploadConfirmButtonDisabled, setCsvUploadConfirmButtonDisabled] =
    useState<boolean>(true);

  const [fileToCsvUpload, setFileToCsvUpload] = useState<File | null>(null);

  // /api/picture-request/csvを呼び出した結果レスポンスのフォーマットを変換する
  // これはあとで変更する可能性大
  // acquireMultipleBooks/components/FeedGrid.tsxで処理すべきかも。要検討
  const convertCsvParseResult = (
    data: [Record<string, string>]
  ): AcquireMultipleBookHandoffData[] => {
    if (data.length > 0) {
      return data.map((value: Record<string, string>, index: number) => {
        return {
          id: index + 1,
          bookId: Number(value.id),
          bookType: value.realEstateType as BookTypeEng,
          prefCode: value.prefCode,
          prefName: value.prefName,
          locationFull: value.locationFull,
          chibanKaokuNumber: value.chibanKaokuNumber,
          kyoutan: false,
          sintaku: false,
          genzai: false,
          ownerInfo: false,
          electricDrawings: false,
          tisekiDrawings: false,
          tiekiDrawings: false,
          buildingDrawings: false,
        };
      });
    }
    return [];
  };

  // CSVアップロードの確認ボタンが押された時の処理
  const handleCsvUploadButtonClick = (): void => {
    if (fileToCsvUpload) {
      let row: AcquireMultipleBookHandoffData[] = [];
      const formData = new FormData();
      formData.append("file", fileToCsvUpload);
      apiClient
        .post("/picture-request/csv", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          // 受信したレスポンスを次のページへの形式へ変換する
          row = convertCsvParseResult(res.data as [Record<string, string>]);

          // 確認ページへ遷移する
          navigate("/acquiremultiplebooks", { state: row });
        })
        .catch((e) => {
          console.error(e);
          if (
            isAxiosError(e) &&
            e.response?.status === HttpStatusCode.BadRequest
          ) {
            toast.error(
              <div>
                CSVファイルのアップロードに失敗しました。
                <br />
                ファイル内容をご確認ください。
              </div>
            );
          } else {
            toast.error("CSVファイルのアップロードに失敗しました");
          }
        });
    }
  };

  return (
    <PagePaper>
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <RequestQuoteIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>不動産登記/図面取得・CSVアップロード</PageTitle>
          </Box>
        </Box>
        <Grid container spacing={1} mb={1}>
          <Grid item xs={12} sm={12} md={12} textAlign="right">
            <Link href={"/assets/sample_登記図面請求アップロードファイル.csv"}>
              見本CSVをダウンロード
            </Link>
          </Grid>
        </Grid>
        <SectionPaper>
          <Box>
            <Stack alignItems="center">
              <Box my={2}>
                <Typography variant="h6">CSVをアップロードする</Typography>
              </Box>
              <Box my={1}>
                <FileUploadButton
                  variant={ButtonVariantOption.Contained}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    // ファイルが選択されているときのみ確認画面へボタンを有効にする
                    const files = event.target.files ?? [];
                    if (files.length > 0) {
                      setFileToCsvUpload(files[0]);
                      setCsvUploadConfirmButtonDisabled(false);
                    } else {
                      if (fileToCsvUpload === null) {
                        setCsvUploadConfirmButtonDisabled(true);
                      }
                    }
                  }}
                  accept={".csv"}
                  label={"アップロード"}
                />
              </Box>
              <Box my={1}>
                <Typography>
                  {fileToCsvUpload?.name
                    ? `ファイル名: ${fileToCsvUpload?.name}`
                    : ""}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ mx: "auto", my: 2 }}>
            <Button
              variant={ButtonVariantOption.Contained}
              onClick={handleCsvUploadButtonClick}
              disabled={csvUploadConfirmButtonDisabled}
              label={"確認画面へ"}
            />
          </Box>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
