import React from "react";
import { Box, FormControl, FormLabel, Typography } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { type LatLng } from "leaflet";

interface ILandPricePopupContent {
  coordinates: LatLng;
  landPrice: number;
  locationChiban: string;
  year: string;
  jukyoHyoji: string;
}

/**
 * 地価を表示するレイヤーでポップアップ画面の内容を表すReactコンポーネント
 * @param props coordinates 座標
 * @param props landPrice 地価
 * @param props locationChiban 所在＋地番
 * @param props year 年
 * @param props jukyoHyoji 住居表示
 */
export const LandPricePopupContent: React.FC<ILandPricePopupContent> = (
  props
) => {
  const {
    coordinates,
    landPrice,
    locationChiban,
    year,
    jukyoHyoji,
  }: ILandPricePopupContent = props;

  return (
    <Box sx={{ width: 300 }}>
      <FormControl>
        <FormLabel>
          <ImportContactsIcon fontSize={"inherit"} /> 住居表示
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"0.8rem"}
          variant={"body1"}
          component={"span"}
        >
          {jukyoHyoji}
        </Typography>
        <FormLabel>
          <ImportContactsIcon fontSize={"inherit"} /> 年
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"0.8rem"}
          variant={"body1"}
          component={"span"}
        >
          {year}
        </Typography>
        <FormLabel>
          <ImportContactsIcon fontSize={"inherit"} /> 所在 地番
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"0.8rem"}
          variant={"body1"}
          component={"span"}
        >
          {locationChiban}
        </Typography>
        <FormLabel>
          <ImportContactsIcon fontSize={"inherit"} /> 公示価格
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"0.8rem"}
          variant={"body1"}
          component={"span"}
        >
          ￥{landPrice.toLocaleString("jp-JP")}
        </Typography>
        <FormLabel>
          <PlaceIcon fontSize={"inherit"} />
          緯度 経度
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"0.8rem"}
          variant={"body1"}
          component={"span"}
        >
          {coordinates.lat}, {coordinates.lng}
        </Typography>
      </FormControl>
    </Box>
  );
};
