import React, { useMemo } from "react";
import { type Control, Controller, type FieldValues } from "react-hook-form";
import {
  type SelectChangeEvent,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox as MuiCheckbox,
  ListItemText,
  Box,
  Chip,
  IconButton,
  Select as MuiSelect,
  FormHelperText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { type ISelectObject } from "@/types/select";
import type { FieldPath } from "react-hook-form/dist/types";
import { SizingWrapper } from "../../../components/Wrapper";
import { type IProps as MonitoringListMultiSelectProps } from "@/features/monitoring/components/MonitoringListMultiSelect";

const OWNERSHIP_TRANSFER_IDS = [
  "1", // 所有権移転相続・法人合併
  "2", // 所有権移転・相続
  "3", // 所有権移転・一般承継
  "4", // 所有権移転売買
  "5", // 所有権移転遺贈・贈与
  "8", // 所有権移転その他の原因
];

interface IProps<T extends FieldValues>
  extends Omit<MonitoringListMultiSelectProps, "value" | "onChange"> {
  control: Control<T>;
  name: FieldPath<T>;
  value: string[];
  onChange: (value: string[]) => void;
}

const CMultiSelectWithSpecialOptions = <T extends FieldValues>(
  props: IProps<T>
): React.ReactElement => {
  const {
    label,
    options,
    value = [],
    onChange,
    isClearable = true,
    size,
    maxSelect,
    formHelperText,
    control,
    name,
  } = props;

  const additionalMenuItemsForMonitoring: ISelectObject[] = [
    { value: "all", label: "全て" },
    { value: "ownership", label: "所有権移転関連" },
  ];

  const extendedOptions = [...additionalMenuItemsForMonitoring, ...options];

  const displayValueList = useMemo(
    () =>
      extendedOptions
        .filter((item) => value.includes(item.value))
        .map((item) => item.label),
    [value, extendedOptions]
  );

  const handleCustomChange = (
    event: SelectChangeEvent<string[]>,
    controllerOnChange: (value: string[]) => void
  ): void => {
    const selectedValues = event.target.value as string[];

    if (selectedValues.includes("all")) {
      const isAllSelected = extendedOptions
        .filter((option) => !["all", "ownership"].includes(option.value))
        .every((option) => selectedValues.includes(option.value));

      if (isAllSelected) {
        onChange([]);
        controllerOnChange([]);
      } else {
        const newValue = extendedOptions
          .filter((option) => !["all", "ownership"].includes(option.value))
          .map((option) => option.value);
        onChange(newValue);
        controllerOnChange(newValue);
      }
      return;
    }

    if (selectedValues.includes("ownership")) {
      const isOwnershipSelected = OWNERSHIP_TRANSFER_IDS.every((id) =>
        selectedValues.includes(id)
      );
      if (isOwnershipSelected) {
        const newValue = value.filter(
          (v) => !OWNERSHIP_TRANSFER_IDS.includes(v)
        );
        onChange(newValue);
        controllerOnChange(newValue);
      } else {
        const newValue = [...value, ...OWNERSHIP_TRANSFER_IDS];
        onChange(newValue);
        controllerOnChange(newValue);
      }
      return;
    }

    const filteredValues = selectedValues.filter(
      (val) => val !== "all" && val !== "ownership"
    );
    onChange(filteredValues);
    controllerOnChange(filteredValues);
  };

  const handleClearClick = (): void => {
    onChange([]);
  };

  const getDisabled = (option: ISelectObject): boolean => {
    if (maxSelect == null) {
      return false;
    }
    return (
      value.length >= maxSelect && !value.some((item) => item === option.value)
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange: controllerOnChange, value },
        fieldState: { error },
      }) => {
        return (
          <SizingWrapper size={size}>
            <FormControl fullWidth>
              <InputLabel id="multi-select-label">{label}</InputLabel>
              <MuiSelect
                id="multi-select-id"
                labelId="multi-select-label"
                label={label}
                onChange={(event) => {
                  handleCustomChange(event, controllerOnChange);
                }}
                multiple
                value={value as string[]}
                defaultValue={[]}
                error={Boolean(error)}
                sx={{
                  "#multi-select-id": {
                    pr: 0,
                  },
                }}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {displayValueList.map((label) => (
                      <Chip
                        key={label}
                        label={label}
                        sx={{ cursor: "pointer" }}
                      />
                    ))}
                  </Box>
                )}
                endAdornment={
                  isClearable ? (
                    <IconButton
                      sx={{
                        display:
                          displayValueList.length === 0
                            ? "none"
                            : "inline-flex",
                        marginRight: "10px",
                      }}
                      onClick={handleClearClick}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <></>
                  )
                }
              >
                {extendedOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={getDisabled(option)}
                    sx={{
                      marginTop: ["all", "ownership"].includes(option.value)
                        ? "8px"
                        : "0px",
                      marginBottom: ["all", "ownership"].includes(option.value)
                        ? "8px"
                        : "0px",
                    }}
                  >
                    {!["all", "ownership"].includes(option.value) && (
                      <MuiCheckbox
                        checked={(value as string[]).some(
                          (item) => item === option.value
                        )}
                      />
                    )}
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </MuiSelect>
              {formHelperText ? (
                <FormHelperText error={Boolean(error)}>
                  {formHelperText}
                </FormHelperText>
              ) : null}
            </FormControl>
          </SizingWrapper>
        );
      }}
    />
  );
};

export { CMultiSelectWithSpecialOptions };
