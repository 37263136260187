import React, { useId } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Button, ButtonVariantOption } from "@/components/Button";
import { type DialogProps } from "@mui/material/Dialog/Dialog";

export interface RealEstateDetailDialogContent {
  title: React.ReactNode;
  value: React.ReactNode;
}

interface IRealEstateDetailModalProps {
  setOpen: (value: boolean) => void;
  title: React.ReactNode;
  contents?: RealEstateDetailDialogContent[] | null;
}

/**
 * 不動産情報の詳細を表示するモーダル
 * contents配列の内容を全て展開して配置します
 * @param props
 */
const RealEstateDetailModal: React.FC<
  IRealEstateDetailModalProps & DialogProps
> = (props: IRealEstateDetailModalProps & DialogProps) => {
  const { open, setOpen, onClose, fullWidth, maxWidth, title, contents } =
    props;

  const ariaLabel = useId();
  const ariaDescribe = useId();

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        setOpen(false);
        if (onClose) {
          onClose(event, reason);
        }
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescribe}
    >
      <DialogTitle id={ariaLabel}>{title}</DialogTitle>
      {contents === null ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent>
          <DialogContentText
            id={ariaDescribe}
            sx={{ display: "flex", flexWrap: "wrap" }}
            component="dl"
          >
            {contents?.map(
              (item, index) =>
                // NOTE: 2025/01/27 hotfixにて地番または家屋番号を非表示に変更
                !["地番または家屋番号", "地番", "家屋番号"].includes(
                  String(item.title)
                ) && (
                  <React.Fragment key={index}>
                    <Typography
                      component={"dt"}
                      fontWeight={"bold"}
                      width={"25%"}
                      my={1}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      component={"dd"}
                      sx={{ ml: 2, whiteSpace: "pre-line" }}
                      width={"70%"}
                      my={1}
                    >
                      {Array.isArray(item.value)
                        ? item.value.join("\n")
                        : item.value ?? "-"}
                    </Typography>
                  </React.Fragment>
                )
            )}
          </DialogContentText>
        </DialogContent>
      )}
      <Box sx={{ mx: "auto", my: 4 }}>
        <Button
          variant={ButtonVariantOption.Contained}
          onClick={() => {
            setOpen(false);
          }}
          disabled={false}
          label={"閉じる"}
        />
      </Box>
    </Dialog>
  );
};
export default RealEstateDetailModal;
