import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Box,
  DialogTitle,
  Dialog,
  DialogContent,
  Button as MuiButton,
  Stack,
  type SelectChangeEvent,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ButtonVariantOption, LinkButton } from "../../components/Button";
import { Divider } from "@/components/Divider";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle, SectionTitle } from "@/components/Title";
import { FeedGrid } from "./components/FeedGrid";
import { ReceptionBookImportStatus } from "./components/ReceptionBookImportStatus";
import {
  type ISingleBookAcquireDialogData,
  type IRealEstateReceptionBookFeedPresenterProps,
  type IMultipleBookAcquireData,
} from "./types";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import {
  preventNonNumericValueWhenKeyDown,
  preventNonNumericValueWhenPaste,
} from "@/utils/utils";
import { SearchCondition } from "./components/SearchCondition";
import Tooltip from "@mui/material/Tooltip";
import { type NavigateOptions } from "react-router/dist/lib/context";
import { useFeatureFlags } from "@/configs/featureFlag";
import type { HandOffMessage } from "@/types/handOffMessage";
import { useGeoSpaceApi } from "@/features/map/hooks/useGeospaceSearch";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { AcquireBookFormUI } from "@/components/AcquireBook/AcquireBookFormUI";

const RealEstateReceptionBookFeedPresenter: React.FC<
  IRealEstateReceptionBookFeedPresenterProps
> = (props) => {
  const {
    gridApiRef,
    handleSearch,
    setSotoRange,
    setEstimateAcreageRange,
    setEstimateLandPriceRange,
    setApproximateEstimatePriceRange,
    setResidenceOptions,
    setCommercialOptions,
    setIndustryOptions,
    setYosekiRatioRange,
    setKenpeiRatioRange,
    setHasTrade,
    setBuildingAgeRange,
    setFarmlandAreaRange,
    filterCondition,
    importStatuses,
    sotoRange,
    estimateAcreageRange,
    estimateLandPriceRange,
    approximateEstimatePriceRange,
    residenceOptions,
    commercialOptions,
    industryOptions,
    yosekiRatioRange,
    kenpeiRatioRange,
    hasTrade,
    buildingAgeRange,
    farmlandAreaRange,
  } = props;

  const navigate = useNavigate();
  const { ownerInfoList, allMattersAnalysis } = useFeatureFlags();

  const { geospaceGetMapServiceContractAppId } = useGeoSpaceApi();

  // 登記/図面取得ダイアログ表示/非表示を決定するフラグ
  const [singleBookAcquireDialogStatus, setSingleBookAcquireDialogStatus] =
    useState<boolean>(false);

  // 登記/図面取得ダイアログで使用するデータをまとめて格納するState
  const [singleBookAcquireDialogData, setSingleBookAcquireDialogData] =
    useState<ISingleBookAcquireDialogData>({
      prefecturesId: 0,
      prefectures: "",
      address: "",
      chiban: "",
      kaokuNumber: "",
      realEstateTypeEng: "",
    });

  // feedの一覧から登記複数取得を行うためのState
  const [multipleBookAcquireData, setMultipleBookAcquireData] =
    useState<IMultipleBookAcquireData>({
      buttonString: "不動産登記/図面\n取得（個別）",
      buttonDisabled: false,
      tooltipMessage: "",
      selectedItem: [],
    });

  const handleSingleBookAcquireDialogClose = (): void => {
    setSingleBookAcquireDialogStatus(false);
  };

  // セレクトボックスが変更された時の処理
  const handleSelectChange = (
    key: string,
    event: SelectChangeEvent<number | null>,
    handleTarget: string
  ): void => {
    const value = Number(event.target.value);

    // valueが-1の場合は「指定なし」を選択したということなのでnullに変換して代入する
    if (handleTarget === "yosekiRatio") {
      const newRange = { ...yosekiRatioRange };
      newRange[key] = value === -1 ? null : value;
      setYosekiRatioRange(newRange);
    } else if (handleTarget === "kenpeiRatio") {
      const newRange = { ...kenpeiRatioRange };
      newRange[key] = value === -1 ? null : value;
      setKenpeiRatioRange(newRange);
    }
  };

  // テキストフィールドが変更されたときの処理
  const handleInputChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleTarget: string
  ): void => {
    // 入力された値を取得
    const value: string = event.target.value;

    if (handleTarget === "soto") {
      const newRange = { ...sotoRange };
      newRange[key] = toNumber(value);
      setSotoRange(newRange);
    } else if (handleTarget === "estimateAcreage") {
      const newRange = { ...estimateAcreageRange };
      newRange[key] = toNumber(value);
      setEstimateAcreageRange(newRange);
    } else if (handleTarget === "estimateLandPrice") {
      const newRange = { ...estimateLandPriceRange };
      newRange[key] = toNumber(value);
      setEstimateLandPriceRange(newRange);
    } else if (handleTarget === "approximateEstimatePrice") {
      const newRange = { ...approximateEstimatePriceRange };
      newRange[key] = toNumber(value);
      setApproximateEstimatePriceRange(newRange);
    } else if (handleTarget === "buildingAge") {
      const newRange = { ...buildingAgeRange };
      const numberVal = toNumber(value);
      // 1未満の入力値は許可しない。
      if (numberVal != null && numberVal < 1) {
        event.target.value = "";
        return;
      }
      newRange[key] = numberVal;
      setBuildingAgeRange(newRange);
    } else if (handleTarget === "farmlandArea") {
      const newRange = { ...farmlandAreaRange };
      const numberVal = toNumber(value);
      if (numberVal != null && (numberVal < 1 || numberVal > 2147483647)) {
        event.target.value = "";
        return;
      }
      newRange[key] = numberVal;
      setFarmlandAreaRange(newRange);
    }
  };

  function toNumber(value: string): number | null {
    if (value === "") {
      return null;
    } else {
      return Number(value);
    }
  }

  // 住居系のチェックボックスの状態を管理するステート
  const [residenceAllChecked, setResidenceAllChecked] = React.useState(false);
  // 商業系のチェックボックスの状態を管理するステート
  const [commercialAllChecked, setCommercialAllChecked] = React.useState(false);
  // 工業系のチェックボックスの状態を管理するステート
  const [industryAllChecked, setIndustryAllChecked] = React.useState(false);
  // 地番地図を表示する権限があるかどうかを判定するステート
  const [hasMapViewingPermission, setHasMapViewingPermission] =
    React.useState(false);

  // 住居系のチェックボックスの一括処理
  const handleResidenceChangeAll = (event: boolean): void => {
    // チェックボックスの状態を反転させる
    setResidenceAllChecked(!residenceAllChecked);
    // 選択肢のチェックボックスの状態を全て同じにする
    setResidenceOptions({
      ittei: event,
      icchuko: event,
      ichiju: event,
      nitei: event,
      nichuko: event,
      niju: event,
      denju: event,
      junju: event,
    });
  };

  // 商業系のチェックボックスの一括処理
  const handleCommercialChangeAll = (event: boolean): void => {
    // チェックボックスの状態を反転させる
    setCommercialAllChecked(!commercialAllChecked);
    // 選択肢のチェックボックスの状態を全て同じにする
    setCommercialOptions({
      kinsyo: event,
      syogyo: event,
    });
  };

  // 工業系のチェックボックスの一括処理
  const handleIndustryChangeAll = (event: boolean): void => {
    // チェックボックスの状態を反転させる
    setIndustryAllChecked(!industryAllChecked);
    // 選択肢のチェックボックスの状態を全て同じにする
    setIndustryOptions({
      junko: event,
      kogyo: event,
      kosen: event,
    });
  };

  // 選択肢のチェックボックスが変更されたときの処理
  const handleOptionChange = (
    event: boolean,
    handleTarget: string,
    key: string
  ): void => {
    // 選択肢のチェックボックスが全てチェックされているかどうかを判定する
    // const isAllChecked = Object.values(options).every((value) => value);

    if (handleTarget === "residence") {
      setResidenceOptions({
        ...residenceOptions,
        [key]: event,
      });
      // 全選択がうまく機能しないので保留
      // setResidenceAllChecked(isAllChecked);
    } else if (handleTarget === "commercial") {
      setCommercialOptions({
        ...commercialOptions,
        [key]: event,
      });
      // 全選択がうまく機能しないので保留
      // setCommercialAllChecked(isAllChecked);
    } else if (handleTarget === "industry") {
      setIndustryOptions({
        ...industryOptions,
        [key]: event,
      });
      // 全選択がうまく機能しないので保留
      // setIndustryAllChecked(isAllChecked);
    }
  };

  // 地図画面を別タブで開くため、ローカルストレージに地図に反映するためのデータを保存する
  const handleMapReflectClick = (): void => {
    const handOffMessage: HandOffMessage = {
      command: "MAP_DRAW_RECEPTION_BOOKS",
      argument: multipleBookAcquireData.selectedItem,
    };
    const navigateOptions: NavigateOptions = {
      state: { handOffMessage },
    };
    const stateKey = `mapState_${Date.now()}`;
    localStorage.setItem(stateKey, JSON.stringify(navigateOptions.state));
    window.open(`/map?stateKey=${stateKey}`, "_blank", "noopener,noreferrer");
  };

  // 登記/図面取得ボタンが押された時の処理
  const handleAcquireMultipleBooksClick = (): void => {
    if (multipleBookAcquireData.selectedItem.length > 0) {
      // feedのテーブルが1件以上選択されている場合複数登記取得画面へ遷移
      navigate("/acquiremultiplebooks", {
        state: multipleBookAcquireData.selectedItem,
      });
    } else {
      // feedのテーブルが選択されていない場合単体登記取得画面へ遷移
      navigate("/acquirebook", { state: {} });
    }
  };

  // ログイン直後に遷移する画面のためlocalstorageが読み取れないケースがあり
  // 地番地図のAPP IDを直接取得しているが、他画面でAPP IDを取得する際はuseMapServicePermissionを使う
  useEffect(() => {
    (async () => {
      // 地番地図のAPP IDを取得
      const appId = await geospaceGetMapServiceContractAppId();
      // APP IDが取得できた場合は地番地図を表示する権限ありと判断する
      setHasMapViewingPermission(!!appId);
    })();
  }, []);

  return (
    <>
      {/* 登記1件取得のダイアログ */}
      <Dialog
        open={singleBookAcquireDialogStatus}
        onClose={handleSingleBookAcquireDialogClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"登記および図面選択"}
        </DialogTitle>
        <DialogContent>
          <AcquireBookFormUI
            mode={"modal"}
            singleBookAcquireDialogData={singleBookAcquireDialogData}
          />
        </DialogContent>
      </Dialog>

      <PagePaper>
        <Accordion defaultExpanded sx={{ mb: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">お知らせ</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReceptionBookImportStatus importStatuses={importStatuses} />
          </AccordionDetails>
        </Accordion>

        <Box sx={{ display: "flex", mb: 3 }}>
          <MenuBookIcon sx={{ mr: 1 }} fontSize="large" />
          <PageTitle>不動産登記受付帳検索</PageTitle>
        </Box>

        {/* 検索条件 */}
        <SearchCondition
          handleInputChange={handleInputChange}
          preventNonNumericValueWhenKeyDown={preventNonNumericValueWhenKeyDown}
          preventNonNumericValueWhenPaste={preventNonNumericValueWhenPaste}
          residenceAllChecked={residenceAllChecked}
          handleResidenceChangeAll={handleResidenceChangeAll}
          handleOptionChange={handleOptionChange}
          commercialAllChecked={commercialAllChecked}
          handleCommercialChangeAll={handleCommercialChangeAll}
          industryAllChecked={industryAllChecked}
          handleIndustryChangeAll={handleIndustryChangeAll}
          handleSelectChange={handleSelectChange}
          {...props}
        />

        <Box sx={{ mt: 3 }} />

        {/* 不動産登記受付帳一覧テーブル */}
        <SectionPaper>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <SectionTitle>不動産登記受付帳一覧</SectionTitle>
            <Stack mx={2} my={2} direction="row" spacing={1}>
              {hasMapViewingPermission && (
                <Tooltip
                  title={
                    <>
                      地図に表示できるのは地番を持った受付帳データです
                      <br />
                      家屋番号の受付帳データは地図に表示されません
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <MuiButton
                    onClick={handleMapReflectClick}
                    variant="contained"
                    sx={{ fontWeight: "bold" }}
                  >
                    地図に反映する
                  </MuiButton>
                </Tooltip>
              )}
              {allMattersAnalysis ? (
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="/allmatters/upload"
                  sx={{ "text-align": "center", fontWeight: "bold" }}
                  wrapperSize={SizingWrapperStyle.INHERIT}
                >
                  全部事項
                  <br />
                  PDFアップロード
                </LinkButton>
              ) : null}
              {ownerInfoList ? (
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="/ownerinfo/upload"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                  wrapperSize={SizingWrapperStyle.INHERIT}
                >
                  所有者事項
                  <br />
                  PDFアップロード
                </LinkButton>
              ) : null}
              <Tooltip
                title={multipleBookAcquireData.tooltipMessage}
                placement="top"
                arrow
              >
                <div style={{ display: "inherit" }}>
                  <MuiButton
                    onClick={handleAcquireMultipleBooksClick}
                    variant="contained"
                    disabled={multipleBookAcquireData.buttonDisabled}
                    sx={{ whiteSpace: "pre-wrap", fontWeight: "bold" }}
                  >
                    {multipleBookAcquireData.buttonString}
                  </MuiButton>
                </div>
              </Tooltip>
              <LinkButton
                variant={ButtonVariantOption.Contained}
                href="/acquiremultiplebooks/upload"
                sx={{ fontWeight: "bold" }}
                wrapperSize={SizingWrapperStyle.INHERIT}
              >
                不動産登記/図面
                <br />
                取得（一括）
              </LinkButton>
            </Stack>
          </Stack>

          <Divider />

          <FeedGrid
            apiRef={gridApiRef}
            filterCondition={filterCondition}
            toolbarProps={{
              hasTradeState: { hasTrade, setHasTrade },
              handleSearch,
            }}
            setSingleBookAcquireDialogStatus={setSingleBookAcquireDialogStatus}
            setSingleBookAcquireDialogData={setSingleBookAcquireDialogData}
            setMultipleBookAcquireData={setMultipleBookAcquireData}
          />

          <Divider />

          <Box sx={{ mb: 6 }} />
        </SectionPaper>
      </PagePaper>
    </>
  );
};

export { RealEstateReceptionBookFeedPresenter };
