import {
  Box,
  Button as MuiButton,
  Grid,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Modal,
  Tooltip,
  RadioGroup as MuiRadioGroup,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  AlertTitle,
} from "@mui/material";
import { Select as CSelect } from "@/components/Select/Select";
import { toast } from "react-toastify";
import { Button, ButtonVariantOption } from "../../../components/Button";
import { RowSpacingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle, SectionTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import React, { useState } from "react";
import { useFeatureFlags } from "@/configs/featureFlag";
import { MultiSelect } from "@/components/Select";
import { Divider } from "@/components/Divider";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Label } from "@/components/Label";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Checkbox, CheckboxGroup } from "@/components/Checkbox";
import { z } from "zod";
import { format, getYear, parse } from "date-fns";
import ja from "date-fns/locale/ja";
import { usePrefectureOptions } from "@/features/realEstateReceptionBookFeed/hooks/usePrefectureOptions";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCityOptions } from "@/features/realEstateReceptionBookFeed/hooks/useCityOptions";
import { useCitySelect } from "@/features/realEstateReceptionBookFeed/hooks/useCitySelect";
import { useReceptionReasonOptions } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionReasonOptions";
import { useAreaUsePurposeOptions } from "@/hooks/useAreaUsePurposeOptions";
import { getSearchMaxDate } from "@/features/realEstateReceptionBookFeed/utils/dateTime";
import { CMultiSelect } from "@/components/Select/CMultiSelect";
import { DateRangePicker } from "@/components/DatePicker";
import {
  preventNonNumericValueWhenKeyDown,
  preventNonNumericValueWhenPaste,
} from "@/utils/utils";
import { FeedGrid } from "../components/FeedGrid";
import RealEstateDetailModal from "@/features/titleSearch/components/RealEstateDetailModal";
import { useApiClient } from "@/hooks/useApiClient";
import {
  type RealEstateTitleSearchAPIRequest,
  type RealEstateTitleSearchAPIResponseStatus,
  type RealEstateTitleSearchDataGridRow,
} from "../types";
import { useAcquireBookForm } from "@/features/acquireMultipleBooks/hooks/useAcquireBookForm";
import {
  type BookTypeEng,
  type AcquireMultipleBookHandoffData,
} from "@/types/acquirebook";
import { AcquireMultipleBooksModal } from "@/features/map/components/AcquireMultipleBooksModal";
import { HttpStatusCode } from "axios";
import { feedAPI, feedHasTradeAPI } from "../api";
import { type ISelectObject } from "@/types/select";

// 不動産種別のマップ
export const REAL_ESTATE_TYPE_MAP = {
  land: { label: "土地", value: "land" },
  kubunBuilding: { label: "区分建物", value: "kubun_building" },
  building: { label: "建物", value: "building" },
};
// 構造
const BUILDING_VARIANT_OPTIONS: ISelectObject[] = [
  { value: "-1", label: "指定なし" },
  { value: "1", label: "木造" },
  { value: "2", label: "CB" },
  { value: "3", label: "CFT" },
  { value: "4", label: "鉄骨造" },
  { value: "5", label: "RC" },
  { value: "6", label: "SRC" },
  { value: "7", label: "PC" },
  { value: "8", label: "HPC" },
  { value: "9", label: "AL" },
  { value: "10", label: "ALC" },
  { value: "11", label: "鉄筋ブロック造" },
  { value: "12", label: "その他" },
];
// 容積率で入力可能な最小値、最小値を定義する。
const YOUSEKI_RATIO_MIN = 50;
const YOUSEKI_RATIO_MAX = 1300;
// 建ぺい率で入力可能な最小値、最小値を定義する。
const KENPEI_RATIO_MIN = 30;
const KENPEI_RATIO_MAX = 80;
// 築年数で入力可能な最小値を定義する。
const BUILDING_AGE_MIN = 0;
// 築年数で入力可能な最大値を定義する。
// BEのコードで、現在日時のyearからMAX+1を引いた年をstart_dateとしている。
// datetime()のyearに0以下は指定できないので、最大値は現在日時のyear-2とする。
const BUILDING_AGE_MAX =
  getYear(
    parse(
      format(new Date(), "yyyy-MM-dd", { locale: ja }),
      "yyyy-MM-dd",
      new Date()
    )
  ) - 2;
// FeedGridに渡すIDのためのデリミタ
export const ID_DELIMITER = "___";
// 売買スクリーニングが処理が可能な最大件数
const MAX_HAS_TRADE_COUNT = 1000;

/**
 * 最小値最大値を設定する場合の関数
 * @param min 設定可能な最小値
 * @param max 設定可能な最大値
 * @param rangeErrorMessage 範囲開始値<範囲終了値となっていない場合のエラーメッセージ
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeRangeSchemaWithRestrictMinMax = (
  min: number,
  max: number,
  rangeErrorMessage: string
) => {
  const minErrorMessage = `${min}以上の数値を入力してください`;
  const maxErrorMessage = `${max}以下の数値を入力してください`;

  /**
   * 最小値のチェック。
   * @param {string} value
   * @returns {boolean}
   */
  const checkMin = (value: number | null): boolean => {
    if (value === null) return true;
    return min <= value;
  };

  /**
   * 最大値のチェック。
   * @param {string} value
   * @returns {boolean}
   */
  const checkMax = (value: number | null): boolean => {
    if (value === null) return true;
    return max >= value;
  };

  return z
    .object({
      min: z
        .number()
        .nullable()
        .refine((value) => checkMin(value), minErrorMessage)
        .refine((value) => checkMax(value), maxErrorMessage),
      max: z
        .number()
        .nullable()
        .refine((value) => checkMin(value), minErrorMessage)
        .refine((value) => checkMax(value), maxErrorMessage),
    })
    .refine(
      (args) => {
        // min<=maxを確認。
        const { min, max } = args;
        if (min === null || max === null) return true;
        return min <= max;
      },
      {
        message: rangeErrorMessage,
      }
    );
};

/**
 * 最小値最大値を設定しない場合の関数
 * @param rangeErrorMessage 範囲開始値<範囲終了値となっていない場合のエラーメッセージ
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeRangeSchema = (rangeErrorMessage: string) => {
  return z
    .object({
      min: z.number().nullable(),
      max: z.number().nullable(),
    })
    .refine(
      (args) => {
        // min<=maxを確認。
        const { min, max } = args;
        if (min === null || max === null) return true;
        return min <= max;
      },
      {
        message: rangeErrorMessage,
      }
    );
};

export const TitleSearch: React.FC = () => {
  const { mypageTop, realEstateTitle } = useFeatureFlags();
  const apiClient = useApiClient().apiClient;
  const { handleSubmit: acquireBookFormHandleSubmit } = useAcquireBookForm();

  // 都道府県一覧
  const { prefectureOptions } = usePrefectureOptions();
  // 市区町村一覧
  const { cityParams } = useCityOptions();

  // 築年月の選択可能最大値
  const maxDate = getSearchMaxDate();

  // 用途地域一覧を取得
  const { areaUsePurposeOptions } = useAreaUsePurposeOptions();

  // 登記原因一覧を取得
  const { receptionReasonOptions } = useReceptionReasonOptions();

  // 表題部検索の結果一覧
  const [realEstateTitles, setRealEstateTitles] = useState<
    RealEstateTitleSearchDataGridRow[]
  >([]);

  // 登記図面取得モーダル用のState
  const [acquireMultipleBooksRows, setAcquireMultipleBooksRows] = useState<
    AcquireMultipleBookHandoffData[]
  >([]);

  // 登記図面取得モーダル表示制御用のState
  const [openAcquireMultipleBooksModal, setOpenAcquireMultipleBooksModal] =
    useState<boolean>(false);

  // 売買スクリーニングのスイッチの状態
  const [switchHasTrade, setSwitchHasTrade] = useState<boolean>(false);

  // 売買スクリーニングのスイッチの活性/非活性
  const [, setSwitchDisabled] = useState<boolean>(true);

  // 詳細ダイアログの表示
  const [openRealEstateDetailModal, setOpenRealEstateDetailModal] =
    useState<boolean>(false);

  // 詳細ダイアログに表示するデータのID
  const [detailData, setDetailData] = useState<Array<{
    title: string;
    value: string | string[];
  }> | null>(null);

  // 検索時のローディング
  const [loading, setLoading] = useState<boolean>(false);

  const FormDataSchema = z.object({
    prefecture: z.array(z.number()).nonempty("都道府県を選択してください"),
    city: z.array(z.number()).nonempty("市区町村を選択してください"),
    address: z.string(),
    areaUsePurpose: z.array(z.number()),
    yousekiRatio: makeRangeSchemaWithRestrictMinMax(
      YOUSEKI_RATIO_MIN,
      YOUSEKI_RATIO_MAX,
      "「以上」は「以下」より小さくしてください"
    ),
    kenpeiRatio: makeRangeSchemaWithRestrictMinMax(
      KENPEI_RATIO_MIN,
      KENPEI_RATIO_MAX,
      "「以上」は「以下」より小さくしてください"
    ),
    realEstateType: z
      .string()
      .trim()
      .refine((value) => value.length > 0, {
        message: "不動産種別を選択してください",
      }),
    receptionReasons: z.array(z.string()),
    requestDateRange: z.tuple([z.date().nullable(), z.date().nullable()]),
    ownerName: z.string(),
    ownerAddress: z.string(),
    landType: z.object({
      farm: z.boolean(),
      factoryStorage: z.boolean(),
      solar: z.boolean(),
    }),
    estimateAcreage: makeRangeSchema(
      "「以上」は「以下」より小さくしてください"
    ),
    price: makeRangeSchema("「以上」は「以下」より小さくしてください"),
    walk: z.number().nullable(),
    buildingType: z.object({
      mansion: z.boolean(),
      apartment: z.boolean(),
      building: z.boolean(),
      jpHotel: z.boolean(),
      shop: z.boolean(),
      hotel: z.boolean(),
      factory: z.boolean(),
    }),
    floorCount: makeRangeSchema("「以上」は「以下」より小さくしてください"),
    buildingVariant: z.number().nullable(),
    buildDateRange: z.tuple([z.date().nullable(), z.date().nullable()]),
    buildingAge: makeRangeSchemaWithRestrictMinMax(
      BUILDING_AGE_MIN,
      BUILDING_AGE_MAX,
      "「以上」は「以下」より小さくしてください"
    ),
    area: makeRangeSchema("「以上」は「以下」より小さくしてください"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type FormData = z.infer<typeof FormDataSchema>;

  // 型を用いてReact-Hook-Formのインスタンスを作る
  const rhf = useForm<FormData>({
    mode: "all",
    defaultValues: {
      prefecture: [],
      city: [],
      address: "",
      areaUsePurpose: [],
      yousekiRatio: { min: null, max: null },
      kenpeiRatio: { min: null, max: null },
      realEstateType: "",
      receptionReasons: [],
      requestDateRange: [null, null],
      ownerName: "",
      ownerAddress: "",
      landType: { farm: false, factoryStorage: false, solar: false },
      estimateAcreage: { min: null, max: null },
      price: { min: null, max: null },
      walk: null,
      buildingType: {
        apartment: false,
        mansion: false,
        building: false,
        jpHotel: false,
        shop: false,
        hotel: false,
        factory: false,
      },
      floorCount: { min: null, max: null },
      buildingVariant: null,
      buildDateRange: [null, null],
      buildingAge: { min: null, max: null },
      area: { min: null, max: null }, // 専有面積は1度実装後に画面項目から削除した経緯があり、バックエンドに送信する昼用があるため残している
    },
    resolver: zodResolver(FormDataSchema),
  });

  const handleSubmit = rhf.handleSubmit(
    async (data: RealEstateTitleSearchAPIRequest) => {
      setRealEstateTitles([]);
      setSwitchHasTrade(false);
      setSwitchDisabled(true);
      setLoading(true);

      const result = await feedAPI(apiClient, data);

      if (result?.status === HttpStatusCode.Unauthorized) {
        toast.info("再度検索ボタンを押下してください");
        setLoading(false);
        return;
      } else if (result?.status === HttpStatusCode.Ok && result?.timeout) {
        toast.info(
          "該当するデータが多すぎて処理できませんでした。より詳細な条件で再度検索してください。"
        );
        setLoading(false);
        return;
      } else if (result?.status === HttpStatusCode.BadRequest) {
        toast.error(result?.error);
        return;
      } else if (result?.status === HttpStatusCode.InternalServerError) {
        toast.error("予期せぬエラーが発生しました");
        return;
      }

      const rows: RealEstateTitleSearchDataGridRow[] =
        result?.data?.list.map(
          (item: RealEstateTitleSearchAPIResponseStatus, index) => ({
            id: `${item.realEstateType}${ID_DELIMITER}${item.id}${ID_DELIMITER}${index}`,
            originalId: item.id,
            realEstateType: item.realEstateType,
            prefecture: item.prefecture,
            prefCode: item.prefCode,
            syozai: item.syozai,
            chibanKaokuNo: item.chibanKaokuNo,
          })
        ) ?? [];
      setRealEstateTitles(rows);
      setSwitchDisabled(rows.length > MAX_HAS_TRADE_COUNT);
      setLoading(false);
    }
  );

  // 都道府県の変更を検知する
  const prefWatch = rhf.watch("prefecture");

  // 選択している都道府県に応じた市区町村リストを取得する
  const { cityOptions } = useCitySelect(
    prefWatch.map((value) => value.toString()),
    cityParams
  );

  const handleClickAcquireButton = (): void => {
    const selected = realEstateTitles.filter((row) => row.selected);
    if (selected.length === 0) {
      toast.info("取得対象が選択されていません");
      return;
    }

    setAcquireMultipleBooksRows(
      selected.map((row) => {
        const getBookType = (
          row: RealEstateTitleSearchDataGridRow
        ): BookTypeEng => {
          if (row.realEstateType === REAL_ESTATE_TYPE_MAP.land.label) {
            return "LAND";
          } else if (
            row.realEstateType === REAL_ESTATE_TYPE_MAP.kubunBuilding.label
          ) {
            return "CONDOMINIUM";
          } else if (
            row.realEstateType === REAL_ESTATE_TYPE_MAP.building.label
          ) {
            return "BUILDING";
          } else {
            return "ONE_BUILDING";
          }
        };
        return {
          id: row.originalId,
          bookType: getBookType(row),
          prefCode: row.prefCode,
          prefName: row.prefecture,
          locationFull: row.syozai,
          chibanKaokuNumber: row.chibanKaokuNo,
          kyoutan: false,
          sintaku: false,
          genzai: false,
          ownerInfo: false,
          electricDrawings: false,
          tisekiDrawings: false,
          tiekiDrawings: false,
          buildingDrawings: false,
        };
      })
    );

    setOpenAcquireMultipleBooksModal(true);
  };

  if (!realEstateTitle) return null;

  return (
    <PagePaper>
      {/* ページタイトル */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <SavedSearchIcon sx={{ mr: 1 }} fontSize="large" />
        <PageTitle>表題部検索</PageTitle>
      </Box>
      <SectionPaper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SectionTitle>絞り込み</SectionTitle>
        </Box>
        <Box sx={{ mx: 2 }}>
          <Divider />
        </Box>
        <Grid sx={{ m: 2 }}>
          <AlertTitle
            sx={{ fontWeight: "bold", color: "red", fontSize: "0.85rem" }}
          >
            ・検索に時間が40秒程度かかる場合があります
          </AlertTitle>
          <AlertTitle
            sx={{ fontWeight: "bold", color: "red", fontSize: "0.85rem" }}
          >
            ・検索にタイムアウトした場合は、さらに条件を絞り込んで再度検索してください
          </AlertTitle>
          <Grid item xs={12}>
            <RowSpacingWrapper>
              <Box sx={{ my: 1 }}>
                <Label>地域</Label>
                <Controller
                  control={rhf.control}
                  name="prefecture"
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <MultiSelect
                        error={Boolean(fieldState.error)}
                        formHelperText={fieldState.error?.message}
                        label="都道府県 *"
                        value={value.map((value) => value.toString())}
                        onChange={(value) => {
                          onChange(value.map((value) => Number(value)));
                          /* eslint-disable */
                          // @ts-ignore
                          // 市区町村の選択をリセットする
                          rhf.setValue("city", []);
                        }}
                        options={prefectureOptions}
                        maxSelect={2}
                      />
                    );
                  }}
                />
              </Box>
              <Box sx={{ my: 1, pt: 4 }}>
                <Controller
                  control={rhf.control}
                  name="city"
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <MultiSelect
                        error={Boolean(fieldState.error)}
                        formHelperText={fieldState.error?.message}
                        label="市区町村 *"
                        value={value.map((value) => value.toString())}
                        onChange={(value) => {
                          onChange(value.map((value) => Number(value)));
                        }}
                        options={cityOptions}
                        maxSelect={2}
                      />
                    );
                  }}
                />
              </Box>
              <Box sx={{ my: 1, pt: 4, width: "25%" }}>
                <Controller
                  control={rhf.control}
                  name="address"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TextField
                        label="大字・町丁目"
                        value={value}
                        onChange={onChange}
                        fullWidth={true}
                      />
                    );
                  }}
                />
              </Box>
            </RowSpacingWrapper>
          </Grid>
          <Grid item xs={12} mt={1}>
            <RowSpacingWrapper>
              <Box sx={{ my: 1 }}>
                <Controller
                  control={rhf.control}
                  name={"realEstateType"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <CheckboxGroup
                        label="不動産種別 *"
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      >
                        <MuiRadioGroup
                          name="real_estate_type"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <MuiFormControlLabel
                              value={REAL_ESTATE_TYPE_MAP.land.value}
                              control={<MuiRadio />}
                              label={REAL_ESTATE_TYPE_MAP.land.label}
                            />
                            <MuiFormControlLabel
                              value={REAL_ESTATE_TYPE_MAP.kubunBuilding.value}
                              control={<MuiRadio />}
                              label={REAL_ESTATE_TYPE_MAP.kubunBuilding.label}
                            />
                            <MuiFormControlLabel
                              value={REAL_ESTATE_TYPE_MAP.building.value}
                              control={<MuiRadio />}
                              label={REAL_ESTATE_TYPE_MAP.building.label}
                            />
                          </Box>
                        </MuiRadioGroup>
                      </CheckboxGroup>
                    );
                  }}
                />
              </Box>
              <Box sx={{ my: 1, mr: 2 }}>
                <Label>登記原因</Label>
                <CMultiSelect<FormData>
                  name={"receptionReasons"}
                  control={rhf.control}
                  label="登記原因"
                  options={receptionReasonOptions}
                  maxSelect={3}
                />
              </Box>
            </RowSpacingWrapper>
          </Grid>
          <Grid item xs={12} mt={1}>
            <RowSpacingWrapper>
              <Box sx={{ my: 1 }}>
                <Label>法務局受付日</Label>
                <Box sx={{ display: "flex" }}>
                  <Controller
                    control={rhf.control}
                    name={"requestDateRange"}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <DateRangePicker
                          startDateLabel="法務局受付日 開始"
                          startDate={value[0]}
                          onChangeStartDate={(date) => {
                            onChange([date, value[1]]);
                          }}
                          endDateLabel="法務局受付日 終了"
                          endDate={value[1]}
                          maxDate={maxDate}
                          onChangeEndDate={(date) => {
                            onChange([value[0], date]);
                          }}
                          views={["year", "month", "day"]}
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
            </RowSpacingWrapper>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Label>所有者名（部分一致）</Label>
                <TextField {...rhf.register("ownerName")} fullWidth={true} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Label>所有者住所（部分一致）</Label>
                <TextField {...rhf.register("ownerAddress")} fullWidth={true} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Accordion
          expanded={
            REAL_ESTATE_TYPE_MAP.land.value === rhf.watch("realEstateType")
          }
          square
          sx={{ mb: 1, boxShadow: "none", position: "static" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ flexDirection: "row-reverse" }}
          >
            <Typography sx={{ color: "blue" }}>土地の条件から探す</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid container sx={{ top: "50%" }}>
                <Grid item xs={12}>
                  <CheckboxGroup label="土地種別">
                    <Box sx={{ display: "flex", flexDirection: "row", mr: 2 }}>
                      <Controller
                        control={rhf.control}
                        name={"landType"}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <>
                              <Checkbox
                                label="農地"
                                checked={value.farm}
                                onChange={(checked) => {
                                  onChange({ ...value, farm: checked });
                                }}
                              />
                              <Checkbox
                                label="工場・倉庫"
                                checked={value.factoryStorage}
                                onChange={(checked) => {
                                  onChange({
                                    ...value,
                                    factoryStorage: checked,
                                  });
                                }}
                              />
                              <Checkbox
                                label="太陽光"
                                checked={value.solar}
                                onChange={(checked) => {
                                  onChange({ ...value, solar: checked });
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </Box>
                  </CheckboxGroup>
                  <Grid sx={{ display: "inline-block", top: "50%", mr: 1 }}>
                    <Label>用途地域</Label>
                    <Controller
                      control={rhf.control}
                      name="areaUsePurpose"
                      render={({ field: { value, onChange }, fieldState }) => {
                        return (
                          <MultiSelect
                            error={Boolean(fieldState.error)}
                            formHelperText={fieldState.error?.message}
                            label="用途地域"
                            value={value.map((value) => value.toString())}
                            onChange={(value) => {
                              onChange(value.map((value) => Number(value)));
                            }}
                            options={areaUsePurposeOptions}
                            maxSelect={5}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%", mr: 1 }}>
                <Label>推計地積</Label>
                <Controller
                  control={rhf.control}
                  name={"estimateAcreage"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        ㎡以上 〜
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        ㎡以下
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>公示価格</Label>
                <Controller
                  control={rhf.control}
                  name={"price"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        円/㎡以上 〜
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        円/㎡以下
                      </>
                    );
                  }}
                />
              </Grid>
            </Box>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%", mr: 1 }}>
                <Label>容積率</Label>
                <Controller
                  control={rhf.control}
                  name={"yousekiRatio"}
                  render={({
                    field: { value, onChange },
                    formState,
                    fieldState,
                  }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(
                            formState.errors.yousekiRatio?.min ??
                              fieldState.error
                          )}
                          helperText={
                            formState.errors.yousekiRatio?.min?.message ??
                            fieldState.error?.message
                          }
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 50,
                            max: 1300,
                            type: "number",
                            step: "10",
                          }}
                        />
                        %以上 〜
                        <TextField
                          error={Boolean(
                            formState.errors.yousekiRatio?.max ??
                              fieldState.error
                          )}
                          helperText={
                            formState.errors.yousekiRatio?.max?.message ??
                            fieldState.error?.message
                          }
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 50,
                            max: 1300,
                            type: "number",
                            step: "10",
                          }}
                        />
                        %以下
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid sx={{ display: "inline-block", top: "50%", mr: 1 }}>
                <Label>建ぺい率</Label>
                <Controller
                  control={rhf.control}
                  name={"kenpeiRatio"}
                  render={({
                    field: { value, onChange },
                    formState,
                    fieldState,
                  }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(
                            formState.errors.kenpeiRatio?.min ??
                              fieldState.error
                          )}
                          helperText={
                            formState.errors.kenpeiRatio?.min?.message ??
                            fieldState.error?.message
                          }
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 30,
                            max: 80,
                            type: "number",
                            step: "10",
                          }}
                        />
                        %以上 〜
                        <TextField
                          error={Boolean(
                            formState.errors.kenpeiRatio?.max ??
                              fieldState.error
                          )}
                          helperText={
                            formState.errors.kenpeiRatio?.max?.message ??
                            fieldState.error?.message
                          }
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 30,
                            max: 80,
                            type: "number",
                            step: "10",
                          }}
                        />
                        %以下
                      </>
                    );
                  }}
                />
              </Grid>
            </Box>
            <Box sx={{ mx: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>最寄駅からの徒歩時間</Label>
                <Controller
                  name={"walk"}
                  control={rhf.control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select
                        value={value === null ? -1 : value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        sx={{ width: "150px", minHeight: "56px" }}
                        variant="outlined"
                      >
                        <MenuItem key={-1} value={-1}>
                          指定なし
                        </MenuItem>
                        {[...Array(60).keys()]
                          .filter((val) => val >= 1)
                          .map((val) => (
                            <MenuItem key={val} value={val}>
                              {val}分以内
                            </MenuItem>
                          ))}
                        <MenuItem key={60} value={60}>
                          60分以上
                        </MenuItem>
                      </Select>
                    );
                  }}
                />
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* 不動産種別 */}

        <Accordion
          expanded={[
            REAL_ESTATE_TYPE_MAP.kubunBuilding.value,
            REAL_ESTATE_TYPE_MAP.building.value,
          ].includes(rhf.watch("realEstateType"))}
          sx={{ mb: 1, boxShadow: "none", position: "static" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ flexDirection: "row-reverse" }}
          >
            <Typography sx={{ color: "blue" }}>建物の条件から探す</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Box sx={{ mx: 2, mb: 2 }}>
              <CheckboxGroup label="建物種別">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Controller
                    control={rhf.control}
                    name={"buildingType"}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <>
                          <Checkbox
                            label="マンション"
                            checked={value.mansion}
                            onChange={(checked) => {
                              onChange({ ...value, mansion: checked });
                            }}
                          />
                          <Checkbox
                            label="アパート"
                            checked={value.apartment}
                            onChange={(checked) => {
                              onChange({ ...value, apartment: checked });
                            }}
                          />
                          <Checkbox
                            label="ビル"
                            checked={value.building}
                            onChange={(checked) => {
                              onChange({ ...value, building: checked });
                            }}
                          />
                          <Checkbox
                            label="旅館"
                            checked={value.jpHotel}
                            onChange={(checked) => {
                              onChange({ ...value, jpHotel: checked });
                            }}
                          />
                          <Checkbox
                            label="店舗"
                            checked={value.shop}
                            onChange={(checked) => {
                              onChange({ ...value, shop: checked });
                            }}
                          />
                          <Checkbox
                            label="ホテル"
                            checked={value.hotel}
                            onChange={(checked) => {
                              onChange({ ...value, hotel: checked });
                            }}
                          />
                          <Checkbox
                            label="工場"
                            checked={value.factory}
                            onChange={(checked) => {
                              onChange({ ...value, factory: checked });
                            }}
                          />
                        </>
                      );
                    }}
                  />
                </Box>
              </CheckboxGroup>
            </Box>

            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>階数</Label>
                <Controller
                  control={rhf.control}
                  name={"floorCount"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "1",
                          }}
                        />
                        階以上 〜
                        <TextField
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "1",
                          }}
                        />
                        階以下
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid sx={{ display: "inline-block", top: "50%", ml: 1 }}>
                <Label>構造</Label>
                <Controller
                  name={"buildingVariant"}
                  control={rhf.control}
                  render={({ field: { onChange } }) => {
                    return (
                      <CSelect
                        label={""}
                        options={BUILDING_VARIANT_OPTIONS}
                        onChange={(value) => {
                          onChange(Number(value));
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Box>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>専有面積</Label>
                <Controller
                  control={rhf.control}
                  name={"area"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "1",
                          }}
                        />
                        ㎡以上 〜
                        <TextField
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max:
                                event.target.value === ""
                                  ? null
                                  : Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "1",
                          }}
                        />
                        ㎡以下
                      </>
                    );
                  }}
                />
              </Grid>
            </Box>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%", mr: 1 }}>
                <Label>築年月</Label>
                <Box sx={{ display: "flex" }}>
                  <Controller
                    control={rhf.control}
                    name={"buildDateRange"}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <DateRangePicker
                          startDateLabel="以降"
                          startDate={value[0]}
                          onChangeStartDate={(date) => {
                            onChange([date, value[1]]);
                          }}
                          endDateLabel="以前"
                          endDate={value[1]}
                          maxDate={maxDate}
                          onChangeEndDate={(date) => {
                            onChange([value[0], date]);
                          }}
                          inputFormat="yyyy年"
                          mask="____年"
                          views={["year"]}
                        />
                      );
                    }}
                  />
                </Box>
              </Grid>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>築年数</Label>
                <Controller
                  control={rhf.control}
                  name={"buildingAge"}
                  render={({ field: { value, onChange }, fieldState }) => (
                    <TextField
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      value={
                        value.min === null || value.min === 0 ? "" : value.min
                      }
                      label="年以上"
                      onChange={(event) => {
                        onChange({
                          min:
                            event.target.value === ""
                              ? null
                              : Number(event.target.value),
                          max: value.max,
                        });
                      }}
                      onKeyDown={undefined}
                      onPaste={undefined}
                      sx={{ width: "150px", top: "50%", mr: 1 }}
                      inputProps={{
                        min: 0,
                        type: "number",
                        step: "10",
                      }}
                    />
                  )}
                />
                <Controller
                  control={rhf.control}
                  name="buildingAge"
                  render={({ field: { value, onChange }, fieldState }) => (
                    <TextField
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      value={
                        value.max === null || value.max === 0 ? "" : value.max
                      }
                      id="buildingAgeMax"
                      label="年以下"
                      onChange={(event) => {
                        onChange({
                          min: value.min,
                          max:
                            event.target.value === ""
                              ? null
                              : Number(event.target.value),
                        });
                      }}
                      onKeyDown={preventNonNumericValueWhenKeyDown}
                      onPaste={preventNonNumericValueWhenPaste}
                      sx={{ width: "150px", top: "50%" }}
                      inputProps={{
                        min: 0,
                        type: "number",
                        step: "10",
                      }}
                    />
                  )}
                />
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Box sx={{ my: 3, mr: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            label="この条件で検索"
            onClick={() => {
              (async () => {
                await handleSubmit();
              })();
            }}
            variant={ButtonVariantOption.Contained}
            size={SizingWrapperStyle.SMALL}
            disabled={rhf.formState.isSubmitting || !rhf.formState.isValid}
          />
        </Box>
      </SectionPaper>

      <Box sx={{ mt: 3 }} />

      {/* 不動産情報一覧テーブル */}
      <SectionPaper>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Box display={"flex"} justifyContent={"center"}>
            <SectionTitle>不動産情報一覧</SectionTitle>
          </Box>
          <Tooltip
            title={`1度で登記/図面取得できる不動産は${MAX_HAS_TRADE_COUNT}件までです`}
            placement="top"
          >
            <Stack mx={2} my={2} direction="row" spacing={1}>
              <MuiButton
                disabled={
                  realEstateTitles.filter((row) => row.selected).length === 0 ||
                  realEstateTitles.filter((row) => row.selected).length >
                    MAX_HAS_TRADE_COUNT
                }
                onClick={() => {
                  handleClickAcquireButton();
                }}
                variant="contained"
                sx={{ fontWeight: "bold" }}
              >
                不動産登記/図面
                <br />
                取得（一括）
              </MuiButton>
            </Stack>
          </Tooltip>
        </Stack>

        <FeedGrid
          loading={loading}
          rows={realEstateTitles}
          setRows={setRealEstateTitles}
          setOpen={setOpenRealEstateDetailModal}
          setDetailData={setDetailData}
          displayHasTrade={switchHasTrade}
        />

        <Box sx={{ mb: 6 }} />
      </SectionPaper>

      {/* 戻る */}
      <Box sx={{ mt: 2 }}>
        <Link
          href={mypageTop ? "/mypage" : "/feed"}
          sx={{ display: "inline-block" }}
        >
          <Box sx={{ display: "flex" }}>
            <KeyboardArrowLeft />
            <Typography>
              {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
            </Typography>
          </Box>
        </Link>
      </Box>
      {/* モーダルを表示する際に使用するコンポーネント */}
      <RealEstateDetailModal
        open={openRealEstateDetailModal}
        setOpen={setOpenRealEstateDetailModal}
        fullWidth={true}
        maxWidth={"md"}
        title={"不動産情報詳細"}
        contents={detailData}
      />
      {/* モーダルを表示する際に使用するコンポーネント */}
      <Modal
        open={openAcquireMultipleBooksModal}
        aria-labelledby={"acquire-multiple-books-modal"}
        aria-describedby={"acquire-multiple-books-modal"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            outline: "none",
          }}
          onClick={() => {
            setOpenAcquireMultipleBooksModal(false);
          }}
        >
          <Box
            sx={{
              width: 1600,
              maxWidth: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <AcquireMultipleBooksModal
              handleSubmit={acquireBookFormHandleSubmit}
              rows={acquireMultipleBooksRows}
              isOpenedTitleSearch={true}
            />
          </Box>
        </Box>
      </Modal>
    </PagePaper>
  );
};
