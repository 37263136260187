import { PagePaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { Box, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  type KeyName,
  type MonitoringDetailEntity,
  type MonitoringDataGridRow,
  type GetMonitoringDetailsAPIResponse,
} from "@/features/monitoring/types";
import { getDisplayReceptionReason } from "@/features/monitoring/utils/getDisplayReceptionReason";
import { GetMonitoringDetailAPI } from "../api";
import { useApiClient } from "@/hooks/useApiClient";
import { MonitoringDetailDataGrid } from "../components/MonitoringDetailDataGrid";
import { AcquireBookModal } from "../components/AcquireBookModal";
import { KeyboardArrowLeft } from "@mui/icons-material";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { type ISingleBookAcquireDialogData } from "@/features/realEstateReceptionBookFeed/types";
import { type IPrefecture } from "@/types/prefectures";
import { GetPrefecturesAPI } from "@/api/GetPrefecturesAPI";
import { HttpStatusCode, isAxiosError } from "axios";
import { toast } from "react-toastify";

/**
 * モニタリング詳細画面
 */
export const MonitoringDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { apiClient } = useApiClient();

  const [count, setCount] = useState<number>(0); // 件数用State
  const [receptionReason, setReceptionReason] = useState<string>(""); // 登記原因用State
  const [monthlyUpdatableLabel, setMonthlyUpdatableLabel] =
    useState<string>("");
  const [legalAffairsBureauRequestDateStart, setReceptionDateStart] =
    useState<string>("");
  const [legalAffairsBureauRequestDateEnd, setReceptionDateEnd] =
    useState<string>("");

  const [rows, setRows] = useState<MonitoringDataGridRow[]>([]); // モニタリング詳細結果用State
  const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]); // チェックボックスやAction選択の管理用State
  const [acquireBookOpen, setAcquireBookOpen] = useState<boolean>(false); // 不動産登記取得モーダル表示用State
  const [prefectures, setPrefectures] = useState<IPrefecture[]>([]);

  // 結果のチェックボックスのON/OFF時に実行される。
  const handleRowSelectionChange = (
    newSelectionModel: GridSelectionModel
  ): void => {
    setSelectedIds(newSelectionModel);
  };

  /**
   * resultsに「謄本取得する」時に表示するチェックボックスの初期値を設定。
   * @param {MonitoringDetailEntity[]} details
   * @returns {MonitoringDataGridRow[]}
   */
  const initialize = (
    details: MonitoringDetailEntity[]
  ): MonitoringDataGridRow[] => {
    return details.map((detail) => ({
      id: detail.id,
      prefectureName:
        prefectures.find((prefecture) => prefecture.id === detail.prefectureId)
          ?.name ?? "",
      realEstateType: detail.realEstateType,
      location: detail.location,
      chibanKaokuNumber: detail.chibanKaokuNumber,
      registrationDate: detail.registrationDate,
      remarks: detail.remarks,
      syoyusya: {
        checked: false,
        unchecked: checkDisable("syoyusya", detail.realEstateType),
      },
      zennbu: {
        checked: false,
        unchecked: checkDisable("zennbu", detail.realEstateType),
      },
      sintaku: {
        checked: false,
        unchecked: checkDisable("sintaku", detail.realEstateType),
      },
      kyodo: {
        checked: false,
        unchecked: checkDisable("kyodo", detail.realEstateType),
      },
      genzai: {
        checked: false,
        unchecked: checkDisable("genzai", detail.realEstateType),
      },
      tiseki: {
        checked: false,
        unchecked: checkDisable("tiseki", detail.realEstateType),
      },
      densi: {
        checked: false,
        unchecked: checkDisable("densi", detail.realEstateType),
      },
      tieki: {
        checked: false,
        unchecked: checkDisable("tieki", detail.realEstateType),
      },
      tatemono: {
        checked: false,
        unchecked: checkDisable("tatemono", detail.realEstateType),
      },
    }));
  };

  /**
   * 各種別でチェックボックスの活性非活性を判定。
   * /features/acquireMultipleBooks/presenter.tsxのcheckDisable()を参考にした。
   * @param {KeyName} keyName
   * @param {BookTypeEng} realEstateType
   * @returns {boolean}
   */
  const checkDisable = (keyName: KeyName, realEstateType: string): boolean => {
    const table: Record<string, Record<string, boolean>> = {
      syoyusya: { 土地: false, 建物: false, 区分建物: false },
      zennbu: { 土地: false, 建物: false, 区分建物: false },
      sintaku: { 土地: false, 建物: false, 区分建物: false },
      kyodo: { 土地: false, 建物: false, 区分建物: false },
      genzai: { 土地: false, 建物: false, 区分建物: false },
      tiseki: { 土地: false, 建物: true, 区分建物: true },
      densi: { 土地: false, 建物: true, 区分建物: true },
      tieki: { 土地: false, 建物: true, 区分建物: true },
      tatemono: { 土地: true, 建物: false, 区分建物: false },
    };

    return table[keyName][realEstateType] ?? false;
  };

  const { data, isLoading, error } = GetMonitoringDetailAPI(
    apiClient,
    Number(id)
  );

  useEffect(() => {
    (() => {
      if (
        isAxiosError<GetMonitoringDetailsAPIResponse>(error) &&
        (error.response?.status === HttpStatusCode.BadRequest ||
          error.response?.status === HttpStatusCode.NotFound)
      ) {
        toast.error("指定のデータが存在しません。");
        return;
      }
      if (
        isAxiosError<GetMonitoringDetailsAPIResponse>(error) &&
        (error.response?.status ?? HttpStatusCode.InternalServerError) >=
          HttpStatusCode.InternalServerError
      ) {
        toast.error("データの取得に失敗しました。再度お試しください。");
        return;
      }
      setCount(data?.count ?? 0);
      setReceptionReason(
        data ? getDisplayReceptionReason(data.summary.receptionReasons) : ""
      );

      if (data == null || data.summary.monthlyUpdatable == null) {
        setMonthlyUpdatableLabel("");
      } else {
        setMonthlyUpdatableLabel(
          data.summary.monthlyUpdatable ? "する" : "しない"
        );
      }

      setReceptionDateStart(
        data?.summary.legalAffairsBureauRequestDateStart
          ? String(data.summary.legalAffairsBureauRequestDateStart)
          : ""
      );
      setReceptionDateEnd(
        data?.summary.legalAffairsBureauRequestDateEnd
          ? String(data.summary.legalAffairsBureauRequestDateEnd)
          : ""
      );
      setRows(data ? initialize(data.details) : []);
    })();
  }, [data, error, prefectures]);

  useEffect(() => {
    (async () => {
      // 都道府県リストをAPIから取得する。
      const prefectures = await GetPrefecturesAPI(apiClient);
      setPrefectures(prefectures);
    })();
  }, []);

  const transferSingleBookAcquireDialogData = (
    rows: MonitoringDataGridRow[],
    selectedIds: GridSelectionModel
  ): ISingleBookAcquireDialogData => {
    const row = rows.find((row) => row.id === selectedIds[0]);
    if (!row) {
      return {
        prefecturesId: 0,
        prefectures: "",
        address: "",
        chiban: "",
        kaokuNumber: "",
        realEstateTypeEng: "",
      };
    }

    const realEstateTypeEng =
      row.realEstateType === "土地"
        ? "LAND"
        : row.realEstateType === "建物"
        ? "BUILDING"
        : row.realEstateType === "区分建物"
        ? "CONDOMINIUM"
        : "";

    return {
      prefecturesId:
        prefectures.find((prefecture) => prefecture.name === row.prefectureName)
          ?.id ?? 0,
      prefectures: row.prefectureName,
      address: row.location,
      chiban: row.chibanKaokuNumber,
      kaokuNumber: "",
      realEstateTypeEng,
    };
  };

  return (
    <PagePaper>
      {/* ページタイトル */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <MonitorHeartIcon sx={{ mr: 1 }} fontSize="large" />
        <PageTitle>不動産登記モニタリング詳細</PageTitle>
      </Box>

      {/* 件数と登記原因 */}
      <Stack direction={"column"} spacing={1} sx={{ mt: 3 }}>
        <Stack direction={"row"} spacing={1}>
          <Typography>件数：</Typography>
          <Typography sx={{ color: "#DF9B4A", fontWeight: "bold" }}>
            {count}
          </Typography>
          <Typography>件</Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography>登記原因：</Typography>
          <Typography sx={{ color: "#DF9B4A", fontWeight: "bold" }}>
            {receptionReason}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography>月次更新：</Typography>
          <Typography sx={{ color: "#DF9B4A", fontWeight: "bold" }}>
            {monthlyUpdatableLabel}
            {monthlyUpdatableLabel === "しない" &&
            legalAffairsBureauRequestDateStart !== "" &&
            legalAffairsBureauRequestDateEnd !== ""
              ? `（${legalAffairsBureauRequestDateStart} 〜 ${legalAffairsBureauRequestDateEnd}）`
              : ""}
          </Typography>
        </Stack>
      </Stack>

      {/* 結果 */}
      <Box sx={{ mt: 3 }}>
        {isLoading || data == null ? (
          <Stack alignItems="center">
            <CircularProgress sx={{ my: "20px" }} />
          </Stack>
        ) : (
          <MonitoringDetailDataGrid
            handleRowSelectionChange={handleRowSelectionChange}
            navigate={navigate}
            rows={rows}
            setSelectedIds={setSelectedIds}
            setAcquireBookOpen={setAcquireBookOpen}
          />
        )}
      </Box>

      {/* 戻るリンク */}
      <Box sx={{ mt: 3 }}>
        <Link href={"/monitoring"} sx={{ display: "inline-block" }}>
          <Box sx={{ display: "flex" }}>
            <KeyboardArrowLeft />
            <Typography>不動産登記モニタリング一覧に戻る</Typography>
          </Box>
        </Link>
      </Box>

      {/* 登記取得モーダル */}
      <AcquireBookModal
        acquireBookOpen={acquireBookOpen}
        handleClose={() => {
          setAcquireBookOpen(false);
          setSelectedIds([]);
        }}
        row={transferSingleBookAcquireDialogData(rows, selectedIds)}
      />
    </PagePaper>
  );
};
