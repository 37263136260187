import { styled } from "@mui/material/styles";
import { AcquireBookDataGrid } from "@/components/DataGrid";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { useApiClient } from "@/hooks/useApiClient";
import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  forwardRef,
  type ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
} from "react";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import type { GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import type { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import type {
  GetMonitoringSummariesAPIResponse,
  MonitoringSummaryRow,
  MonitoringFeedGridProps,
  DisplayMonthlyUpdatable,
} from "@/features/monitoring/types";
import { getDisplayReceptionReason } from "@/features/monitoring/utils/getDisplayReceptionReason";
import { GetMonitoringListAPI } from "../api/GetMonitoringListAPI";
import { HttpStatusCode, isAxiosError } from "axios";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "count",
      headerName: "件数",
      width: 80,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "name",
      headerName: "識別名",
      width: 300,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "receptionReasons",
      headerName: "登記原因",
      width: 120,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "monthlyUpdatable",
      headerName: "月次更新",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "legalAffairsBureauRequestDateStart",
      headerName: "法務局受付日 開始",
      width: 160,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "legalAffairsBureauRequestDateEnd",
      headerName: "法務局受付日 終了",
      width: 160,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdAt",
      headerName: "登録日",
      width: 120,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 525,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderCell: ({
        id,
        row,
      }: GridRenderCellParams<string, MonitoringSummaryRow>) => {
        return (
          <Stack direction="row" spacing={2}>
            <LinkButton
              variant={ButtonVariantOption.Contained}
              wrapperSize={SizingWrapperStyle.INHERIT}
              href={`/monitoring/${row.id}/`}
            >
              詳細
            </LinkButton>
            <LinkButton
              variant={ButtonVariantOption.Contained}
              wrapperSize={SizingWrapperStyle.INHERIT}
              disabled={!row.realEstateOwnerAnalyzeRequestId}
              href={`/${
                row.realEstateOwnerAnalyzeRequestId
                  ? "ownerinfo/detail/" +
                    String(row.realEstateOwnerAnalyzeRequestId)
                  : "ownerinfo/upload"
              }/`}
            >
              所有者事項解析結果
            </LinkButton>
            <LinkButton
              variant={ButtonVariantOption.Contained}
              wrapperSize={SizingWrapperStyle.INHERIT}
              disabled={!row.pictureRequestId}
              href={`/${
                row.pictureRequestId
                  ? "acquirebookstatus/" + String(row.pictureRequestId)
                  : "acquirebook"
              }/`}
            >
              取得済み登記
            </LinkButton>
          </Stack>
        );
      },
    },
  ];
};

/**
 * _FeedGrid
 * reload関数を親コンポーネントに外部公開するために、関数名にはアンダースコアをつけている
 * @param _props
 * @param ref
 */
const _FeedGrid: ForwardRefRenderFunction<
  { reload: () => void },
  MonitoringFeedGridProps
> = (_props, ref) => {
  let rows: MonitoringSummaryRow[] = [];
  const { apiClient } = useApiClient();
  const colDef = makeGridColDef();

  const {
    data: apiResponse,
    isLoading,
    mutate,
    error,
  } = GetMonitoringListAPI(apiClient, _props.filterCondition);

  useEffect(() => {
    _props.setApiResponseData(apiResponse);
  }, [apiResponse]);

  if (
    isAxiosError<GetMonitoringSummariesAPIResponse>(error) &&
    (error.response?.status === HttpStatusCode.BadRequest ||
      error.response?.status === HttpStatusCode.NotFound)
  ) {
    toast.error("指定のデータが存在しません。");
  }
  if (
    isAxiosError<GetMonitoringSummariesAPIResponse>(error) &&
    (error.response?.status ?? HttpStatusCode.InternalServerError) >=
      HttpStatusCode.InternalServerError
  ) {
    toast.error("データの取得に失敗しました。再度お試しください。");
  }

  const getDisplayMonthlyUpdatable = (
    monthlyUpdatable: boolean | undefined
  ): DisplayMonthlyUpdatable => {
    return monthlyUpdatable ? "する" : "しない";
  };

  const transferResponseToRows = (
    apiResponse: GetMonitoringSummariesAPIResponse
  ): MonitoringSummaryRow[] => {
    const apiResponseData: MonitoringSummaryRow[] = [];

    for (const summary of apiResponse.summaries) {
      apiResponseData.push({
        id: summary.id,
        count: summary.count,
        name: summary.name,
        receptionReasons: getDisplayReceptionReason(summary.receptionReasons),
        monthlyUpdatable: getDisplayMonthlyUpdatable(summary.monthlyUpdatable),
        legalAffairsBureauRequestDateStart:
          summary.legalAffairsBureauRequestDateStart,
        legalAffairsBureauRequestDateEnd:
          summary.legalAffairsBureauRequestDateEnd,
        realEstateOwnerAnalyzeRequestId:
          summary.realEstateOwnerAnalyzeRequestId,
        pictureRequestId: summary.pictureRequestId,
        createdAt: format(parseISO(summary.createdAt), "yyyy/MM/dd"),
      });
    }

    return apiResponseData;
  };

  // refを使って外部公開する関数を定義する
  useImperativeHandle(
    ref,
    () => {
      return {
        // mutate()を使って再読み込みを行う
        async reload() {
          await mutate().then((apiResponse) => {
            if (apiResponse) {
              rows = transferResponseToRows(apiResponse);
              _props.setApiResponseData(apiResponse);
            }
          });
        },
      };
    },
    []
  );

  if (isLoading || apiResponse === undefined) {
    return (
      <Stack alignItems="center">
        <CircularProgress sx={{ my: "20px" }} />
      </Stack>
    );
  }

  rows = transferResponseToRows(apiResponse);

  const selectionModelChange = (
    selectionModel: GridSelectionModel,
    _details: GridCallbackDetails
  ): void => {
    _props.setSelectedRowIds(selectionModel);
  };

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      checkboxSelection
      rows={rows}
      columns={colDef}
      onSelectionModelChange={selectionModelChange}
      selectionModel={_props.selectedRowIds}
      sortModel={[
        {
          field: "id",
          sort: "asc",
        },
      ]}
    />
  );
};

/**
 * FeedGrid
 * forwardRefを使ってreload()を外部から呼び出せるようにしている
 */
export const MonitoringFeedGrid = forwardRef<
  { reload: () => void },
  MonitoringFeedGridProps
>(_FeedGrid);
