import { useApiClient } from "../../../hooks/useApiClient";
import { useNavigate } from "react-router-dom";
import {
  type AcquireBookPictures,
  type AcquireBookZenbujikou,
  type PictureResponse,
  type PictureRequest,
} from "../../../types/acquirebook";
import { toast } from "react-toastify";
import { HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";

export interface AcquireBookForm {
  handleSubmit: (
    prefecture: string,
    city: string,
    locationWithoutCity: string,
    locationFull: string,
    chibanKaokuNumber: string,
    pastDays: number | "",
    realEstateType: string,
    requestOptions: Record<string, Record<string, boolean>>,
    withOwnerAnalyze: boolean,
    identificationName: string,
    cancelLoading: () => void
  ) => void;
}

// picture-requestに送信するデータに変換する
const makeSinglePictureRequest = (
  prefecture: string,
  city: string,
  locationWithoutCity: string,
  locationFull: string,
  chibanKaokuNumber: string,
  pastDays: number | "",
  realEstateType: string,
  requestOptions: Record<string, Record<string, boolean>> // チェックボックスのチェック状態
): PictureRequest => {
  const pictures: AcquireBookPictures[] = [];
  let zenbujikoOptions: AcquireBookZenbujikou[] = [];

  // チェックボックスのチェック状態に応じてpictures, zenbujikoOptionsの値を埋める
  for (const key in requestOptions) {
    switch (key) {
      case "syoyusya":
        if (requestOptions[key].checked) {
          pictures.push("SHOYUSHAJIKO");
        }
        break;
      case "zennbu":
        if (requestOptions[key].checked) {
          pictures.push("ZENBUJIKO");
        }
        break;
      case "sintaku":
        if (requestOptions[key].checked) {
          zenbujikoOptions.push("SHINTAKUMOKUROKU");
        }
        break;
      case "kyodo":
        if (requestOptions[key].checked) {
          zenbujikoOptions.push("KYODOTANPOMOKUROKU");
        }
        break;
      case "genzai":
        break;
      case "tiseki":
        if (requestOptions[key].checked) {
          pictures.push("CHISEKISOKURYOZU");
        }
        break;
      case "densi":
        if (requestOptions[key].checked) {
          pictures.push("DENSHIKOZU");
        }
        break;
      case "tieki":
        if (requestOptions[key].checked) {
          pictures.push("CHIEKIKENZUMEN");
        }
        break;
      case "tatemono":
        if (requestOptions[key].checked) {
          pictures.push("TATEMONOZUMEN");
        }
        break;
    }
  }

  // 現在事項がチェックされている場合は信託目録と共同担保目録を現在に切り替える
  if (requestOptions.genzai.checked) {
    zenbujikoOptions = zenbujikoOptions.map((value): AcquireBookZenbujikou => {
      if (value === "KYODOTANPOMOKUROKU") {
        return "KYODOTANPOMOKUROKU_GENZAI";
      } else {
        return "SHINTAKUMOKUROKU_GENZAI";
      }
    });
  }

  // 送信するデータを生成する
  const resultWithCity = {
    real_estate_type: realEstateType,
    pref_code: prefecture,
    city_code: city,
    location_without_city: locationWithoutCity,
    chiban_kaoku_number: chibanKaokuNumber,
    past_days: pastDays,
    pictures,
    zenbujiko_options: zenbujikoOptions,
  };

  const resultWithoutCity = {
    real_estate_type: realEstateType,
    pref_code: prefecture,
    location_full: locationFull,
    chiban_kaoku_number: chibanKaokuNumber,
    past_days: pastDays,
    pictures,
    zenbujiko_options: zenbujikoOptions,
  };

  // city空欄かつlocationFullに値がある場合はcity_code, location_without_cityプロパティ無しでリクエストする
  if (city === "" && locationFull !== "") {
    return resultWithoutCity;
  } else {
    return resultWithCity;
  }
};

export const useAcquireBookForm = (): AcquireBookForm => {
  const { apiClient } = useApiClient();
  const navigate = useNavigate();

  const handleSubmit = (
    prefecture: string,
    city: string,
    locationWithoutCity: string,
    locationFull: string,
    chibanKaokuNumber: string,
    pastDays: number | "",
    realEstateType: string,
    requestOptions: Record<string, Record<string, boolean>>,
    withOwnerAnalyze: boolean,
    identificationName: string,
    cancelLoading: () => void
  ): void => {
    // データを送信できるオブジェクトに変換する
    const items = [
      makeSinglePictureRequest(
        prefecture,
        city,
        locationWithoutCity,
        locationFull,
        chibanKaokuNumber,
        pastDays,
        realEstateType,
        requestOptions
      ),
    ];
    const req = {
      identificationName,
      withOwnerAnalyze,
      items,
    };

    // 送信する
    apiClient
      .post<PictureResponse>("/picture-request/", req)
      .then((_res) => {
        navigate("/acquirebooklist");
      })
      .catch((e) => {
        if (isAxiosError(e) && e.response && e.response.data) {
          /* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */
          if (e.response.data.nonFieldErrors?.length) {
            // エラー文字列が配列なので複数のエラー文字列が返されても表示できるようにする
            const errorList: React.ReactNode[] =
              e.response.data.nonFieldErrors.map(
                (value: string): React.ReactNode => {
                  return (
                    <>
                      {value}
                      <br />
                    </>
                  );
                }
              );
            toast.error(<>{errorList}</>);
          } else {
            toast.error(JSON.stringify(e.response.data));
          }
          /* eslint-enable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */
        }
        if (
          isAxiosError(e) &&
          (e.response?.status ?? HttpStatusCode.InternalServerError) >=
            HttpStatusCode.InternalServerError
        ) {
          Sentry.captureException(e);
        }
        console.error(e);
        cancelLoading();
      });
  };

  return {
    handleSubmit,
  };
};
