import { type AxiosError } from "axios";
import * as Sentry from "@sentry/react";

export const SendToTrackingService = (error: AxiosError): void => {
  const errorResponse = error.response;
  const endpoint = errorResponse?.config.url ?? "";
  const status = errorResponse?.status;
  const method = errorResponse?.config.method ?? "";

  try {
    const contexts = {
      response: {
        ...errorResponse,
        headers: errorResponse?.headers
          ? Object.fromEntries(
              Object.entries(errorResponse.headers).map(([key, value]) => [
                key,
                String(value),
              ])
            )
          : undefined,
      },
    };

    Sentry.withScope((scope) => {
      scope.setFingerprint(["{{ default }}", endpoint, String(status), method]);
      Sentry.captureException(error, { contexts });
    });
  } catch (handleError) {
    Sentry.captureException(handleError);
    Sentry.captureException(error);
  }
};
