import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReceptionReasonOptions } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionReasonOptions";
import {
  getSearchMaxDate,
  getSearchMinDate,
  getStartAndEndDate,
} from "@/features/realEstateReceptionBookFeed/utils/dateTime";
import { readString } from "react-papaparse";
import { type ParseResult } from "papaparse";
import { useFeatureFlags } from "@/configs/featureFlag";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useApiClient } from "@/hooks/useApiClient";
import { HttpStatusCode, isAxiosError } from "axios";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { Box, Container, Grid, Link, Typography, Stack } from "@mui/material";
import { PageTitle } from "@/components/Title";
import { FileUploadButton } from "@/features/realEstateReceptionBookFeed/components/FileUploadButton";
import { CMultiSelectWithSpecialOptions } from "@/features/monitoring/components/CMultiSelectWithSpecialOptions";
import { CDateRangePicker } from "@/components/DatePicker/CDateRangePicker";
import { Button, ButtonVariantOption, SubmitButton } from "@/components/Button";
import { KeyboardArrowLeft } from "@mui/icons-material";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import { CTextFieldIdentifyName } from "@/components/TextField/CTextFieldIdentifyName";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { type PostMonitoringFileAPIResponse } from "../types";
import { PostMonitoringFromFileAPI } from "../api";
import { CustomModal } from "@/components/Modal";
import { CCheckbox } from "@/components/Checkbox/CCheckbox";
import { SendToTrackingService } from "@/api/handlers/errors";

const ONCE_UPDATE_MAX_CSV_ROWS = 1_000;
const MONTHLY_UPDATE_MAX_CSV_ROWS = 5_000;

/**
 * CSVファイルの読み込み処理
 * @param file
 * @return CSVパース結果オブジェクトが返される
 */
const readCSV = async (file: File): Promise<ParseResult<unknown>> =>
  await new Promise<ParseResult<string>>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("error", () => {
      reject(reader.error);
    });
    reader.addEventListener("load", () => {
      const { result } = reader;
      if (typeof result !== "string") {
        reject(reader.error);
      }
      readString<string>(result as string, {
        worker: true,
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results);
        },
        error(error: Error) {
          reject(error);
        },
      });
    });
    reader.readAsText(file);
  });

/**
 * 受けとったCSVファイルの要素の件数が指定された件数を超えているか判定する
 * @param content papaparseのCSVパース結果オブジェクト
 * @param count 最大値
 * @return 最大値を超えているならtrue、それ以外はfalse
 */
const isLineCountExceeded = (
  content: ParseResult<unknown>,
  count: number
): boolean => {
  return content.data.length > count;
};

/**
 * CSVファイルから重複行を取り除く
 * 重複取り除きルールは「不動産種別、都道府県、所在、地番または家屋番号」が全て一致している行
 * 重複があった場合、最初の要素のみ残し、後の要素は削除する
 * @param content papaparseのCSVパース結果オブジェクト
 * @return contentから重複行を取り除いたオブジェクト
 */
const distinctCSV = (content: ParseResult<unknown>): ParseResult<unknown> => {
  const newData: Array<Record<string, string>> = [];

  const getKey = (input: Record<string, string>): string => {
    return (
      input["不動産種別"] +
      input["都道府県"] +
      input["所在"] +
      input["地番または家屋番号"]
    );
  };

  content.data.forEach((value) => {
    const key = getKey(value as Record<string, string>);
    if (newData.some((value) => getKey(value) === key)) {
      return;
    }
    newData.push(value as Record<string, string>);
  });
  content.data = newData;
  return content;
};

export const MonitoringUpload: React.FC = () => {
  const { realEstateRegistrationMonitoring } = useFeatureFlags();

  // アップロード対象のファイルオブジェクト
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [columnCount, setColumnCount] = useState<number>(0);

  // MAX_COLUMN_COUNT件越えエラーメッセージ関連のState
  const [showFileErrorMessage, setShowFileErrorMessage] =
    useState<boolean>(false);

  // 料金がかかる旨を通知するためのState
  const [fileNoticeMessage, setFileNoticeMessage] = useState<string>("");

  // 実行ボタン無効化用State
  const [executeButtonDisabled, setExecuteButtonDisabled] =
    useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  // 確認ボタン押下後のキャンセルボタン無効化State
  const [isPushedConfirmButton, setIsPushedConfirmButton] =
    useState<boolean>(false);

  // 登記原因のState
  const [receptionReasons, setReceptionReasons] = useState<string[]>([]);

  // 登記要因セレクト項目
  const { receptionReasonOptions } = useReceptionReasonOptions({ short: true });

  // ルーティング用
  const navigate = useNavigate();

  // 法務局受付日 開始、終了の初期値
  const [startDate, endDate] = getStartAndEndDate();
  const minDate = getSearchMinDate();
  const maxDate = getSearchMaxDate();

  // React-Hook-Form用スキーマ定義
  const UploadFormDataSchema = z.object({
    identifyName: z
      .string()
      .trim()
      .min(1, { message: "識別名を入力してください" })
      .max(256, { message: "256文字まで入力可能です" }),
    legalAffairsBureauRequestDateRange: z.tuple([
      z.date().min(minDate).max(maxDate),
      z.date().min(minDate).max(maxDate),
    ]),
    notMonthlyUpdatable: z.boolean(),
    receptionReasons: z
      .array(z.string())
      .min(1, "１つ以上登記原因を選択してください"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type UploadFormData = z.infer<typeof UploadFormDataSchema>;

  const reactHookForm = useForm<UploadFormData>({
    mode: "all",
    defaultValues: {
      identifyName: "",
      legalAffairsBureauRequestDateRange: [startDate, endDate],
      notMonthlyUpdatable: false,
      receptionReasons: [],
    },
    resolver: zodResolver(UploadFormDataSchema),
  });

  const [watchNotMonthlyUpdatable, watchLegalAffairsBureauRequestDateRange] =
    reactHookForm.watch([
      "notMonthlyUpdatable",
      "legalAffairsBureauRequestDateRange",
    ]);

  const { apiClient } = useApiClient();

  useEffect(() => {
    const legalAffairsBureauRequestDateStart = reactHookForm.getValues(
      "legalAffairsBureauRequestDateRange"
    )[0];
    const legalAffairsBureauRequestDateEnd = reactHookForm.getValues(
      "legalAffairsBureauRequestDateRange"
    )[1];

    if (
      !legalAffairsBureauRequestDateStart ||
      !legalAffairsBureauRequestDateEnd ||
      Number.isNaN(legalAffairsBureauRequestDateStart.getTime()) ||
      Number.isNaN(legalAffairsBureauRequestDateEnd.getTime())
    ) {
      setExecuteButtonDisabled(true);
      return;
    }

    const termMonth =
      (legalAffairsBureauRequestDateEnd.getFullYear() -
        legalAffairsBureauRequestDateStart.getFullYear()) *
        12 +
      (legalAffairsBureauRequestDateEnd.getMonth() -
        legalAffairsBureauRequestDateStart.getMonth()) +
      1;
    setFileNoticeMessage(
      columnCount
        ? reactHookForm.getValues("notMonthlyUpdatable")
          ? `注意: 実行すると${(columnCount * termMonth * 10).toLocaleString(
              "ja-JP"
            )}円(10円×${columnCount.toLocaleString(
              "ja-JP"
            )}件×${termMonth}ヶ月)の費用が発生します。`
          : `注意: 実行すると毎月最大${(columnCount * 10).toLocaleString(
              "ja-JP"
            )}円(10円×${columnCount.toLocaleString(
              "ja-JP"
            )}件)の費用が発生します。`
        : ""
    );

    const isColumnCountError = reactHookForm.getValues("notMonthlyUpdatable")
      ? columnCount > ONCE_UPDATE_MAX_CSV_ROWS
      : columnCount > MONTHLY_UPDATE_MAX_CSV_ROWS;

    setShowFileErrorMessage(isColumnCountError);
    setExecuteButtonDisabled(isColumnCountError);
  }, [
    columnCount,
    watchNotMonthlyUpdatable,
    watchLegalAffairsBureauRequestDateRange,
  ]);

  // モニタリング機能無効の場合は何も表示しない
  if (!realEstateRegistrationMonitoring) return null;

  /**
   * ファイル選択時の処理
   * - エラーメッセージ削除
   * - CSVファイルの読み込み
   * - CSVファイルMAX_COLUMN_COUNT件制限の確認
   * - CSVファイル重複項目の削除
   * - エラーの場合はメッセージ表示
   * - 成功の場合は実行ボタンの有効化
   * @param event
   * @return void
   */
  const handleFileSelected = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setShowFileErrorMessage(false);
    setFileNoticeMessage("");
    setExecuteButtonDisabled(true);
    setFileToUpload(null);

    // ファイルが選択されているときのみ確認画面へボタンを有効にする
    const files = event.target.files ?? [];
    if (files.length > 0) {
      const csv = await readCSV(files[0]);
      setColumnCount(csv.data.length);

      if (reactHookForm.getValues("notMonthlyUpdatable")) {
        if (isLineCountExceeded(csv, ONCE_UPDATE_MAX_CSV_ROWS)) {
          setShowFileErrorMessage(true);
          return;
        }
      } else {
        if (isLineCountExceeded(csv, MONTHLY_UPDATE_MAX_CSV_ROWS)) {
          setShowFileErrorMessage(true);
          return;
        }
      }

      const resultCsv = distinctCSV(csv);
      setColumnCount(resultCsv.data.length);
      setExecuteButtonDisabled(false);
      setFileToUpload(files[0]);
    }
  };

  // CSVアップロードの確認ボタンが押された時の処理
  const handlePushedConfirmButton = (): void => {
    setIsOpen(true);
  };

  // 確認モーダルで「はい」を選択した時の処理
  const handleCsvUploadButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ): void => {
    if (!fileToUpload || !reactHookForm.formState.isValid) {
      return;
    }

    // NOTE: 月初日を取得するために、月を1進めて1日目を指定する
    const legalAffairsBureauRequestDateStart = new Date();
    legalAffairsBureauRequestDateStart.setFullYear(
      reactHookForm
        .getValues("legalAffairsBureauRequestDateRange")[0]
        .getFullYear(),
      reactHookForm
        .getValues("legalAffairsBureauRequestDateRange")[0]
        .getMonth(),
      1
    );

    // NOTE: 月末日を取得するために、月を1進めて0日目を指定する
    const legalAffairsBureauRequestDateEnd = new Date();
    legalAffairsBureauRequestDateEnd.setFullYear(
      reactHookForm
        .getValues("legalAffairsBureauRequestDateRange")[1]
        .getFullYear(),
      reactHookForm
        .getValues("legalAffairsBureauRequestDateRange")[1]
        .getMonth() + 1,
      0
    );

    setIsPushedConfirmButton(true);
    PostMonitoringFromFileAPI(apiClient, {
      file: fileToUpload,
      name: reactHookForm.getValues("identifyName"),
      receptionReasons: reactHookForm.getValues("receptionReasons"),
      monthlyUpdatable: !reactHookForm.getValues("notMonthlyUpdatable"),
      legalAffairsBureauRequestDateStart,
      legalAffairsBureauRequestDateEnd,
    })
      .then((response) => {
        navigate(`/monitoring/${response.id ?? ""}`);
      })
      .catch((error) => {
        if (
          isAxiosError<PostMonitoringFileAPIResponse>(error) &&
          error.response?.status === HttpStatusCode.BadRequest
        ) {
          toast.warning(
            <>
              CSVファイルのアップロードに失敗しました。
              <br />
              ファイル内容をご確認ください。
            </>
          );
          return;
        }
        if (
          isAxiosError<PostMonitoringFileAPIResponse>(error) &&
          (error.response?.status ?? HttpStatusCode.InternalServerError) >=
            HttpStatusCode.InternalServerError
        ) {
          SendToTrackingService(error);
          toast.error("CSVファイルのアップロードに失敗しました");
        }
      })
      .finally(() => {
        cancelLoading();
        setIsPushedConfirmButton(false);
      });
  };

  return (
    <PagePaper>
      <form>
        {/* ページタイトル */}
        <Box sx={{ display: "flex", mb: 0 }}>
          <SavedSearchIcon sx={{ mr: 1 }} fontSize="large" />
          <PageTitle>不動産データアップロード</PageTitle>
        </Box>
        <Stack spacing={1} sx={{ ml: 5, mb: 3, width: "fit-content" }}>
          <Link href={"/assets/sample_R.E.DATAモニタリングサンプル.csv"}>
            見本CSVをダウンロード
          </Link>
          <Link href={"/assets/sample_R.E.DATAモニタリングサンプル.xlsx"}>
            見本Excelをダウンロード
          </Link>
        </Stack>
        <SectionPaper>
          <Box sx={{ m: 2 }}>
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "2px dashed",
                borderRadius: "2px",
                borderColor: "black",
                mb: 2,
              }}
            >
              <Grid container spacing={2} sx={{ my: 5 }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography sx={{ lineHeight: 2 }}>
                    アップロードボタンを押してファイルを選択してください。（CSV形式）
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FileUploadButton
                      variant={ButtonVariantOption.Contained}
                      onChange={(event) => {
                        (async (): Promise<void> => {
                          await handleFileSelected(event);
                        })();
                      }}
                      accept={".csv"}
                      label={"アップロード"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign={"center"}>
                    {fileToUpload ? `ファイル名:${fileToUpload?.name}` : ""}
                  </Typography>
                </Grid>
                {showFileErrorMessage ? (
                  <Grid item xs={12}>
                    <Typography textAlign={"center"} color={"red"}>
                      {`CSVファイルの項目数は${(watchNotMonthlyUpdatable
                        ? ONCE_UPDATE_MAX_CSV_ROWS
                        : MONTHLY_UPDATE_MAX_CSV_ROWS
                      ).toLocaleString(
                        "ja-JP"
                      )}以下にしてください。現在の項目数: ${columnCount.toLocaleString(
                        "ja-JP"
                      )}件`}
                    </Typography>
                  </Grid>
                ) : null}
                {fileNoticeMessage !== "" && !showFileErrorMessage ? (
                  <Grid item xs={12}>
                    <Typography textAlign={"center"} color={"red"}>
                      {fileNoticeMessage}
                      <br />
                      重複行を考慮しない概算金額です。重複排除はアップロード後に行われます。
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Container>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              direction={{ xs: "column", sm: "row" }}
            >
              <Grid item xs={12} sm={7}>
                <Grid
                  container
                  justifyContent={"center"}
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Grid item pr={1}>
                    <Typography py={2}>識別名</Typography>
                    <CTextFieldIdentifyName
                      name={"identifyName"}
                      control={reactHookForm.control}
                      size={SizingWrapperStyle.MEDIUM}
                    />
                  </Grid>
                  <Grid>
                    <Typography py={2}>
                      モニタリング対象にする登記原因
                    </Typography>
                    <CMultiSelectWithSpecialOptions<UploadFormData>
                      name={"receptionReasons"}
                      control={reactHookForm.control}
                      label="登記原因"
                      options={receptionReasonOptions}
                      value={receptionReasons}
                      onChange={setReceptionReasons}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box
                  sx={{
                    display: "flex",
                    rowGap: 2,
                    py: 2,
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <CCheckbox<UploadFormData>
                    name={"notMonthlyUpdatable"}
                    control={reactHookForm.control}
                    label={"月次でモニタリングしない"}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    rowGap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <CDateRangePicker<UploadFormData>
                    name={"legalAffairsBureauRequestDateRange"}
                    control={reactHookForm.control}
                    startDateLabel="法務局法務局受付日 開始"
                    endDateLabel="法務局受付日 終了"
                    minDate={minDate}
                    maxDate={maxDate}
                    defaultCalendarMonth={startDate}
                    disabled={!watchNotMonthlyUpdatable}
                    views={["year", "month"]}
                    inputFormat={"yyyy/MM"}
                    mask={"____/__"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    label={"モニタリングする"}
                    disabled={
                      !reactHookForm.formState.isValid || executeButtonDisabled
                    }
                    onClick={handlePushedConfirmButton}
                    variant={ButtonVariantOption.Contained}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link href={"/monitoring"} sx={{ display: "inline-block" }}>
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>不動産登記モニタリング画面に戻る</Typography>
            </Box>
          </Link>
        </Box>
      </form>
      {/* 課金確認モーダル */}
      <CustomModal
        isOpen={isOpen}
        handleClose={(
          event: Record<string, unknown>,
          reason: "backdropClick" | "escapeKeyDown"
        ): void => {
          if (
            reason &&
            (reason === "backdropClick" || reason === "escapeKeyDown")
          ) {
            return;
          }
          setIsOpen(false);
        }}
        ariaLabelledby="confirm-modal"
        ariaDescribedby="confirm-modal-description"
      >
        <PageTitle>確認</PageTitle>
        <Box>
          <Typography>課金が発生します。よろしいですか？</Typography>

          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <SubmitButton
              label={"はい"}
              timeout={120_000} // ms
              onClick={handleCsvUploadButtonClick}
              variant={ButtonVariantOption.Contained}
            />
            <Button
              label="いいえ"
              disabled={isPushedConfirmButton}
              onClick={() => {
                setIsOpen(false);
              }}
              variant={ButtonVariantOption.Outlined}
            />
          </Box>
        </Box>
      </CustomModal>
    </PagePaper>
  );
};
