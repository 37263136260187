import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  type SxProps,
  type Theme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { type NewsSummary, NewsSummaryAPI } from "@/features/mypage/api";
import { News } from "@/features/mypage/components/News";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export interface NewsContainerProps {
  title: string;
  year: number;
}

export const NewsSummaryContainer: React.FC<NewsContainerProps> = (props) => {
  const newsSummaries: NewsSummary[] = NewsSummaryAPI(props.year).news;
  const accordionSx: SxProps<Theme> = {
    mb: 1,
    bgcolor: "#1976D29A",
    color: "#FFFFFF",
    padding: "0 1em 1em 1em",
    borderRadius: "0 0 10px 10px",
    verticalAlign: "-1px",
  };
  const accordionDetailSx: SxProps<Theme> = {
    height: "12.5vh",
    overflowY: "auto",
    bgcolor: "#FFFFFF",
  };

  const theme = createTheme();
  theme.typography.h4 = {
    fontSize: "24px",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  };
  theme.typography.h5 = {
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
  };

  return (
    <>
      <Accordion defaultExpanded sx={accordionSx}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ThemeProvider theme={theme}>
            <Typography variant="h4">{props.title}</Typography>
          </ThemeProvider>
        </AccordionSummary>
        <AccordionDetails sx={accordionDetailSx}>
          {(newsSummaries?.length ?? 0) === 0 ? (
            <ThemeProvider theme={theme}>
              <Typography variant="h5">ニュースはありません。</Typography>
            </ThemeProvider>
          ) : (
            newsSummaries.map((news) => (
              <News
                key={news.id}
                id={news.id}
                publishedAt={news.publishedAt}
                title={news.title}
                category={news.category}
                pinned={news.pinned}
              />
            ))
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
