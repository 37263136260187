import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import {
  Button,
  ButtonColorOption,
  ButtonVariantOption,
} from "@/components/Button";
import type { Layer } from "leaflet";

interface IPopupContent {
  shapeArea: string;
  layer: Layer;
  searchSelectedArea: (layer: Layer) => void;
  drawnItemsRef: React.MutableRefObject<L.FeatureGroup<any>>;
}

/**
 * 地図上に表示する面積ポップアップを表示するReactコンポーネント
 * @param props shapeArea 面積、無加工でそのまま表示されるので単位を表示したい場合は単位込みの文字列を渡す
 * @param props layer 選択している範囲を表すレイヤー
 * @param props handleDrawCreatedReal 検索処理を実行する関数
 * @param props drawnItemsRef 範囲を削除する時に使用するrefs
 */
export const AreaSelectPopupContent: React.FC<IPopupContent> = (props) => {
  const { shapeArea, layer, searchSelectedArea, drawnItemsRef }: IPopupContent =
    props;

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl>
        <FormLabel>
          <ArchitectureIcon fontSize={"inherit"} />
          面積
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"1.2rem"}
          variant={"body1"}
          component={"span"}
        >
          {shapeArea}
        </Typography>
      </FormControl>
      <Divider />
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              label={"この範囲で検索する"}
              onClick={() => {
                searchSelectedArea(layer);
              }}
              variant={ButtonVariantOption.Contained}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              label={"この範囲を削除する"}
              onClick={() => {
                drawnItemsRef.current.removeLayer(layer);
              }}
              color={ButtonColorOption.Error}
              variant={ButtonVariantOption.Contained}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
