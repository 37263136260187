import { HttpStatusCode, type AxiosInstance, isAxiosError } from "axios";
import type { EmailListDataGridRow } from "../types";
import { format, isValid } from "date-fns";
import { SendToTrackingService } from "@/api/handlers/errors";

/**
 * メール一覧を取得する。
 * @param {AxiosInstance} apiClient
 * @param {string} status
 * @param {string} keyword
 * @param {Date | null} sendDate
 * @returns {Promise<EmailListDataGridRow[]>}
 */
export const GetMailListAPI = async (
  apiClient: AxiosInstance,
  status: string,
  keyword: string,
  sendDate: Date | null
): Promise<EmailListDataGridRow[]> => {
  const date: string =
    sendDate != null && isValid(sendDate) ? format(sendDate, "yyyy-MM-dd") : "";
  const params = new URLSearchParams({
    status,
    keyword,
    send_date: date,
  });
  const response = await apiClient
    .get<EmailListDataGridRow[]>("/mail/?" + params.toString())
    .catch((error): void => {
      if (
        isAxiosError(error) &&
        (error.response?.status ?? HttpStatusCode.InternalServerError) >=
          HttpStatusCode.InternalServerError
      ) {
        SendToTrackingService(error);
      }
      return undefined;
    });
  if (response) {
    return response.status === HttpStatusCode.Ok ? response.data : [];
  }
  return [];
};
