import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import { AcquireMultipleBooks } from "./AcquireMultipleBooks";
import { AcquireMultipleBooksUpload } from "./AcquireMultipleBooksUpload";

export const AcquireMultipleBooksRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<AcquireMultipleBooks />} />
      <Route path="upload" element={<AcquireMultipleBooksUpload />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};
