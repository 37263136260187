import { useMemo } from "react";
import * as React from "react";
import {
  Box,
  FormControl,
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  type SelectChangeEvent,
  IconButton,
  Checkbox as MuiCheckbox,
  ListItemText,
  Chip,
  FormHelperText,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { type ISelectObject } from "@/types/select";
import { SizingWrapper } from "@/components/Wrapper";
import { type IProps as MultiSelectIProps } from "@/components/Select/MultiSelect";

export interface IProps extends MultiSelectIProps {
  label: string;
  value: string[];
  onChange: (value: string[]) => void;
  options: ISelectObject[];
  isClearable?: boolean;
  maxSelect?: number;
  error?: boolean;
  formHelperText?: string;
}

// 所有権移転関連のIDリスト。正式名称に所有権移転と記載があるもの
export const OWNERSHIP_TRANSFER_IDS = [
  "1", // 所有権移転相続・法人合併
  "2", // 所有権移転・相続
  "3", // 所有権移転・一般承継
  "4", // 所有権移転売買
  "5", // 所有権移転遺贈・贈与
  "8", // 所有権移転その他の原因
];

const MonitoringListMultiSelect: React.FC<IProps> = (props) => {
  const {
    label,
    options,
    value,
    onChange,
    isClearable = true,
    size,
    maxSelect,
    error,
    formHelperText,
  } = props;

  const additionalMenuItemsForMonitoring: ISelectObject[] = [
    { value: "all", label: "全て解除" },
    { value: "ownership", label: "所有権移転関連" },
  ];

  const extendedOptions = [...additionalMenuItemsForMonitoring, ...options];

  const displayValueList = useMemo(
    () =>
      extendedOptions
        .filter((item) => value.includes(item.value))
        .map((item) => item.label),
    [value, extendedOptions]
  );

  const handleCustomChange = (event: SelectChangeEvent<string[]>): void => {
    const selectedValues = event.target.value as string[];

    if (selectedValues.includes("all")) {
      onChange([]);
      return;
    }

    if (selectedValues.includes("ownership")) {
      const isAllSelected = OWNERSHIP_TRANSFER_IDS.every((id) =>
        value.includes(id)
      );

      if (isAllSelected) {
        onChange(value.filter((v) => !OWNERSHIP_TRANSFER_IDS.includes(v)));
      } else {
        const newValue = [...value];
        OWNERSHIP_TRANSFER_IDS.forEach((id) => {
          if (!newValue.includes(id)) {
            newValue.push(id);
          }
        });
        onChange(newValue);
      }
      return;
    }

    const filteredValues = selectedValues.filter(
      (val) => val !== "all" && val !== "ownership"
    );
    onChange(filteredValues);
  };

  const handleClearClick = (): void => {
    onChange([]);
  };

  const getDisabled = (option: ISelectObject): boolean => {
    if (maxSelect == null) {
      return false;
    }
    return (
      value.length >= maxSelect && !value.some((item) => item === option.value)
    );
  };

  return (
    <SizingWrapper size={size}>
      <FormControl fullWidth>
        <InputLabel id="multi-select-label">{label}</InputLabel>
        <MuiSelect
          id="multi-select-id"
          labelId="multi-select-label"
          label={label}
          onChange={handleCustomChange}
          multiple
          value={value}
          defaultValue={[]}
          error={error}
          sx={{
            "#multi-select-id": {
              pr: 0,
            },
          }}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {displayValueList.map((label) => (
                <Chip key={label} label={label} sx={{ cursor: "pointer" }} />
              ))}
            </Box>
          )}
          endAdornment={
            isClearable ? (
              <IconButton
                sx={{
                  display:
                    displayValueList.length === 0 ? "none" : "inline-flex",
                  marginRight: "10px",
                }}
                onClick={handleClearClick}
              >
                <ClearIcon />
              </IconButton>
            ) : (
              <></>
            )
          }
        >
          {extendedOptions.flatMap((option, index) => [
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={getDisabled(option)}
              sx={{
                marginTop: ["all", "ownership"].includes(option.value)
                  ? "8px"
                  : "0px",
                marginBottom: ["all", "ownership"].includes(option.value)
                  ? "8px"
                  : "0px",
              }}
            >
              {!["all", "ownership"].includes(option.value) && (
                <MuiCheckbox
                  checked={value.some((item) => item === option.value)}
                />
              )}
              <ListItemText primary={option.label} />
            </MenuItem>,
            option.value === "ownership" && (
              <Divider key={`divider-${index}`} />
            ),
          ])}
        </MuiSelect>
        {formHelperText ? (
          <FormHelperText error>{formHelperText}</FormHelperText>
        ) : null}
      </FormControl>
    </SizingWrapper>
  );
};

export { MonitoringListMultiSelect, type IProps as MultiSelectIProps };
