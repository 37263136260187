import { styled } from "@mui/material/styles";
import { AcquireBookDataGrid } from "../../../components/DataGrid";
// import { useGridFeed } from "../hooks/useGridFeed";
import {
  type GridCallbackDetails,
  type GridColDef,
  type GridColumnGroupingModel,
  type GridColumnHeaderParams,
  type GridEventListener,
  type GridRenderCellParams,
  type MuiEvent,
} from "@mui/x-data-grid";
import { type AcquireMultipleBookHandoffData } from "../../../types/acquirebook";
import { CheckboxForAcquireBook } from "../../../components/Checkbox";
import { NYHalfWidthNumberToFillWidthNumber } from "@/utils/utils";
import {
  type BookRequestCheckboxStatus,
  type BookRequestCheckboxStatuses,
} from "../types";
import { type ReactNode, useState } from "react";

interface IProps {
  rows: AcquireMultipleBookHandoffData[];
  checkBoxOptions: BookRequestCheckboxStatuses[];
  setCheckBoxOptions: React.Dispatch<
    React.SetStateAction<BookRequestCheckboxStatuses[]>
  >;
  autoHeight?: boolean;
  // NOTE: 2025/01/27 hotfixにて地番または家屋番号を非表示に変更
  isOpenedTitleSearch?: boolean;
}

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // タイトルのborderと重複するため削除
  & > div:nth-of-type(3) > .MuiDataGrid-footerContainer {
    border-top: 0;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

// 英語/日本語変換テーブル
const bookTypeTable: Record<string, string> = {
  LAND: "土地",
  BUILDING: "建物",
  CONDOMINIUM: "区分建物",
};

export const FeedGrid: React.FC<IProps> = (props) => {
  const {
    rows,
    checkBoxOptions,
    setCheckBoxOptions,
    autoHeight = true,
    isOpenedTitleSearch = false,
  } = props;

  // チェックボックスのセルを表示する処理
  const renderCheckboxCell = (
    id: number | string,
    type: keyof BookRequestCheckboxStatuses
  ): ReactNode => {
    const checkboxState: BookRequestCheckboxStatuses =
      checkBoxOptions[Number(id) - 1];
    const checked = checkboxState[type].checked;
    const disabled = checkboxState[type].disabled;

    return (
      <CheckboxForAcquireBook
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          const newOptions = [...checkBoxOptions];
          newOptions[Number(id) - 1][type].checked = e;
          setCheckBoxOptions(newOptions);
        }}
      />
    );
  };

  // カラム設定
  const coldef: GridColDef[] = [
    {
      field: "id",
      headerName: "No",
      width: 90,
      sortable: false,
    },
    {
      field: "bookType",
      headerName: "種別",
      width: 90,
      sortable: false,
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        // valueが英語なので日本語に置き換える
        if (value) {
          return bookTypeTable[value];
        } else {
          return "";
        }
      },
    },
    {
      field: "prefName",
      headerName: "都道府県",
      width: 90,
      sortable: false,
    },
    {
      field: "locationFull",
      headerName: "所在",
      width: 240,
      sortable: false,
      renderCell: ({ formattedValue }: GridRenderCellParams<string>) => {
        return formattedValue;
      },
    },
    {
      field: "chibanKaokuNumber",
      headerName: "地番または家屋番号",
      width: 150,
      sortable: false,
      renderCell: ({ value }: GridRenderCellParams) => {
        if (isOpenedTitleSearch) {
          return "***";
        }
        return NYHalfWidthNumberToFillWidthNumber(value as string);
      },
    },
    {
      field: "kyoutan",
      headerName: "共担",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ id }: GridRenderCellParams) => {
        return renderCheckboxCell(id, "kyoutan");
      },
    },
    {
      field: "sintaku",
      headerName: "信託",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ id }: GridRenderCellParams) => {
        return renderCheckboxCell(id, "sintaku");
      },
    },
    {
      field: "genzai",
      headerName: "現在",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ id }: GridRenderCellParams) => {
        return renderCheckboxCell(id, "genzai");
      },
    },
    {
      field: "ownerInfo",
      headerName: "所有者事項",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ id }: GridRenderCellParams) => {
        return renderCheckboxCell(id, "ownerInfo");
      },
    },
    {
      field: "electricDrawings",
      headerName: "電子公図",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ id }: GridRenderCellParams) => {
        return renderCheckboxCell(id, "electricDrawings");
      },
    },
    {
      field: "tisekiDrawings",
      headerName: "地積測量図",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ id }: GridRenderCellParams) => {
        return renderCheckboxCell(id, "tisekiDrawings");
      },
    },
    {
      field: "tiekiDrawings",
      headerName: "地役権図面",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ id }: GridRenderCellParams) => {
        return renderCheckboxCell(id, "tiekiDrawings");
      },
    },
    {
      field: "buildingDrawings",
      headerName: "建物図面",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ id }: GridRenderCellParams) => {
        return renderCheckboxCell(id, "buildingDrawings");
      },
    },
  ];

  // 全部事項のカラムを結合する設定
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "全部事項",
      description: "",
      children: [
        { field: "kyoutan" },
        { field: "sintaku" },
        { field: "genzai" },
      ],
    },
  ];

  // 列ダブルクリックで列全体のチェックボックスを反転させるためのstate
  // Map型でそれぞれの列に対してチェック状態を保存している
  // key: string 列名(coldefのfieldの値)
  // value: boolean チェック状態, 初期値はfalse
  const [columnCheckboxStates, setColumnCheckboxStates] = useState<
    Map<string, boolean>
  >(
    () => new Map<string, boolean>(coldef.map((value) => [value.field, false]))
  );

  // 列ダブルクリックでチェックボックスを全選択する
  const headerDoubleClickHandleEvent: GridEventListener<
    "columnHeaderDoubleClick"
  > = (
    params: GridColumnHeaderParams,
    _event: MuiEvent<React.MouseEvent<HTMLElement>>,
    _details: GridCallbackDetails
  ) => {
    // 列ダブルクリックで列全体のチェックボックスを反転させるためのstateを更新
    const newColumnCheckboxStates = new Map(columnCheckboxStates);
    newColumnCheckboxStates.set(
      params.field,
      !columnCheckboxStates.get(params.field)
    );
    setColumnCheckboxStates(newColumnCheckboxStates);

    const newCheckBoxOptions = checkBoxOptions.map(
      (value: BookRequestCheckboxStatuses) => {
        const newValue = { ...value };
        const requestCheckboxStatus: BookRequestCheckboxStatus =
          newValue[params.field as keyof BookRequestCheckboxStatuses];

        // チェックボックスが存在しない列をダブルクリックしていた場合は何もしない
        if (requestCheckboxStatus === undefined) return newValue;

        // 有効になっているチェックボックスをnewColumnCheckboxStatesの状態と一致させる
        if (!requestCheckboxStatus.disabled) {
          requestCheckboxStatus.checked =
            newColumnCheckboxStates.get(params.field) ?? false;
        }
        return newValue;
      }
    );
    setCheckBoxOptions(newCheckBoxOptions);
  };

  return (
    <StyledDataGrid
      autoHeight={autoHeight}
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
      columns={coldef}
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
      onColumnHeaderDoubleClick={headerDoubleClickHandleEvent}
    />
  );
};
