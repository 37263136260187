import React, { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { type StyleSpecification } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "@maplibre/maplibre-gl-leaflet";
import L, { LatLng } from "leaflet";
import "./layer.css";
import { createRoot } from "react-dom/client";
import { LandPricePopupContent } from "@/features/map/components/LandPricePopupContent";

interface Props {
  visible: boolean;
  initialZoomLevel: number;
}

const MIN_ZOOM = 15;
const MAX_ZOOM = 21;
const SOURCE_NAME = "landPrice";
const SOURCE_LAYER_NAME = "landprice";

// タイルデータが保存されているホスト名 例: https://example.com
const HOST = process.env.VITE_PUBLIC_LAND_PRICE_TILE_HOST ?? "";

// MapLibreのスタイル定義
// https://maplibre.org/maplibre-style-spec/
const MAPLIBRE_STYLE: StyleSpecification = {
  version: 8,
  name: "Land Price Vector Tile Layer",
  glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
  sources: {
    landPrice: {
      type: "vector",
      // タイルデータのURL
      tiles: [`${HOST}/{z}/{x}/{y}.pbf`],
      minzoom: MIN_ZOOM,
      maxzoom: MAX_ZOOM,
    },
  },
  layers: [
    // ラベル
    {
      id: "type_symbol",
      type: "symbol",
      source: SOURCE_NAME,
      "source-layer": SOURCE_LAYER_NAME,
      layout: {
        "text-field": [
          "string",
          ["number-format", ["get", "land_price"], { currency: "JPY" }], // number-formatを用いて「,」を挿入すると自動で￥も追加される
        ],
        "text-font": ["Open Sans Regular"],
        "text-size": 20,
        "text-ignore-placement": false, // 重なっている場合は非表示
        "text-anchor": "bottom",
        "text-radial-offset": 1,
      },
      paint: {
        "text-color": "#aa00ff",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 3,
      },
      minzoom: MIN_ZOOM,
      maxzoom: MAX_ZOOM,
      filter: ["==", ["geometry-type"], "Point"],
    },
    {
      id: "circle_layer",
      type: "circle",
      source: SOURCE_NAME,
      "source-layer": SOURCE_LAYER_NAME,
      paint: {
        "circle-color": "#4264fb",
        "circle-radius": 10,
        "circle-stroke-width": 3,
        "circle-stroke-color": "#ffffff",
      },
      minzoom: MIN_ZOOM,
      maxzoom: MAX_ZOOM,
      filter: ["==", ["geometry-type"], "Point"],
    },
  ],
};

/*
用途地域レイヤーのコンポーネント
ベクトルタイルを読み込むため、MapLibreをLeafletのレイヤーとして扱えるプラグインを使用している
*/
export const LandPriceTileLayer: React.FC<Props> = ({
  visible,
  initialZoomLevel,
}) => {
  // Leafletのマップオブジェクト
  const map = useMap();
  // レイヤー格納用
  const layerRef = useRef<L.MaplibreGL | null>(null);
  // ポップアップ格納用
  const popupRef = useRef<L.Popup | null>(null);
  // ズームレベル
  const [zoomLevel, setZoomLevel] = useState<number>(initialZoomLevel);

  // レイヤーをLeafletのマップオブジェクトに追加し、イベントの設定を行う関数
  const addLayerToMap = (vectorTileLayer: L.MaplibreGL, map: L.Map): void => {
    if (map.hasLayer(vectorTileLayer)) {
      return;
    }

    vectorTileLayer.addTo(map);
    /*
      addTo関数を利用して非表示から表示に切り替えた場合、
      地図をスクロールするまで用途地域レイヤーの内容が更新されない問題があったため、ここで強制更新する
      leaflet-maplibre-gl.jsの内部関数を直接呼ぶためTypescriptの各種エラーは回避している
      不具合チケット: https://www.notion.so/trustart/34391683363346e5ba5d4758241f1ccd?pvs=4
    */
    /* eslint-disable */
    // @ts-ignore
    vectorTileLayer._update();
    /* eslint-enable */

    // z-indexを設定しないとタイルレイヤーが見えないので必須
    vectorTileLayer.getContainer().style.zIndex = "500";

    // MapLibreのマップオブジェクト
    const maplibreMap = vectorTileLayer.getMaplibreMap();

    // これらを有効にすると表示している文字とタイルに枠が表示される
    maplibreMap._showCollisionBoxes = false;
    maplibreMap._showTileBoundaries = false;

    maplibreMap.on("click", "circle_layer", (e) => {
      e.originalEvent.stopPropagation();

      const features = e.features;
      if (features && features.length > 0) {
        const feature = features[0];

        const coordinates = new LatLng(e.lngLat.lat, e.lngLat.lng);
        const landPrice = feature.properties.land_price as number;
        const locationChiban = feature.properties.location_chiban as string;
        const year = feature.properties.year as string;
        const jukyoHyoji = feature.properties.jukyo_hyoji as string;

        // popupの内容として表示するHTMLを生成する
        const popupHTML = document.createElement("div");
        createRoot(popupHTML).render(
          <LandPricePopupContent
            coordinates={coordinates}
            landPrice={landPrice}
            locationChiban={locationChiban}
            year={year}
            jukyoHyoji={jukyoHyoji}
          />
        );

        if (popupRef.current) {
          // 存在する場合は削除
          popupRef.current.remove();
        }

        popupRef.current = L.popup({ content: popupHTML, minWidth: 300 })
          .setLatLng([coordinates.lat, coordinates.lng])
          .openOn(map);
      }
    });

    // 円描画時のドラッグで用途地域レイヤーが動かないように設定
    maplibreMap.dragPan.disable();
  };

  useEffect(() => {
    if (!map) return;

    map.on("zoomend", (e) => {
      setZoomLevel(map.getZoom());
    });

    const vectorTileLayer = L.maplibreGL({
      interactive: true,
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      // Leafletと同調しなくなるので、falseにする
      scrollZoom: false,
      style: MAPLIBRE_STYLE,
    });

    layerRef.current = vectorTileLayer;
    if (visible) {
      addLayerToMap(vectorTileLayer, map);
    }

    return () => {
      if (map && layerRef.current) {
        map.removeLayer(layerRef.current);
      }
    };
  }, [map]);

  // レイヤーの表示制御
  // ベクトルタイルはLeaflet（ラスタタイル）よりもズームレベルが1だけ小さい
  useEffect(() => {
    if (layerRef.current) {
      if (
        visible &&
        zoomLevel &&
        zoomLevel - 1 >= MIN_ZOOM &&
        zoomLevel - 1 <= MAX_ZOOM
      ) {
        addLayerToMap(layerRef.current, map);
      } else {
        map.removeLayer(layerRef.current);
      }
    }
  }, [visible, zoomLevel, map]);

  return null;
};
