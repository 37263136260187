import { useAcquireBookForm } from "../hooks/useAcquireBookForm";
import type { AcquireMultipleBookHandoffData } from "@/types/acquirebook";
import type {
  BookRequestCheckboxStatus,
  BookRequestCheckboxStatuses,
} from "@/features/acquireMultipleBooks/types";
import { useApiClient } from "@/hooks/useApiClient";
import { UserConfigGetAPI } from "@/features/accountSettings/api/UserConfigAPI";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import type { IPrefecture } from "@/types/prefectures";
import { useFeatureFlags } from "@/configs/featureFlag";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import type { PropertySelectionRow } from "@/features/map/types";
import type { KaokuNumberSearchChiban } from "@/types/kaokuNumber";
import { GetPrefecturesAPI } from "@/api";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { AlertBar } from "@/features/acquireMultipleBooks/components/AlertBar";
import {
  Box,
  Checkbox as MuiCheckbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { PageTitle } from "@/components/Title";
import Grid from "@mui/material/Grid";
import { CTextFieldIdentifyName } from "@/components/TextField/CTextFieldIdentifyName";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { CTextFieldPastDays } from "@/components/TextField/CTextFieldPastDays";
import { Button, ButtonVariantOption, SubmitButton } from "@/components/Button";
import { FeedGrid } from "@/features/acquireMultipleBooks/components/FeedGrid";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { KaokuNumberSearchModal } from "@/features/acquireMultipleBooks/components/KaokuNumberSearchModal";

export const DISTINCT_MESSAGE =
  "重複項目が見つかったため、自動で取り除きました";

// チェックボックスの初期状態を設定するための値を生成する
export const makeBookRequestCheckboxStatus = (
  checked: boolean,
  disabled: boolean
): BookRequestCheckboxStatus => {
  return { checked, disabled };
};

// チェックボックスの状態を管理するstateの初期値を生成する
export const makeOptionsInitialState = (
  rows: AcquireMultipleBookHandoffData[]
): BookRequestCheckboxStatuses[] => {
  // 土地の場合と建物の場合とでチェックボックスを表示するか非表示にするか判定する関数

  const checkDisable = (type: string, bookType: string): boolean => {
    // false: 表示, true: 非表示
    const table: Record<string, Record<string, boolean>> = {
      kyoutan: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      sintaku: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      genzai: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      ownerInfo: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      electricDrawings: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      tisekiDrawings: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      tiekiDrawings: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      buildingDrawings: { LAND: true, BUILDING: false, CONDOMINIUM: false },
    };
    return table[type][bookType] ?? false;
  };

  return rows.map((value) => {
    return {
      // ここでチェックボックスの初期状態を設定する
      kyoutan: makeBookRequestCheckboxStatus(
        false,
        checkDisable("kyoutan", value.bookType)
      ),
      sintaku: makeBookRequestCheckboxStatus(
        false,
        checkDisable("sintaku", value.bookType)
      ),
      genzai: makeBookRequestCheckboxStatus(
        false,
        checkDisable("genzai", value.bookType)
      ),
      ownerInfo: makeBookRequestCheckboxStatus(
        false,
        checkDisable("ownerInfo", value.bookType)
      ),
      electricDrawings: makeBookRequestCheckboxStatus(
        false,
        checkDisable("electricDrawings", value.bookType)
      ),
      tisekiDrawings: makeBookRequestCheckboxStatus(
        false,
        checkDisable("tisekiDrawings", value.bookType)
      ),
      tiekiDrawings: makeBookRequestCheckboxStatus(
        false,
        checkDisable("tiekiDrawings", value.bookType)
      ),
      buildingDrawings: makeBookRequestCheckboxStatus(
        false,
        checkDisable("buildingDrawings", value.bookType)
      ),
    };
  });
};

// 重複を取り除く
export const distinct = (
  rows: AcquireMultipleBookHandoffData[]
): AcquireMultipleBookHandoffData[] => {
  // 作業用の空配列を作る
  const workingArray: Array<{
    key: string;
    value: AcquireMultipleBookHandoffData;
  }> = [];

  rows.forEach((value) => {
    // 同一項目と識別するためのkeyを生成する
    const key = `${value.bookType}${value.prefName}${value.locationFull}${value.chibanKaokuNumber}`;

    // 作業用配列から上で生成したkeyと同じkeyを持っている項目があるか探す
    const anItemThatHasSameKey = workingArray.find((item) => item.key === key);

    if (anItemThatHasSameKey === undefined) {
      // 同じkeyを持っていないならそのkeyで作業用配列に新規追加
      workingArray.push({ key, value });
    }
    // 同じkeyを持っているならそのvalueは捨てる
  });

  // 最終的に出来上がった作業用配列からvalueのみを取り出す
  return workingArray.map((item, index): AcquireMultipleBookHandoffData => {
    // idを採番し直す
    item.value.id = index + 1;
    return item.value;
  });
};

export const AcquireMultipleBooks: React.FC = () => {
  const { handleSubmit } = useAcquireBookForm();
  const { apiClient } = useApiClient();

  // ユーザー設定取得
  const userConfig = UserConfigGetAPI();

  // 確認画面のモーダルを表示するかしないかを決めるフラグ
  const [openModalFlag, setOpenModalFlag] = React.useState(false);
  const [withOwnerAnalyze, setWithOwnerAnalyze] = React.useState(false);

  // 「家屋番号検索」ボタンの活性非活性制御State
  const [
    kaokuNumberSearchButtonIsDisabled,
    setKaokuNumberSearchButtonIsDisabled,
  ] = React.useState<boolean>(true);

  // 「家屋番号検索」モーダル表示非表示管理State
  const [kaokuNumberSearchModalIsOpen, setKaokuNumberSearchModalIsOpen] =
    React.useState<boolean>(false);

  // 遷移元の画面からデータを引き継ぐ
  const location = useLocation();
  // ここでデータがない場合は空配列にする
  const rows = (location.state as AcquireMultipleBookHandoffData[]) ?? [];
  // 重複を取り除く
  const [distinctedRows, setDistinctedRows] = useState<
    AcquireMultipleBookHandoffData[]
  >(distinct(rows));

  // 重複があった場合に表示するメッセージを設定する
  const [message, setMessage] = useState(
    distinctedRows.length !== rows.length ? DISTINCT_MESSAGE : ""
  );

  // チェックボックスの状態を管理するstateを生成する
  const [checkBoxOptions, setCheckBoxOptions] = useState(
    makeOptionsInitialState(distinctedRows)
  );

  // 都道府県リストのState
  const [prefectures, setPrefectures] = useState<IPrefecture[]>([]);

  const isOwnerInfoChecked = (
    checkBoxOptions: BookRequestCheckboxStatuses[]
  ): boolean => {
    for (const option of checkBoxOptions) {
      if (option.ownerInfo.checked) {
        return true;
      }
    }
    return false;
  };

  const { ownerInfoViaScraping, mypageTop } = useFeatureFlags();

  // Zodスキーマ定義
  const FormDataSchema = z.object({
    identifyName: z.string().max(256, "識別名は256文字以内です"),
    pastDays: z
      .number({ invalid_type_error: "経過日数を0日以上で入力してください" })
      .min(0, "経過日数は0日以上で入力してください")
      .max(1000, "経過日数は1000日以内で入力してください"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type FormData = z.infer<typeof FormDataSchema>;

  // 型を用いてReact-Hook-Formのインスタンスを作る
  const reactHookForm = useForm<FormData>({
    mode: "all",
    defaultValues: {
      identifyName: "",
      pastDays: 0,
    },
    resolver: zodResolver(FormDataSchema),
  });

  /**
   * 「prefName」が一致するprefectureのprefCodeを取得する。
   * @param {string} prefName
   * @returns {string}
   */
  const getPrefCode = (prefName: string): string => {
    const targetPrefecture = prefectures.find(
      (element) => element.name === prefName
    );

    return targetPrefecture != null ? targetPrefecture.prefCode : "";
  };

  /**
   * 物件リストに物件を追加する処理。
   * @param {PropertySelectionRow[]} selectRows
   */
  const appendPropertySelectionRows = (
    selectRows: PropertySelectionRow[]
  ): void => {
    // distinctedRowsのidの最大値を取得。
    const lastElement = distinctedRows.at(-1);
    let maxId = lastElement != null ? lastElement.id : 0;

    // AcquireMultipleBookHandoffData型に変換。
    const convertRows = selectRows.map((selectedRow) => {
      const element: AcquireMultipleBookHandoffData = {
        id: ++maxId, // IDはdistinctedRowsの連番にする。
        bookType: selectedRow.bookType,
        prefCode: getPrefCode(selectedRow.prefName),
        prefName: selectedRow.prefName,
        locationFull: selectedRow.location,
        chibanKaokuNumber: selectedRow.chibanKaokuNumber,
        kyoutan: false,
        sintaku: false,
        genzai: false,
        ownerInfo: false,
        electricDrawings: false,
        tisekiDrawings: false,
        tiekiDrawings: false,
        buildingDrawings: false,
      };

      return element;
    });

    // 変換した配列とリストに表示されている配列をマージして重複削除。
    const mergeDistinctRows = distinct(distinctedRows.concat(convertRows));

    // 重複削除した配列のチェックボックスを作成。
    if (distinctedRows.length < mergeDistinctRows.length) {
      // マージした配列の方が大きければ、追加した分のチェックボックスを用意。
      const addedRows = mergeDistinctRows.filter(
        (_, index) => distinctedRows[index] === undefined
      );

      if (addedRows.length > 0)
        setCheckBoxOptions(
          checkBoxOptions.concat(makeOptionsInitialState(addedRows))
        );
    }

    setDistinctedRows(mergeDistinctRows);
  };

  /**
   * 種別が「土地」かつチェックが入っているデータを取得しKaokuNumberSearchModalのchibanListに一致する型に変換する。
   * @returns {KaokuNumberSearchChiban[]}
   */
  const convertChibanList = (): KaokuNumberSearchChiban[] => {
    return distinctedRows
      .filter((row) => row.bookType === "LAND")
      .filter(
        (_, index) =>
          checkBoxOptions[index].buildingDrawings.checked ||
          checkBoxOptions[index].electricDrawings.checked ||
          checkBoxOptions[index].genzai.checked ||
          checkBoxOptions[index].kyoutan.checked ||
          checkBoxOptions[index].ownerInfo.checked ||
          checkBoxOptions[index].sintaku.checked ||
          checkBoxOptions[index].tiekiDrawings.checked ||
          checkBoxOptions[index].tisekiDrawings.checked
      )
      .map((row) => {
        return {
          prefectureName: row.prefName,
          location: row.locationFull,
          chiban: row.chibanKaokuNumber,
        };
      });
  };

  useEffect(() => {
    // テキストボックスに何か入力してしばらく放置すると、再びサーバーからデータを取りに行った時に入力中の値が上書きされてしまう
    // そこで、一度でも入力があったら更新しないようにする
    if (reactHookForm.formState.isDirty) {
      return;
    }
    reactHookForm.setValue("pastDays", userConfig?.pictureRequestPastDays ?? 0);
  }, [userConfig]);

  useEffect(() => {
    // 種別が「土地」の登記種別にチェックが入っていたら「家屋番号検索」ボタンを活性化する。
    const isDisabled = checkBoxOptions
      .filter((_, index) => distinctedRows[index].bookType === "LAND")
      .every(
        (option) =>
          !(
            option.buildingDrawings.checked ||
            option.electricDrawings.checked ||
            option.genzai.checked ||
            option.kyoutan.checked ||
            option.ownerInfo.checked ||
            option.sintaku.checked ||
            option.tiekiDrawings.checked ||
            option.tisekiDrawings.checked
          )
      );

    setKaokuNumberSearchButtonIsDisabled(isDisabled);
  }, [checkBoxOptions]);

  useEffect(() => {
    (async () => {
      // 都道府県リストをAPIから取得する
      const prefectures = await GetPrefecturesAPI(apiClient);
      if (prefectures.length !== 0) setPrefectures(prefectures);
    })();
  }, []);

  return (
    <PagePaper>
      {message !== "" ? (
        <AlertBar
          message={message}
          onClose={() => {
            setMessage("");
          }}
        />
      ) : (
        ""
      )}
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <RequestQuoteIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>不動産登記/図面取得一括取得</PageTitle>
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ ml: 0, color: "red", flexShrink: 0 }}>
              <ul>
                <li>最新の不動産登記/図面を取得いたします</li>
                <li>取得済みの場合は、課金が発生いたしません</li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md mb={1}>
            <Grid container spacing={1} justifyContent={"center"}>
              <Grid item xs={6}>
                <CTextFieldIdentifyName
                  name={"identifyName"}
                  control={reactHookForm.control}
                  size={SizingWrapperStyle.INHERIT}
                />
              </Grid>
              <Grid item xs={6}>
                <CTextFieldPastDays
                  name="pastDays"
                  control={reactHookForm.control}
                  size={SizingWrapperStyle.INHERIT}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={1}>
            <Button
              disabled={kaokuNumberSearchButtonIsDisabled}
              label="家屋番号検索"
              onClick={() => {
                setKaokuNumberSearchModalIsOpen(true);
              }}
              size={SizingWrapperStyle.SMALL}
              variant={ButtonVariantOption.Contained}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography fontWeight={"bold"} align={"right"}>
              列名をダブルクリックすると全選択する
            </Typography>
          </Grid>
        </Grid>
        <SectionPaper>
          <FeedGrid
            rows={distinctedRows}
            checkBoxOptions={checkBoxOptions}
            setCheckBoxOptions={setCheckBoxOptions}
          />
          {ownerInfoViaScraping ? (
            <Box
              sx={{
                mt: 6,
                mb: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <MuiCheckbox
                    sx={{ padding: "0px 4px" }}
                    checked={withOwnerAnalyze}
                    disabled={!isOwnerInfoChecked(checkBoxOptions)}
                    onChange={(e) => {
                      setWithOwnerAnalyze(e.target.checked);
                    }}
                  />
                }
                label="所有者事項登記の取得と同時に内容を解析する"
              />
            </Box>
          ) : null}
          <Box
            sx={{
              mt: 1,
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant={ButtonVariantOption.Contained}
              onClick={() => {
                void (async () => {
                  // 現在がチェックかつ信託か共担のどちらかがチェックされていなかった場合、メッセージを表示する
                  const isInvalidOption = checkBoxOptions.some((value) => {
                    if (
                      value.genzai.checked &&
                      !(value.sintaku.checked || value.kyoutan.checked)
                    ) {
                      alert(
                        "現在事項を取得する場合には共担か信託を選択してください"
                      );
                      return true;
                    }
                    return false;
                  });
                  if (isInvalidOption) return;
                  // 一つもチェックがない場合、メッセージを表示する
                  const result = checkBoxOptions.some((value) => {
                    return (
                      Object.keys(value) as Array<
                        keyof BookRequestCheckboxStatuses
                      >
                    ).some((key) => value[key].checked);
                  });
                  if (!result) {
                    alert("取得したい登記種別を最低一つはチェックしてください");
                    return;
                  }

                  // 一つでもチェックがあれば通す。チェックされていない行は取得登録されない
                  await reactHookForm.handleSubmit((_data, _event) => {
                    setOpenModalFlag(true);
                  })();
                })();
              }}
              label={"一括取得する"}
            />
          </Box>
        </SectionPaper>
        {/* 確認のモーダル画面 */}

        <Dialog
          open={openModalFlag}
          onClose={() => {
            setOpenModalFlag(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              【課金が発生します】登記/図面を取得します。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              variant={ButtonVariantOption.Contained}
              onClick={(_event, cancelLoading) => {
                handleSubmit(
                  distinctedRows,
                  checkBoxOptions,
                  reactHookForm.getValues("pastDays"),
                  withOwnerAnalyze,
                  reactHookForm.getValues("identifyName"),
                  cancelLoading
                );
              }}
              label={"取得する"}
              timeout={30000}
            />
            <Button
              variant={ButtonVariantOption.Outlined}
              onClick={() => {
                setOpenModalFlag(false);
              }}
              label={"キャンセル"}
            />
          </DialogActions>
        </Dialog>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>

      {/* 家屋番号検索モーダル */}
      <Modal
        open={kaokuNumberSearchModalIsOpen}
        aria-labelledby={"kaoku-number-search-modal"}
        aria-describedby={"kaoku-number-search-modal"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            outline: "none",
          }}
        >
          <Box
            sx={{
              width: 1600,
              maxWidth: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <KaokuNumberSearchModal
              chibanList={convertChibanList()}
              setKaokuNumberSearchModalIsOpen={setKaokuNumberSearchModalIsOpen}
              appendPropertySelectionRows={appendPropertySelectionRows}
            />
          </Box>
        </Box>
      </Modal>
    </PagePaper>
  );
};
