import { Avatar, Box, Container, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { TextField, TextFieldPassword } from "@/components/TextField";
import { SubmitButton, ButtonVariantOption } from "../../components/Button";
import { type ILoginPresenterProps } from "./types";
import React from "react";
import LoginIcon from "@mui/icons-material/Login";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";

const theme = createTheme();

const LoginPresenter: React.FC<ILoginPresenterProps> = (props) => {
  const { handleSubmit, setPassword, setEmail, password, email } = props;

  // ログインボタンをスクリプトからクリックするために使用するref
  const ref = React.useRef<HTMLButtonElement>(null);

  // テキストボックスの有効/無効を制御するstate
  const [loading, setLoading] = React.useState(false);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      ref.current?.click();
    }
  };

  // ログインUIのリセット
  const resetLoginUI = (cancelLoading: () => void) => {
    return () => {
      cancelLoading();
      setLoading(false);
    };
  };

  const handleClick = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ): void => {
    setLoading(true);
    void handleSubmit(resetLoginUI(cancelLoading));
  };

  const today = new Date();
  const notifyStartDate = new Date(2025, 11, 22);
  const notifyEndDate = new Date(2026, 0, 5);
  const holidayStartDateText = format(
    new Date(2025, 11, 31),
    "yyyy年MM月dd日（eee）",
    { locale: ja }
  );
  const holidayEndDateText = format(
    new Date(2026, 0, 4),
    "yyyy年MM月dd日（eee）",
    {
      locale: ja,
    }
  );
  const businessStartDateText = format(notifyEndDate, "yyyy年MM月dd日（eee）", {
    locale: ja,
  });

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* 鍵アイコン */}
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>

          {/* メールアドレス */}
          <Box sx={{ my: 1 }}>
            <TextField
              id="email"
              label="メールアドレス"
              value={email}
              onChange={setEmail}
              onKeyUp={handleKeyUp}
              required={true}
              autoFocus={true}
              size={SizingWrapperStyle.LARGE}
              disabled={loading}
            />
          </Box>

          {/* パスワード */}
          <Box sx={{ my: 1 }}>
            <TextFieldPassword
              id="password"
              label="パスワード"
              value={password}
              onChange={setPassword}
              onKeyUp={handleKeyUp}
              size={SizingWrapperStyle.LARGE}
              disabled={loading}
            />
          </Box>

          {/* ログインボタン */}
          <Box sx={{ my: 1 }}>
            <SubmitButton
              label={"ログイン"}
              onClick={handleClick}
              variant={ButtonVariantOption.Contained}
              size={SizingWrapperStyle.LARGE}
              loadingIndicator={"ログイン中..."}
              timeout={30000}
              refToClickButton={ref}
              onTimeout={() => {
                setLoading(false);
              }}
              startIcon={<LoginIcon />}
            />
          </Box>
        </Box>
        {/* 年末年始のお知らせ */}
        {today >= notifyStartDate && today <= notifyEndDate ? (
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderTop: 1,
              paddingTop: 5,
            }}
          >
            <Typography component="h1" variant="h5">
              年末年始のお知らせ
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ marginTop: 2 }}
            >
              平素より格別のご愛顧を賜り、誠にありがとうございます。さて、弊社では下記の期間を年末年始休業とさせていただきます。
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ marginTop: 2 }}
            >
              休業期間：{holidayStartDateText} ～ {holidayEndDateText}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ marginTop: 2 }}
            >
              休業期間中にいただきましたお問い合わせやご発注につきましては、
              {businessStartDateText}より順次対応させていただきます。
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ marginTop: 2 }}
            >
              お客様にはご不便をおかけいたしますが、何卒ご理解賜りますようお願い申し上げます。来年も変わらぬご愛顧を賜りますようお願い申し上げます。
            </Typography>
          </Box>
        ) : null}
      </Container>
    </ThemeProvider>
  );
};

export { LoginPresenter };
