import { HttpStatusCode, isAxiosError, type AxiosInstance } from "axios";
import * as Sentry from "@sentry/react";
import {
  type PostMonitoringAPIRequest,
  type PostMonitoringAPIResponse,
} from "../types";
import { format } from "date-fns";

/**
 * モニタリングAPIを実行する。
 * @param {AxiosInstance} apiClient
 * @param {IRequests} requests
 */
export const PostMonitoringAPI = async (
  apiClient: AxiosInstance,
  requests: PostMonitoringAPIRequest
): Promise<PostMonitoringAPIResponse> => {
  const legalAffairsBureauRequestDateStartString =
    requests.legalAffairsBureauRequestDateStart
      ? format(requests.legalAffairsBureauRequestDateStart, "yyyy-MM-dd")
      : undefined;
  const legalAffairsBureauRequestDateEndString =
    requests.legalAffairsBureauRequestDateEnd
      ? format(requests.legalAffairsBureauRequestDateEnd, "yyyy-MM-dd")
      : undefined;

  try {
    const response = await apiClient.post<PostMonitoringAPIResponse>(
      "/monitoring/",
      {
        name: requests.name,
        receptionReasonIds: requests.receptionReasonIds,
        realEstateOwnerAnalyzeRequestId:
          requests.realEstateOwnerAnalyzeRequestId,
        pictureRequestId: requests.pictureRequestId,
        monthlyUpdatable: requests.monthlyUpdatable,
        legalAffairsBureauRequestDateStart:
          legalAffairsBureauRequestDateStartString,
        legalAffairsBureauRequestDateEnd:
          legalAffairsBureauRequestDateEndString,
        remarks: requests.remarks,
      }
    );

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      if (
        (error.response?.status ?? HttpStatusCode.InternalServerError) >=
        HttpStatusCode.InternalServerError
      ) {
        Sentry.captureMessage(
          "不動産モニタリング登録APIでサーバーエラーが発生しました"
        );
      }
    }
    throw error;
  }
};
