import { useEffect, useState } from "react";
import { useMap, FeatureGroup } from "react-leaflet";
import { AreaUsePurposeTileLayer } from "./AreaUsePurposeTileLayer";

interface Props {
  defaultVisible: boolean;
  initialZoomLevel: number;
}

export const AREA_USE_PURPOSE_LAYER_NAME = "用途地域を表示する";

/*
AreaUsePurposeTileLayerをLeafletのLayersControlで制御するためのWrapperコンポーネント
*/
export const AreaUsePurposeLayerControlWrapper: React.FC<Props> = ({
  defaultVisible,
  initialZoomLevel,
}) => {
  const [visible, setVisible] = useState(defaultVisible);
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // 一時的にBaseLayerを用いて公示価格レイヤーと同時に表示できないようにする
    // BaseLayer本来の使い方ではないため、公示価格レイヤーと同時表示が可能になり次第この処理は削除する
    map.on("baselayerchange", (e) => {
      if (e.name === AREA_USE_PURPOSE_LAYER_NAME) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  }, []);

  return (
    <>
      {/* 空のFeatureGroupは、LayersControlに認識させるために必要 */}
      <FeatureGroup />
      <AreaUsePurposeTileLayer
        visible={visible}
        initialZoomLevel={initialZoomLevel}
      />
    </>
  );
};
