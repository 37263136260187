import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox as MuiCheckbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { CTextFieldIdentifyName } from "@/components/TextField/CTextFieldIdentifyName";
import { SizingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import { CTextFieldPastDays } from "@/components/TextField/CTextFieldPastDays";
import { BlueSectionPaper } from "@/components/AcquireBook/BlueSectionPaper";
import {
  CheckboxesToSelectBookOptions,
  getPrefectureIdByValue,
  getPrefectureMenuItems,
  prefectures,
} from "@/utils/utilsAcquireBook";
import { Button, ButtonVariantOption, SubmitButton } from "@/components/Button";
import { useForm, type UseFormReturn } from "react-hook-form";
import {
  useValidator,
  type Validator,
} from "@/features/acquireBook/hooks/useValidator";
import {
  UserConfigGetAPI,
  type UserConfigAPIResponse,
} from "@/features/accountSettings/api/UserConfigAPI";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  useAcquireBookForm,
  type AcquireBookForm,
} from "@/features/acquireBook/hooks/useAcquireBookForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCity } from "@/hooks/useCity";
import { useFeatureFlags } from "@/configs/featureFlag";
import { type CityListResponse } from "@/types/city";
import type { ISingleBookAcquireDialogData } from "@/features/realEstateReceptionBookFeed/types";
import { KaokuNumberSearchModal } from "./KaokuNumberSearchModal";
import { type PropertySelectionRow } from "@/features/map/types";
import { type AcquireMultipleBookHandoffData } from "@/types/acquirebook";

// Zodスキーマ定義
const FormDataSchema = z.object({
  identifyName: z.string().max(256, "識別名は256文字以内です"),
  pastDays: z
    .number({ invalid_type_error: "経過日数を0日以上で入力してください" })
    .min(0, "経過日数は0日以上で入力してください")
    .max(1000, "経過日数は1000日以内で入力してください"),
});

// 定義したZodのスキーマをTypescriptの型に変換
type FormData = z.infer<typeof FormDataSchema>;

interface IPropsAcquireBookFormModal {
  userConfig?: UserConfigAPIResponse;
  reactHookForm: UseFormReturn<FormData>;
  acquireBookForm: AcquireBookForm;
  validator: Validator;
  singleBookAcquireDialogData: ISingleBookAcquireDialogData;
  requestOptions: Record<string, Record<string, boolean>>;
  setRequestOptions: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, boolean>>>
  >;
}

/**
 * 登記１件取得UIのモーダル版
 * @param props
 */
const AcquireBookFormModal: React.FC<IPropsAcquireBookFormModal> = (props) => {
  const {
    singleBookAcquireDialogData,
    reactHookForm,
    validator,
    acquireBookForm,
    requestOptions,
    setRequestOptions,
  } = props;

  // ページ遷移のためのナビゲーションを設定。
  const navigate = useNavigate();

  // 「家屋番号検索」モーダル表示非表示管理State
  const [kaokuNumberSearchModalIsOpen, setKaokuNumberSearchModalIsOpen] =
    useState<boolean>(false);

  // 登記および図面取得ボタンが押された時の処理
  const handleAcquireButtonClick = async (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ): Promise<void> => {
    if (validator.checkOptions.validate(requestOptions)) {
      await reactHookForm.handleSubmit(
        (data) => {
          acquireBookForm.handleSubmit(
            String(singleBookAcquireDialogData.prefecturesId), // 都道府県コード (1 - 47の数字文字列)
            "", // 市町村コード (数字文字列) (locationFullがある場合, 空欄可)
            "", // locationWithoutCity (yyy町) (locationFullがある場合, 空欄可)
            singleBookAcquireDialogData.address, // locationFull (xxx市yyy町)
            singleBookAcquireDialogData.chiban +
              singleBookAcquireDialogData.kaokuNumber, // 地番 + 家屋番号
            reactHookForm.getValues("pastDays"), // 経過日数
            singleBookAcquireDialogData.realEstateTypeEng, // 不動産種別 (大文字英語)
            requestOptions, // オプション設定値
            false,
            data.identifyName,
            cancelLoading
          );
        },
        () => {
          cancelLoading();
        }
      )();
    } else {
      cancelLoading();
    }
  };

  /**
   * 家屋番号検索ボタンの活性非活性制御。
   * @returns {boolean}
   */
  const checkKaokuNumberSearchDisabled = (): boolean => {
    if (
      !singleBookAcquireDialogData.prefectures ||
      !singleBookAcquireDialogData.address ||
      !singleBookAcquireDialogData.chiban
    )
      return true;

    if (singleBookAcquireDialogData.realEstateTypeEng !== "LAND") return true;

    return false;
  };

  /**
   * 物件リストに物件を追加する処理。
   * 登記取得一括画面に遷移する。
   * @param {PropertySelectionRow[]} rows
   */
  const appendPropertySelectionRows = (rows: PropertySelectionRow[]): void => {
    const states: AcquireMultipleBookHandoffData[] = rows.map((row) => {
      return {
        id: row.id,
        bookType: row.bookType,
        prefCode: String(singleBookAcquireDialogData.prefecturesId),
        prefName: row.prefName,
        locationFull: row.location,
        chibanKaokuNumber: row.chibanKaokuNumber,
        kyoutan: false,
        sintaku: false,
        genzai: false,
        ownerInfo: false,
        electricDrawings: false,
        tisekiDrawings: false,
        tiekiDrawings: false,
        buildingDrawings: false,
      };
    });

    // 重複しないIDを設定。
    const uniqueID = 0;

    states.unshift({
      id: uniqueID,
      bookType: "LAND",
      prefCode: String(singleBookAcquireDialogData.prefecturesId),
      prefName: singleBookAcquireDialogData.prefectures,
      locationFull: singleBookAcquireDialogData.address,
      chibanKaokuNumber:
        singleBookAcquireDialogData.chiban +
        singleBookAcquireDialogData.kaokuNumber,
      kyoutan: false,
      sintaku: false,
      genzai: false,
      ownerInfo: false,
      electricDrawings: false,
      tisekiDrawings: false,
      tiekiDrawings: false,
      buildingDrawings: false,
    });

    // 一括取得の画面に地番を含めて値を受け渡し、登記取得一括画面に遷移する。
    navigate("/acquiremultiplebooks", { state: states });
  };

  return (
    <>
      <Stack spacing={2} sx={{ marginTop: "8px" }}>
        <Stack sx={{ mt: "1em" }} spacing={2} direction={"row"}>
          <CTextFieldIdentifyName
            name={"identifyName"}
            control={reactHookForm.control}
            size={SizingWrapperStyle.LARGE}
          />
        </Stack>
        <Stack sx={{ mt: "1em" }} spacing={2} direction={"row"}>
          <TextField
            label={"都道府県"}
            size="medium"
            sx={{ width: "100px" }}
            value={singleBookAcquireDialogData.prefectures}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label={"所在"}
            size="medium"
            sx={{ width: "400px" }}
            value={singleBookAcquireDialogData.address}
            InputProps={{ readOnly: true }}
          />
        </Stack>
        <Stack spacing={2} direction={"row"}>
          <TextField
            label={"地番または家屋番号"}
            size="medium"
            sx={{ width: "400px" }}
            value={
              singleBookAcquireDialogData.chiban +
              singleBookAcquireDialogData.kaokuNumber
            }
            InputProps={{ readOnly: true }}
          />
          <CTextFieldPastDays
            name="pastDays"
            control={reactHookForm.control}
            size={SizingWrapperStyle.INHERIT}
          />
          <Stack direction="row" alignItems={"center"}>
            <Button
              disabled={checkKaokuNumberSearchDisabled()}
              label="家屋番号検索"
              onClick={() => {
                setKaokuNumberSearchModalIsOpen(true);
              }}
              size={SizingWrapperStyle.SMALL}
              variant={ButtonVariantOption.Contained}
            />
          </Stack>
        </Stack>
        <BlueSectionPaper
          error={validator.checkOptions.state.error}
          helperText={validator.checkOptions.state.helperTextWhenError}
        >
          <CheckboxesToSelectBookOptions
            requestOptions={requestOptions}
            setRequestOptions={setRequestOptions}
            realEstateTypeEng={singleBookAcquireDialogData.realEstateTypeEng}
            onChange={(
              event: boolean,
              key: string,
              requestOptions: Record<string, Record<string, boolean>>
            ) => {
              validator.checkOptions.validate(requestOptions);
            }}
          />
        </BlueSectionPaper>
        <ul>
          <li>最新の不動産登記/図面を取得いたします</li>
          <li>取得済みの場合は、課金が発生いたしません</li>
        </ul>
      </Stack>
      <Box
        sx={{ mx: "auto", my: 4, display: "flex", justifyContent: "center" }}
      >
        <SubmitButton
          variant={ButtonVariantOption.Contained}
          onClick={(event, cancelLoading) => {
            void handleAcquireButtonClick(event, cancelLoading);
          }}
          label={"取得する"}
          timeout={30000}
        />
      </Box>

      {/* 家屋番号検索モーダル */}
      <Modal
        open={kaokuNumberSearchModalIsOpen}
        aria-labelledby={"kaoku-number-search-modal"}
        aria-describedby={"kaoku-number-search-modal"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            outline: "none",
          }}
        >
          <Box
            sx={{
              width: 1600,
              maxWidth: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <KaokuNumberSearchModal
              chibanList={[
                {
                  prefectureName: singleBookAcquireDialogData.prefectures,
                  location: singleBookAcquireDialogData.address,
                  chiban:
                    singleBookAcquireDialogData.chiban +
                    singleBookAcquireDialogData.kaokuNumber,
                },
              ]}
              setKaokuNumberSearchModalIsOpen={setKaokuNumberSearchModalIsOpen}
              appendPropertySelectionRows={appendPropertySelectionRows}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

interface IPropsAcquireBookFormPage {
  userConfig?: UserConfigAPIResponse;
  reactHookForm: UseFormReturn<FormData>;
  acquireBookForm: AcquireBookForm;
  validator: Validator;
  requestOptions: Record<string, Record<string, boolean>>;
  setRequestOptions: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, boolean>>>
  >;
}

/**
 * 登記一件取得UIの単体ページ版
 * @param props
 */
const AcquireBookFormPage: React.FC<IPropsAcquireBookFormPage> = (props) => {
  const {
    acquireBookForm,
    reactHookForm,
    validator,
    requestOptions,
    setRequestOptions,
  } = props;

  const [searchParams] = useSearchParams();

  const prefInitId =
    String(getPrefectureIdByValue(searchParams.get("prefecture") ?? "")) ?? "";

  // 都道府県セレクトボックス
  const [prefecture, setPrefecture] = useState<string>(prefInitId);

  // 市区町村セレクトボックスの内容
  const { city, setCity, cityList } = useCity(
    prefecture,
    searchParams.get("city") ?? ""
  );

  // 大字・町丁目テキストボックス
  const [locationWithoutCity, setLocationWithoutCity] = useState<string>(
    searchParams.get("location") ?? ""
  );

  // 地番または家屋番号テキストボックス
  const [chibanKaokuNumber, setChibanKaokuNumber] = useState<string>(
    searchParams.get("chiban") ?? ""
  );

  // 不動産種別セレクトボックス
  const [realEstateTypeEng, setRealEstateTypeEng] = useState<string>(
    searchParams.get("realEstate") ?? ""
  );

  // 確認画面のモーダルを表示するかしないかを決めるフラグ
  const [openModalFlag, setOpenModalFlag] = React.useState(false);
  const [withOwnerAnalyze, setWithOwnerAnalyze] = React.useState(false);

  // 「家屋番号検索」モーダル表示非表示管理State
  const [kaokuNumberSearchModalIsOpen, setKaokuNumberSearchModalIsOpen] =
    React.useState<boolean>(false);

  // ページ遷移のためのナビゲーションを設定。
  const navigate = useNavigate();

  /**
   * 「prefecture」から都道府県を取得する。
   * @returns {string}
   */
  const getPrefectureName = (): string => {
    const targetPrefecture = prefectures.find(
      (element) => element.id === Number(prefecture)
    );

    return targetPrefecture != null ? targetPrefecture.val : "";
  };

  /**
   * 「city」と「市区町村以下」から所在を取得する。
   * @returns {string}
   */
  const getLocation = (): string => {
    const targetCity = cityList.list.find(
      (element) => element.cityCode === city
    );

    return targetCity != null
      ? targetCity.name + locationWithoutCity
      : locationWithoutCity;
  };

  /**
   * 物件リストに物件を追加する処理。
   * 登記取得一括画面に遷移する。
   * @param {PropertySelectionRow[]} rows
   */
  const appendPropertySelectionRows = (rows: PropertySelectionRow[]): void => {
    const states: AcquireMultipleBookHandoffData[] = rows.map((row) => {
      return {
        id: row.id,
        bookType: row.bookType,
        prefCode: prefecture,
        prefName: row.prefName,
        locationFull: row.location,
        chibanKaokuNumber: row.chibanKaokuNumber,
        kyoutan: false,
        sintaku: false,
        genzai: false,
        ownerInfo: false,
        electricDrawings: false,
        tisekiDrawings: false,
        tiekiDrawings: false,
        buildingDrawings: false,
      };
    });

    // 重複しないIDを設定。
    const uniqueID = 0;

    states.unshift({
      id: uniqueID,
      bookType: "LAND",
      prefCode: prefecture,
      prefName: getPrefectureName(),
      locationFull:
        cityList.list.filter((element) => element.cityCode === city)[0].name +
        locationWithoutCity,
      chibanKaokuNumber,
      kyoutan: false,
      sintaku: false,
      genzai: false,
      ownerInfo: false,
      electricDrawings: false,
      tisekiDrawings: false,
      tiekiDrawings: false,
      buildingDrawings: false,
    });

    // 一括取得の画面に地番を含めて値を受け渡し、登記取得一括画面に遷移する。
    navigate("/acquiremultiplebooks", { state: states });
  };

  /**
   * 家屋番号検索ボタンの活性非活性制御。
   * 「都道府県」「市区町村」「不動産種別」「大字・町丁目」「地番または家屋番号」のうち1つでも未入力なら非活性。
   * 「不動産種別」で「土地」以外が選択されていたら非活性。
   * @returns {boolean}
   */
  const checkKaokuNumberSearchDisabled = (): boolean => {
    if (
      !prefecture ||
      !city ||
      !realEstateTypeEng ||
      !locationWithoutCity ||
      !chibanKaokuNumber
    )
      return true;

    if (realEstateTypeEng !== "LAND") return true;

    return false;
  };

  // 都道府県セレクトボックス変更時の処理
  const handlePrefecture = (event: SelectChangeEvent<string>): void => {
    setPrefecture(event.target.value);
    validator.pref.validate(event.target.value);
    // 市区町村selectBoxの中身が変わるのでselectBoxを初期状態に戻す
    setCity("");
  };

  // 市区町村セレクトボックス変更時の処理
  const handleCity = (event: SelectChangeEvent<string>): void => {
    setCity(event.target.value);
    validator.city.validate(event.target.value);
  };

  // 市区町村のセレクトボックスの選択項目を生成する
  const getCityMenuItems = (
    cityListResponse: CityListResponse
  ): React.ReactNode | undefined => {
    const cityList = cityListResponse.list;
    if (cityList.length > 0) {
      return cityList.map((value) => {
        return (
          <MenuItem key={value.id} value={value.cityCode}>
            {value.name}
          </MenuItem>
        );
      });
    }
  };

  const { ownerInfoViaScraping } = useFeatureFlags();

  return (
    <>
      {/* 確認のモーダル画面 */}
      <Dialog
        open={openModalFlag}
        onClose={() => {
          setOpenModalFlag(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            【課金が発生します】登記/図面を取得します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            timeout={30000}
            variant={ButtonVariantOption.Contained}
            onClick={(_event, cancelLoading) => {
              acquireBookForm.handleSubmit(
                prefecture,
                city,
                locationWithoutCity,
                "",
                chibanKaokuNumber,
                reactHookForm.getValues("pastDays"),
                realEstateTypeEng,
                requestOptions,
                withOwnerAnalyze,
                reactHookForm.getValues("identifyName"),
                cancelLoading
              );
            }}
            label={"取得する"}
          />
          <Button
            variant={ButtonVariantOption.Outlined}
            onClick={() => {
              setOpenModalFlag(false);
            }}
            label={"キャンセル"}
          />
        </DialogActions>
      </Dialog>

      {/* 家屋番号検索モーダル */}
      <Modal
        open={kaokuNumberSearchModalIsOpen}
        aria-labelledby={"kaoku-number-search-modal"}
        aria-describedby={"kaoku-number-search-modal"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            outline: "none",
          }}
        >
          <Box
            sx={{
              width: 1600,
              maxWidth: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <KaokuNumberSearchModal
              chibanList={[
                {
                  prefectureName: getPrefectureName(),
                  location: getLocation(),
                  chiban: chibanKaokuNumber,
                },
              ]}
              setKaokuNumberSearchModalIsOpen={setKaokuNumberSearchModalIsOpen}
              appendPropertySelectionRows={appendPropertySelectionRows}
            />
          </Box>
        </Box>
      </Modal>

      <>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent={"left"}>
            <Grid item>
              <Box sx={{ my: 1 }}>
                <SizingWrapper>
                  <FormControl
                    fullWidth
                    error={validator.realEstateType.state.error}
                  >
                    <InputLabel id="select-label">不動産種別</InputLabel>
                    <Select
                      labelId="select-label"
                      label="不動産種別"
                      value={realEstateTypeEng}
                      onChange={(event: SelectChangeEvent<string>) => {
                        setRealEstateTypeEng(event.target.value);
                        validator.realEstateType.validate(event.target.value);
                      }}
                    >
                      <MenuItem value={"LAND"}>土地</MenuItem>
                      <MenuItem value={"BUILDING"}>建物</MenuItem>
                      <MenuItem value={"CONDOMINIUM"}>区分建物</MenuItem>
                    </Select>
                    <FormHelperText>
                      {validator.realEstateType.state.helperTextWhenError}
                    </FormHelperText>
                  </FormControl>
                </SizingWrapper>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ my: 1 }}>
                <SizingWrapper>
                  <FormControl fullWidth error={validator.pref.state.error}>
                    <InputLabel id="select-label">都道府県</InputLabel>
                    <Select
                      labelId="select-label"
                      label="都道府県"
                      value={prefecture}
                      onChange={handlePrefecture}
                    >
                      {getPrefectureMenuItems()}
                    </Select>
                    <FormHelperText>
                      {validator.pref.state.helperTextWhenError}
                    </FormHelperText>
                  </FormControl>
                </SizingWrapper>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ my: 1 }}>
                <SizingWrapper>
                  <FormControl fullWidth error={validator.city.state.error}>
                    <InputLabel id="select-label">市区町村</InputLabel>
                    <Select
                      labelId="select-label"
                      label="市区町村"
                      value={city}
                      onChange={handleCity}
                    >
                      {getCityMenuItems(cityList)}
                    </Select>
                    <FormHelperText>
                      {validator.city.state.helperTextWhenError}
                    </FormHelperText>
                  </FormControl>
                </SizingWrapper>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent={"left"}
            alignItems={"center"}
          >
            <Grid item>
              <TextField
                error={validator.locationWithoutCity.state.error}
                label="大字・町丁目"
                helperText={
                  validator.locationWithoutCity.state.helperTextWhenError
                }
                value={locationWithoutCity}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationWithoutCity(event.target.value);
                  validator.locationWithoutCity.validate(event.target.value);
                }}
                sx={{ width: "500px" }}
              />
            </Grid>
            <Grid item>
              <TextField
                error={validator.chibanKaokuNumber.state.error}
                label="地番または家屋番号"
                helperText={
                  validator.chibanKaokuNumber.state.helperTextWhenError
                }
                value={chibanKaokuNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setChibanKaokuNumber(event.target.value);
                  validator.chibanKaokuNumber.validate(event.target.value);
                }}
                onBlur={(
                  event: React.FocusEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => {
                  setChibanKaokuNumber(
                    event.target.value
                      .trim()
                      .replace(/(番地)$|[番号]$/g, "")
                      .replace(/(番地の)|(番地)|(番の)|[番の]/g, "-")
                  );
                }}
                sx={{ width: "500px" }}
              />
            </Grid>
            <Grid item>
              <Button
                disabled={checkKaokuNumberSearchDisabled()}
                label="家屋番号検索"
                onClick={() => {
                  setKaokuNumberSearchModalIsOpen(true);
                }}
                size={SizingWrapperStyle.SMALL}
                variant={ButtonVariantOption.Contained}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent={"left"} sx={{ my: 0 }}>
            <Grid item>
              <Box display="flex" sx={{ width: "500px" }}>
                <CTextFieldIdentifyName
                  name={"identifyName"}
                  control={reactHookForm.control}
                  size={SizingWrapperStyle.INHERIT}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex">
                <CTextFieldPastDays
                  name="pastDays"
                  control={reactHookForm.control}
                  size={SizingWrapperStyle.LARGE}
                />
              </Box>
            </Grid>
          </Grid>

          <Typography sx={{ color: "blue", my: 3, fontSize: "20px" }}>
            取得したい登記/図面を選択してください
          </Typography>

          <BlueSectionPaper
            error={validator.checkOptions.state.error}
            helperText={validator.checkOptions.state.helperTextWhenError}
          >
            <CheckboxesToSelectBookOptions
              requestOptions={requestOptions}
              setRequestOptions={setRequestOptions}
              realEstateTypeEng={realEstateTypeEng}
              onChange={(
                event: boolean,
                key: string,
                requestOptions: Record<string, Record<string, boolean>>
              ) => {
                validator.checkOptions.validate(requestOptions);
              }}
            />
          </BlueSectionPaper>
        </Box>

        {ownerInfoViaScraping ? (
          <Box
            sx={{
              mr: 2,
              my: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              control={
                <MuiCheckbox
                  sx={{ padding: "0px 4px" }}
                  checked={withOwnerAnalyze}
                  disabled={!requestOptions.syoyusya.checked}
                  onChange={(e) => {
                    setWithOwnerAnalyze(e.target.checked);
                  }}
                />
              }
              label="所有者事項証明書の取得と同時に内容を解析する"
            />
          </Box>
        ) : null}
        <Box
          sx={{
            mr: 2,
            mt: 2,
            mb: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            label="取得する"
            onClick={() => {
              void (async () => {
                // すべての要素に対してバリデーションを実行する
                const results = [
                  validator.pref.validate(prefecture),
                  validator.city.validate(city),
                  validator.realEstateType.validate(realEstateTypeEng),
                  validator.locationWithoutCity.validate(locationWithoutCity),
                  validator.chibanKaokuNumber.validate(chibanKaokuNumber),
                  validator.checkOptions.validate(requestOptions),
                ];

                // ここでreact hook formのバリデーションを実行する
                await reactHookForm.handleSubmit((_data, _event) => {
                  // resultsの全ての値がtrueならvalidation通過とみなす
                  if (results.every((value) => value)) {
                    setOpenModalFlag(true);
                  }
                  /*
                  reactHookForm.handleSubmit()は関数を返す関数なので
                  (reactHookForm.handleSubmit(callback))()とする必要がある
                   */
                })();
              })();
            }}
            variant={ButtonVariantOption.Contained}
            size={SizingWrapperStyle.SMALL}
          />
        </Box>
      </>
    </>
  );
};

interface IProps {
  mode: "modal" | "normal";
  singleBookAcquireDialogData?: ISingleBookAcquireDialogData;
}

/**
 * 登記１件取得画面のUI
 * modeを"normal"にするとページ版UIが表示され、"modal"に設定するとモーダル版が表示される
 * modalモードの場合はsingleBookAcquireDialogDataが必要になります。
 * 引数が正しく設定されていない場合は何も表示されません
 * @param props
 */
const AcquireBookFormUI: React.FC<IProps> = (props) => {
  const { mode, singleBookAcquireDialogData } = props;

  // 登記/図面種別選択チェックボックス
  const [requestOptions, setRequestOptions] = useState<
    Record<string, Record<string, boolean>>
  >({
    syoyusya: { checked: false, disable: true },
    zennbu: { checked: false, disable: true },
    sintaku: { checked: false, disable: true },
    kyodo: { checked: false, disable: true },
    genzai: { checked: false, disable: true },
    tiseki: { checked: false, disable: true },
    densi: { checked: false, disable: true },
    tieki: { checked: false, disable: true },
    tatemono: { checked: false, disable: true },
  });

  // 型を用いてReact-Hook-Formのインスタンスを作る
  const reactHookForm = useForm<FormData>({
    mode: "all",
    defaultValues: {
      identifyName: "",
      pastDays: 0,
    },
    resolver: zodResolver(FormDataSchema),
  });

  // ユーザー設定取得
  const userConfig = UserConfigGetAPI();

  // 登記取得APIアクセス用関数
  const acquireBookForm = useAcquireBookForm();

  // バリデーションのためのフックを導入する
  const validator = useValidator();

  useEffect(() => {
    // テキストボックスに何か入力してしばらく放置すると、再びサーバーからデータを取りに行った時に入力中の値が上書きされてしまう
    // そこで、一度でも入力があったら更新しないようにする
    if (reactHookForm.formState.isDirty) {
      return;
    }
    reactHookForm.setValue("pastDays", userConfig?.pictureRequestPastDays ?? 0);
  }, [userConfig]);

  switch (mode) {
    case "normal":
      return (
        <AcquireBookFormPage
          userConfig={userConfig}
          reactHookForm={reactHookForm}
          acquireBookForm={acquireBookForm}
          validator={validator}
          requestOptions={requestOptions}
          setRequestOptions={setRequestOptions}
        />
      );
    case "modal":
      if (!singleBookAcquireDialogData) return null;
      return (
        <AcquireBookFormModal
          userConfig={userConfig}
          reactHookForm={reactHookForm}
          acquireBookForm={acquireBookForm}
          validator={validator}
          singleBookAcquireDialogData={singleBookAcquireDialogData}
          requestOptions={requestOptions}
          setRequestOptions={setRequestOptions}
        />
      );
    default:
      return null;
  }
};

export { AcquireBookFormUI };
