import {
  Box,
  Grid,
  Stack,
  type AlertColor,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { SectionPaper } from "@/components/Paper";
import { SectionTitle } from "@/components/Title";
import { Label } from "@/components/Label";
import { SizingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import { CheckboxGroup, Checkbox } from "@/components/Checkbox";
import { Button, ButtonVariantOption } from "@/components/Button";
import {
  type CustomerSearchDataGridRow,
  CustomerSerachResultFeedGrid,
} from "./CustomerSerachResultFeedGrid";
import { MultiSelect } from "@/components/Select";
import { usePrefectureOptions } from "@/features/realEstateReceptionBookFeed/hooks/usePrefectureOptions";
import { useCityOptions } from "@/features/realEstateReceptionBookFeed/hooks/useCityOptions";
import { useCitySelect } from "@/features/realEstateReceptionBookFeed/hooks/useCitySelect";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { Divider } from "@/components/Divider";

import { useApiClient } from "@/hooks/useApiClient";
// import { PersonAdd, PersonSearch } from "@mui/icons-material";
import { useCustomerAdd } from "../hooks/useCustomerAdd";
import { HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { SendToTrackingService } from "@/api/handlers/errors";
export interface FilterCondition {
  prefectures: string[];
  cities: string[];
  // 不動産種別
  realEstateTypeTochi: boolean;
  realEstateTypeKutate: boolean;
  realEstateTypeTatemono: boolean;
  // スクリーニング条件の状態管理
  noCorporation: boolean;
  sharedWithFivePeople: boolean;
  isComplaint: boolean;
  duplicateCommercialPicture: boolean;
}

interface Props {
  handleCustomerRefresh: () => Promise<void>;
}

export const CustomerSearch: React.FC<Props> = ({ handleCustomerRefresh }) => {
  // 都道府県の選択肢を取得
  const { prefectureOptions } = usePrefectureOptions();

  // 選択された行のIDを管理する状態
  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([]);
  // 検索結果の状態管理
  const [rows, setRows] = useState<CustomerSearchDataGridRow[]>([]);
  // 検索条件の状態管理
  const [filterCondition, setFilterCondition] = useState<FilterCondition>({
    prefectures: [],
    cities: [],
    // 不動産種別
    realEstateTypeTochi: false,
    realEstateTypeKutate: false,
    realEstateTypeTatemono: false,
    // スクリーニング条件の状態管理
    noCorporation: false,
    sharedWithFivePeople: false,
    isComplaint: false,
    duplicateCommercialPicture: false,
  });

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (): void => {
    setSnackbar({ ...snackbar, open: false });
  };

  const { handleAddCustomer, handleAddOutOfTargetCustomer } = useCustomerAdd(
    selectedRowIds as string[]
  );
  const { apiClient } = useApiClient();
  const { cityParams } = useCityOptions();
  const { cityOptions } = useCitySelect(
    filterCondition.prefectures,
    cityParams
  );

  // 不動産種別とスクリーニング条件のチェックボックスの状態を管理する関数を集約
  const handleCheckboxChange = (condition: keyof FilterCondition): void => {
    setFilterCondition((prev) => ({
      ...prev,
      [condition]: !prev[condition],
    }));
  };

  // 選択された行のIDを管理する関数
  const handleRowSelectionChange = (
    newSelectionModel: GridSelectionModel
  ): void => {
    setSelectedRowIds(newSelectionModel);
    console.log("Selected IDs:", newSelectionModel);
  };

  const handleSearch = async (): Promise<void> => {
    // 都道府県 or 市区町村が選択されていない場合はアラートを表示
    const messages = [];
    if (filterCondition.prefectures.length === 0) {
      messages.push("都道府県を選択して下さい");
    }
    if (filterCondition.cities.length === 0) {
      messages.push("市区町村を選択して下さい");
    }
    if (messages.length > 0) {
      alert(messages.join("\n"));
      return;
    }

    const response = await apiClient
      .post<CustomerSearchDataGridRow[]>(
        "/real-estate-owner-customer-management/search",
        filterCondition
      )
      .catch((error): void => {
        if (
          isAxiosError(error) &&
          (error.response?.status ?? HttpStatusCode.InternalServerError) >=
            HttpStatusCode.InternalServerError
        ) {
          SendToTrackingService(error);
        }
        return undefined;
      });
    if (response) {
      setRows(response.data);
    } else {
      messages.push("APIの呼び出しに失敗しました");
      Sentry.captureException("APIの呼び出しに失敗しました");
    }
  };

  const handleCustomerRegistrationClick = async (): Promise<void> => {
    try {
      await handleAddCustomer();
      await handleSearch();
      await handleCustomerRefresh();

      setSnackbar({
        open: true,
        message: "顧客を登録しました。",
        severity: "success",
      });
    } catch (error) {
      console.error("顧客の登録に失敗しました。", error);
      setSnackbar({
        open: true,
        message: "顧客の登録に失敗しました。",
        severity: "error",
      });
    }
  };

  const handleOutOfTargetCustomerRegistrationClick =
    async (): Promise<void> => {
      try {
        await handleAddOutOfTargetCustomer();
        await handleSearch();
        await handleCustomerRefresh();

        setSnackbar({
          open: true,
          message: "対象外顧客を登録しました。",
          severity: "success",
        });
      } catch (error) {
        console.error("対象外顧客の登録に失敗しました。", error);
        setSnackbar({
          open: true,
          message: "対象外顧客の登録に失敗しました。",
          severity: "error",
        });
      }
    };

  // 都道府県の選択状態が変化したら市区町村は初期化する
  useEffect(() => {
    if (filterCondition.prefectures.length === 0) {
      setFilterCondition((prevState) => ({
        ...prevState,
        cities: [],
      }));
    }
  }, [filterCondition.prefectures]);

  return (
    <SectionPaper>
      <form>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              ".MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            <Box sx={{ display: "flex" }}>
              <SectionTitle>顧客検索</SectionTitle>

              <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{ top: { sm: 80, xs: 65 } }} // ヘッダーの高さを考慮し調整
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity={snackbar.severity}
                  sx={{ width: "100%" }}
                >
                  {snackbar.message}
                </Alert>
              </Snackbar>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Label>地域</Label>
              <Grid container>
                <Box sx={{ mr: 1 }}>
                  <SizingWrapper>
                    <MultiSelect
                      label="都道府県"
                      value={filterCondition.prefectures}
                      onChange={(value: string[]) => {
                        setFilterCondition((prevState) => ({
                          ...prevState,
                          prefectures: value,
                        }));
                      }}
                      options={prefectureOptions}
                    />
                  </SizingWrapper>
                </Box>

                <SizingWrapper>
                  <MultiSelect
                    label="市区町村 *"
                    value={filterCondition.cities}
                    onChange={(value: string[]) => {
                      setFilterCondition((prevState) => ({
                        ...prevState,
                        cities: value,
                      }));
                    }}
                    options={cityOptions}
                    maxSelect={10}
                  />
                </SizingWrapper>
              </Grid>
            </Box>

            {/* 不動産種別 */}
            <Box sx={{ mx: 2, mb: 2 }}>
              <CheckboxGroup label="不動産種別">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Checkbox
                    label="土地"
                    checked={filterCondition.realEstateTypeTochi}
                    onChange={() => {
                      handleCheckboxChange("realEstateTypeTochi");
                    }}
                  />
                  <Checkbox
                    label="区分建物"
                    checked={filterCondition.realEstateTypeKutate}
                    onChange={() => {
                      handleCheckboxChange("realEstateTypeKutate");
                    }}
                  />
                  <Checkbox
                    label="建物"
                    checked={filterCondition.realEstateTypeTatemono}
                    onChange={() => {
                      handleCheckboxChange("realEstateTypeTatemono");
                    }}
                  />
                </Box>
              </CheckboxGroup>
            </Box>

            {/* スクリーニング条件 */}
            <Box sx={{ mx: 2, mb: 2 }}>
              <CheckboxGroup label="スクリーニング条件">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Checkbox
                    label="法人以外"
                    checked={filterCondition.noCorporation}
                    onChange={() => {
                      handleCheckboxChange("noCorporation");
                    }}
                  />
                  <Checkbox
                    label="共有5人以上"
                    checked={filterCondition.sharedWithFivePeople}
                    onChange={() => {
                      handleCheckboxChange("sharedWithFivePeople");
                    }}
                  />
                  <Checkbox
                    label="過去苦情先"
                    checked={filterCondition.isComplaint}
                    onChange={() => {
                      handleCheckboxChange("isComplaint");
                    }}
                  />
                  <Checkbox
                    label="登記重複"
                    checked={filterCondition.duplicateCommercialPicture}
                    onChange={() => {
                      handleCheckboxChange("duplicateCommercialPicture");
                    }}
                  />
                </Box>
              </CheckboxGroup>
            </Box>

            <Box
              sx={{
                mr: 2,
                mb: 3,
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <Button
                label="顧客検索"
                onClick={() => {
                  (async () => {
                    await handleSearch();
                  })();
                }}
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.SMALL}
              />
            </Box>

            <Divider />

            <Box
              sx={{
                mr: 2,
                my: 3,
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <Button
                label="顧客登録"
                disabled={selectedRowIds.length === 0}
                onClick={() => {
                  (async () => {
                    await handleCustomerRegistrationClick();
                  })();
                }}
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.SMALL}
              />
              <Button
                label="対象外顧客登録"
                disabled={selectedRowIds.length === 0}
                onClick={() => {
                  (async () => {
                    await handleOutOfTargetCustomerRegistrationClick();
                  })();
                }}
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.SMALL}
              />
            </Box>

            <SectionPaper>
              <CustomerSerachResultFeedGrid
                rows={rows}
                selectedRowIds={selectedRowIds}
                handleRowSelectionChange={handleRowSelectionChange}
              />
              <Box sx={{ mb: 6 }} />
              <Stack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
                mb={2}
              ></Stack>
            </SectionPaper>
          </AccordionDetails>
        </Accordion>
      </form>
    </SectionPaper>
  );
};
