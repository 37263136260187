import {
  AxiosError,
  type AxiosInstance,
  HttpStatusCode,
  isAxiosError,
} from "axios";
import { SendToTrackingService } from "@/api/handlers/errors";
import {
  type RealEstateTitleSearchAPIRequest,
  type RealEstateTitleSearchAPIResponse,
  type RealEstateTitleSearchDetailAPIRequest,
  type RealEstateTitleSearchAPIResponseStatus,
  type RealEstateTitleHasTradeAPIRequest,
  type RealEstateTitleHasTradeAPIResponse,
} from "../types";

const TIMEOUT_MILLISECOND = 40_000;

interface FeedRealEstateTitleAPIResponse {
  data?: RealEstateTitleSearchAPIResponse;
  status: number;
  error?: string;
  timeout?: boolean;
}

interface FeedRealEstateTitleDetailAPIResponse {
  data?: RealEstateTitleSearchAPIResponseStatus;
  status: number;
  error?: string;
}

interface FeedRealEstateTitleHasTradeAPIResponse {
  data?: RealEstateTitleHasTradeAPIResponse;
  status: number;
  error?: string;
}

class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "TimeoutError";
  }
}

export const feedAPI = async (
  apiClient: AxiosInstance,
  body: RealEstateTitleSearchAPIRequest
): Promise<FeedRealEstateTitleAPIResponse | undefined> => {
  // タイムアウトの実装
  const timeoutPromise = new Promise((_resolve, reject) =>
    setTimeout(() => {
      reject(new TimeoutError("表題部検索の一覧取得がタイムアウトしました"));
    }, TIMEOUT_MILLISECOND)
  );

  try {
    const feed = apiClient.post<RealEstateTitleSearchAPIResponse>(
      "/real-estate-title/search",
      body
    );
    const response = (await Promise.race([
      feed,
      timeoutPromise,
    ])) as FeedRealEstateTitleAPIResponse;

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error instanceof TimeoutError) {
      return {
        status: HttpStatusCode.Ok,
        timeout: true,
      };
    } else if (
      isAxiosError(error) &&
      (error.response?.status ?? HttpStatusCode.InternalServerError) >=
        HttpStatusCode.InternalServerError
    ) {
      SendToTrackingService(error);
    } else if (error instanceof AxiosError && error.response != null) {
      return {
        status: error.status ?? HttpStatusCode.InternalServerError,
        error: error.message,
      };
    }

    return {
      status: HttpStatusCode.InternalServerError,
    };
  }
};

export const feedDetailAPI = async (
  apiClient: AxiosInstance,
  data: RealEstateTitleSearchDetailAPIRequest
): Promise<FeedRealEstateTitleDetailAPIResponse | undefined> => {
  try {
    const response =
      await apiClient.get<RealEstateTitleSearchAPIResponseStatus>(
        `/real-estate-title/${data.id}?real_estate_type=${data.realEstateType}`
      );

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (
      isAxiosError(error) &&
      (error.response?.status ?? HttpStatusCode.InternalServerError) >=
        HttpStatusCode.InternalServerError
    ) {
      SendToTrackingService(error);
    }

    if (error instanceof AxiosError && error.response != null) {
      return {
        status: error.status ?? HttpStatusCode.InternalServerError,
        error: error.message,
      };
    }

    return {
      status: HttpStatusCode.InternalServerError,
    };
  }
};

export const feedHasTradeAPI = async (
  apiClient: AxiosInstance,
  body: RealEstateTitleHasTradeAPIRequest
): Promise<FeedRealEstateTitleHasTradeAPIResponse | undefined> => {
  try {
    const response = await apiClient.post<RealEstateTitleHasTradeAPIResponse>(
      `/real-estate-title/has-trade`,
      body
    );

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error instanceof AxiosError && error.response != null) {
      return {
        status: error.status ?? HttpStatusCode.InternalServerError,
        error: error.message,
      };
    }

    return {
      status: HttpStatusCode.InternalServerError,
    };
  }
};
